
import { Modal } from 'react-bootstrap';
import "./CommonModal.css"
import ApplyModal from './ApplyModal';
import { useState } from 'react';
const KnowMoreModal = (props) => {
    const [applyModalShow, setApplyModalShow] = useState(false);
    console.log(props)


    const jobDescriptionLines = (props.jobDescription || '')
    .split(/\d+\.\s+/)
    .filter(line => line.trim() !== ""); 

    const handleApplyNowClick = () => {
        props.onHide(); 
        setApplyModalShow(true); 
    };

    return (
        <section>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className="common-main-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4 className="modal-headdd">Job Description for {props.jobTitle}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="modal-head">Job Description</h4>
                    {/* Map over job description lines and render them one per line */}
                    <ul>
                        {jobDescriptionLines.map((line, index) => (
                            <li key={index} className="blk-grey-text">{line}</li>
                        ))}
                    </ul>
                    <h4 className="modal-head">Additional Information</h4>
                    <ul>
                        <li>Location: {props.location}</li>
                        <li>Experience Required: {props.experienceRequired} Years</li>
                        <li>Languages: {props.languages}</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <div className="job-card-main">
                        <button className="know-more-btn-job" onClick={handleApplyNowClick}>
                            Apply Now
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Pass the position prop to ApplyModal */}
            <ApplyModal
                show={applyModalShow}
                onHide={() => setApplyModalShow(false)}
                jobTitle={props.jobTitle} // Pass jobTitle to ApplyModal
            />
        </section>
    );
};


export default KnowMoreModal;
