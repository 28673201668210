import React from 'react';
import './../../cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const TalentStaffingFeature = () => {
 
const talentstaffingfeature = [
    {
        icon: "/assets/images/servicesPage/feature-image/4.svg",
        title: "Trained Fresher",
        description:
            "We train fresh graduates, enhancing their skills and molding them into valuable assets for your organization, bridging the gap between education and employment.",
    },

    {
        icon: "/assets/images/servicesPage/feature-image/5.svg",
        title: "On Contract",
        description:
            "Discover flexible staffing solutions with our On Contract service. Access skilled professionals for temporary workforce needs, whether for short-term projects or peak workloads.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/6.svg",
        title: "Contract To Hire",
        description:
            "Assess candidates on temporary contracts to ensure the right fit for your team before making permanent decisions, saving time and resources in the hiring process.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/7.svg",
        title: "Fulltime Employee",
        description:
            "Find top talent with our Full-Time Employee service, streamlining hiring to match your needs with dedicated candidates.",
    },

    {
        icon: "/assets/images/servicesPage/feature-image/8.svg",
        title: "Risk Mitigation",
        description:
            "Reduce the risk of hiring mistakes or mismatches by relying on the staffing service's expertise in candidate sourcing, screening, and selection.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/9.svg",
        title: "Cost Savings",
        description:
            "Assess candidates on temporary contracts to ensure the right fit for your team before making permanent decisions, saving time and resources in the hiring process.Save on recruitment costs, such as advertising, screening, and onboarding, by outsourcing the hiring process to a staffing service.",
    },
];

    return (
        <section className="feature-main">
            <Container>
            <CommonHeader title="Why Choose RedBeryl!" subtitle="The core values that drive everything we do " highlightSubtitleIndex={2} />
                <Row>
                    {talentstaffingfeature.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default TalentStaffingFeature;
