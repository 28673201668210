import React from 'react';

import '../../../services-page/cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const RfillingsFeature = () => {

    const features =[
      {
        icon: "/assets/images/productsPage/rFiling/material-symbols-light_business-center-outline.svg",
        title: "CRM & Accounting Integration",
        description: "Seamlessly integrated with Accounting tool and CRM for automatic client account setup, tracking, and financial management."
      },
      {
        icon: "/assets/images/productsPage/rFiling/fluent_shield-checkmark-20-regular.svg",
        title: "User-Friendly Interface",
        description: "Navigate effortlessly with a clean and intuitive design, making it easy for admins and users to complete tasks with minimal training."
      },
      {
        icon: "/assets/images/productsPage/rFiling/cross_1.svg",
    
        title: "Real-Time Status Updates",
        description: "Instantly view order status updates and alerts, helping you and your clients keep track of ongoing filings and any actions needed."
      },
      {
        icon: "/assets/images/productsPage/rFiling/stash_hand-holding-dollar-light.svg",
        title: "Actionable Reporting and Analytics",
        description: " R-Filings offers powerful analytics to assess client orders, employee productivity, and compliance success. Make informed decisions with data at your fingertips."
      },
      {
        icon: "/assets/images/productsPage/rFiling/fluent_document-checkmark-20-regular.svg",
        title: "Automated Compliance Tracking",
        description: "Stay ahead with built-in tracking tools to monitor client compliance needs and deadlines across all 50 states, ensuring timely updates and accuracy."
      },
      {
        icon: "/assets/images/productsPage/rFiling/data_1.svg",
    
        title: "Compliance Made Easy",
        description: "Simplify compliance with a platform that provides step-by-step guidance and automated reminders for each filing type."
      }
    ];

    return (
        <section className="feature-main">
            <Container>
                <CommonHeader highlightSubtitleIndex={6} title='Why Choose R-Filings?' subtitle='A comprehensive and easy to use platform for seamless formation and compliance tasks'/>

                <Row>
                    {features.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default RfillingsFeature;
