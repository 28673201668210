import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';


const tabData = [
    {
        key: "first",
        title: 'Offshore Solutions',
        subHeading: "Expand your reach and capacity",
        heading: "Extend capabilities, meet deadlines effectively with offshore resources",
        description: "By establishing an Offshore Development Center (ODC), you gain access to a pool of skilled professionals in different time zones. This allows you to extend your development capabilities, work around-the-clock, and meet tight deadlines more effectively.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/odcestablishment/o1.png`,
    },
    {
        key: "second",
        title: 'Cost Efficiency',
        subHeading: "Cost-effective and scalable solutions",
        heading: "Save costs, scale development team without hiring overhead.",
        description: "Setting up an ODC can be more cost-effective than hiring and managing an in-house development team. You can scale your development resources up or down as needed, without the overhead costs associated with hiring and training new employees.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/odcestablishment/o2.png`,
    },
    {
        key: "third",
        title: 'Global Workforce',
        subHeading: "Tap into a global talent pool",
        heading: "Access global talent for specialized expertise, fresh perspectives.",
        description: "An ODC provides access to a diverse pool of global talent with specialized skills and expertise. This allows you to find the right people for your project, bring in fresh perspectives, and enhance the quality of your development work.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/odcestablishment/o3.png`,
    },
    {
        key: "four",
        title: 'Core Focus',
        subHeading: "Free up your in-house team",
        heading: "Focus on core activities while offshore team handles routine tasks.",
        description: "By delegating routine tasks to your offshore team, your in-house team can focus on more strategic and high-value activities, such as innovation, product strategy, and customer interaction. This improves efficiency and productivity.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/odcestablishment/o4.png`,
    },
    {
        key: "five",
        title: 'Process Optimization',
        subHeading: "Improved efficiency and collaboration",
        heading: "Efficiently manage resources, streamline development processes.",
        description: "An ODC can help you streamline your development processes and improve resource management. With clear communication channels and well-defined workflows, you can ensure efficient collaboration between your in-house and offshore teams.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/odcestablishment/o5.png`,
    },
];



const OdcEstablishmentData = () => {
    return (
        <section className="common-tab">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default OdcEstablishmentData