import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import GetQuoteLinearButton from '../../../common-component/get-quote-linear-btn/GetQuoteLinearButton'
import CommonImage from '../../../common-component/common-image/CommonImage'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CareerFirstDetails from '../career-first-details/CareerFirstDetails'

const CareerFirst = () => {
    return (
        <section className='what-matters'>
            <Container>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CareerFirstDetails />
                        <GetQuoteLinearButton
                            label="Start Building Your Team Today"
                            icon={<FontAwesomeIcon icon={faArrowRight} />}
                        />
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CommonImage imgsrc='/assets/images/careerPage/growth.jpeg' />
                    </Col>
                </Row>

            </Container>
        </section>
    )
}

export default CareerFirst