import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import './TechStack.css';

const TechStack = () => {
    const rowImages = [
        [
            '/assets/images/aboutUs/rowOne/Angular.png',
            '/assets/images/aboutUs/rowOne/CloudDB.png',
            '/assets/images/aboutUs/rowOne/DotNet.png',
            '/assets/images/aboutUs/rowOne/Group21.png',
            '/assets/images/aboutUs/rowOne/Group26.png',
            '/assets/images/aboutUs/rowOne/Group27.png',
            '/assets/images/aboutUs/rowOne/Group29.png',
            '/assets/images/aboutUs/rowOne/Group30.png',
            '/assets/images/aboutUs/rowOne/IoS.png',
            '/assets/images/aboutUs/rowOne/Java.png',
            '/assets/images/aboutUs/rowOne/Jenkins.png',
            '/assets/images/aboutUs/rowOne/Kotlin.png',
            '/assets/images/aboutUs/rowOne/Orcle.png',
            '/assets/images/aboutUs/rowOne/Postgre.png',
            '/assets/images/aboutUs/rowOne/Python.png',
            '/assets/images/aboutUs/rowOne/React.png',
        ], // Images for the first row
        [
            '/assets/images/aboutUs/rowTwo/Alexa.png',
            '/assets/images/aboutUs/rowTwo/Aws.png',
            '/assets/images/aboutUs/rowTwo/Azure.png',
            '/assets/images/aboutUs/rowTwo/Docker.png',
            '/assets/images/aboutUs/rowTwo/GCloud.png',
            '/assets/images/aboutUs/rowTwo/Group18.png',
            '/assets/images/aboutUs/rowTwo/Kubernaties.png',
            '/assets/images/aboutUs/rowTwo/TeraByte.png',
        ], // Images for the second row
        [
            '/assets/images/aboutUs/rowThree/Group15.png',
            '/assets/images/aboutUs/rowThree/Group16.png',
            '/assets/images/aboutUs/rowThree/Group17.png',
            '/assets/images/aboutUs/rowThree/Group18.png',
            '/assets/images/aboutUs/rowThree/Group19.png',
            '/assets/images/aboutUs/rowThree/Group20.png',
            '/assets/images/aboutUs/rowThree/Group22.png',
            '/assets/images/aboutUs/rowThree/Group23.png',
            '/assets/images/aboutUs/rowThree/Group24.png',
            '/assets/images/aboutUs/rowThree/Microstratergy.png',
            '/assets/images/aboutUs/rowThree/MongoDB.png',
        ], // Images for the third row
    ];

    const [imageIndexes, setImageIndexes] = useState({
        row1: [0, 1, 2],
        row2: [0, 1, 2, 3],
        row3: [0, 1, 2],
    });

    const [highlightedRow, setHighlightedRow] = useState(1); 

    const getRandomUniqueIndexes = (length, numItems) => {
        const indexes = Array.from({ length }, (_, i) => i);
        for (let i = length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [indexes[i], indexes[j]] = [indexes[j], indexes[i]];
        }
        return indexes.slice(0, numItems);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setHighlightedRow((prevRow) => {
                const nextRow = prevRow === 3 ? 1 : prevRow + 1;
                if (nextRow === 1) {
                    setImageIndexes((prevIndexes) => ({
                        ...prevIndexes,
                        row1: getRandomUniqueIndexes(rowImages[0].length, 3),
                    }));
                } else if (nextRow === 2) {
                    setImageIndexes((prevIndexes) => ({
                        ...prevIndexes,
                        row2: getRandomUniqueIndexes(rowImages[1].length, 4),
                    }));
                } else {
                    setImageIndexes((prevIndexes) => ({
                        ...prevIndexes,
                        row3: getRandomUniqueIndexes(rowImages[2].length, 3),
                    }));
                }
                return nextRow;
            });
        }, 5000);

        return () => clearInterval(interval);
    }, [rowImages]);

    const renderBubbles = () => {
        return rowImages.map((images, rowIndex) => (
            <Row
                key={rowIndex}
                className={`mb-4 justify-content-center ${highlightedRow === rowIndex + 1 ? '' : 'row-opacity'}`}
            >
                {images
                    .slice(0, rowIndex === 1 ? 4 : 3)
                    .map((image, bubbleIndex) => (
                        <Col key={bubbleIndex} className="d-flex justify-content-center mb-3">
                            <div className="rounded-circle-container">
                                <Image
                                    src={rowImages[rowIndex][imageIndexes[`row${rowIndex + 1}`][bubbleIndex]]}
                                    alt={`Bubble ${bubbleIndex}`}
                                    fluid
                                    className="object-cover w-100 h-100 transition-opacity"
                                />
                            </div>
                        </Col>
                    ))}
            </Row>
        ));
    };

    return (
        <section className="techstack-container">
            <Container>
                {renderBubbles()}
            </Container>
        </section>
    );
};

export default TechStack;
