import React from 'react';
import './../../cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const DataMigrationFeature = () => {
   
const datamigrationservicefeatures = [
    {
        icon: "/assets/images/servicesPage/feature-image/25.svg",
        title: "Comprehensive Assessment",
        description:
            "Our expert team assesses your data warehouse, analyzing architecture, performance, security, and business needs to craft a tailored migration plan.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/26.svg",
        title: "Data Mapping and Cleansing",
        description:
            "We ensure accurate data mapping by cleansing, transforming, and validating your data to maintain integrity, enhancing its quality and reliability during migration.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/27.svg",
        title: "Migration Strategy",
        description:
            "We create a detailed migration strategy, ensuring a seamless transition to your new platform with minimal downtime and disruptions",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/28.svg",
        title: "Testing and Validation",
        description:
            "We rigorously test data integrity, validate queries and tools, and ensure seamless performance in the new environment for uninterrupted operations.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/29.svg",
        title: "Optimization and Performance Tuning",
        description:
            "Post-migration, we optimize your data warehouse by refining configurations, indexing, and queries to boost data processing and analytics efficiency.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/30.svg",
        title: "Secure Data Transfer",
        description:
            "Security is paramount. We employ advanced encryption techniques to transfer your data from the source warehouse to the destination. We ensure the confidentiality and integrity of your sensitive data throughout the migration process.",
    },
]

    return (
        <section className="feature-main">
            <Container>

            <CommonHeader title="Why Choose RedBeryl!" subtitle="The core values that drive everything we do " highlightSubtitleIndex={2} />

                <Row>
                    {datamigrationservicefeatures.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default DataMigrationFeature;
