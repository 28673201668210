import React from 'react';
import './OurClients.css';
import Slider from "react-slick";
import { Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonHeader from '../../common-component/common-header/CommonHeader';

const OurClients = () => {
   const settings = {
        dots: false,               
        arrows: false,             
        infinite: true,            
        speed: 2000,               
        slidesToShow: 4,           
        slidesToScroll: 1,         
        autoplay: true,           
        autoplaySpeed: 0,         
        cssEase: 'linear',  
        rtl: false,      
        responsive: [
            {
                breakpoint: 1200, 
                settings: {
                    slidesToShow: 4, 
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992, 
                settings: {
                    slidesToShow: 3, 
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 2, 
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576, 
                settings: {
                    slidesToShow: 1, 
                    slidesToScroll: 1,
                }
            }
        ]
    };

    // Array of client images and alt texts
    const clientImages = [
        { src: '/assets/images/landingPage/ingrain.png', alt: 'Ingrain' },
        { src: '/assets/images/landingPage/dataCareCorp.png', alt: 'Data Care Corp' },
        { src: '/assets/images/landingPage/IncubXperts.png', alt: 'IncubXperts' },
        { src: '/assets/images/landingPage/ayanWork.png', alt: 'Ayan Work' },
        { src: '/assets/images/landingPage/ingrain.png', alt: 'Ingrain' },
        { src: '/assets/images/landingPage/logos/redb (13).png', alt: 'Redb 13' },
        { src: '/assets/images/landingPage/logos/redb (12).png', alt: 'Redb 12' },
        { src: '/assets/images/landingPage/logos/redb (11).png', alt: 'Redb 11' },
        { src: '/assets/images/landingPage/logos/redb (10).png', alt: 'Redb 10' },
        { src: '/assets/images/landingPage/logos/redb (9).png', alt: 'Redb 9' },
        { src: '/assets/images/landingPage/logos/redb (8).png', alt: 'Redb 8' },
        { src: '/assets/images/landingPage/logos/redb (7).png', alt: 'Redb 7' },
        { src: '/assets/images/landingPage/logos/redb (6).png', alt: 'Redb 6' },
        { src: '/assets/images/landingPage/logos/redb (5).png', alt: 'Redb 5' },
        { src: '/assets/images/landingPage/logos/redb (4).png', alt: 'Redb 4' },
        { src: '/assets/images/landingPage/logos/redb (3).png', alt: 'Redb 3' },
        { src: '/assets/images/landingPage/logos/redb (2).png', alt: 'Redb 2' },
    ];

    return (
        <section className='our-client'>
            <Container>
                {/* <CommonHeader title="Our Clients" subtitle="Our reputation is built on the trust of our clients." 
                highlightSubtitleIndex={6}  /> */}
                
                <CommonHeader title="Our Clients" subtitle="Our reputation is built on the trust of our clients." highlightSubtitleIndex={6}/>
                
                <div className="slider-container">
                    <Slider {...settings}>
                        {clientImages.map((client, index) => (
                            <div key={index}>
                                <img
                                    src={process.env.PUBLIC_URL + client.src}
                                    className="client-img"
                                    alt={client.alt}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </Container>
        </section>
    );
}

export default OurClients;
