import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';



const tabData = [
    {
        key: "first",
        title: 'Resource Management',
        subHeading: "Scale up or down effortlessly with flexible resource management and pay-as-you-go options.",
        heading: "Adjust resources easily as needed",
        description: "Easily adapt to changing demands with our dynamic resource scaling. Increase or decrease capacity as needed, ensuring optimal performance and cost-efficiency. Pay only for what you use—streamlined and budget-friendly flexibility at your fingertips.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/cloud-solution/c1.png`,
    },
    {
        key: "second",
        title: 'Data Accessibility',
        subHeading: "Seamlessly retrieve your data from any location with secure, remote cloud access.",
        heading: "Access data remotely anytime",
        description: "Empower your team with secure, round-the-clock access to critical data from anywhere. Our cloud solution ensures seamless connectivity, enabling productivity and collaboration without boundaries—your data is always at your fingertips.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/cloud-solution/c2.png`,
    },
    {
        key: "third",
        title: 'Data Security',
        subHeading: "Robust security measures safeguard your data with encryption, monitoring, and secure access controls.",
        heading: "Ensure data protection with robust measures",
        description: "Protect your sensitive information with cutting-edge security features. Our cloud solution employs encryption, continuous monitoring, and multi-layered access controls to ensure your data remains safe and secure, giving you peace of mind.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/cloud-solution/c3.png`,
    },
    {
        key: "four",
        title: 'Modern Tech Solutions',
        subHeading: "Adopt cutting-edge technologies like AI, ML, IoT, and big data analytics effortlessly.",
        heading: "Quick adoption of new technologies",
        description: "Stay ahead with seamless access to the latest advancements, including AI, machine learning, serverless computing, and IoT. Our cloud platform simplifies integration, enabling rapid adoption of new technologies for enhanced innovation and growth.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/cloud-solution/c4.png`,
    },
    {
        key: "five",
        title:'Data Integration',
        subHeading: "Achieve smooth data integration with loosely coupled services for streamlined workflows.",
        heading: "Integrate data seamlessly across systems",
        description: "Simplify your operations with flexible data integration across diverse systems. Our loosely coupled services ensure seamless connectivity, enabling interoperability, reducing complexity, and fostering efficient workflows that adapt to your evolving business needs.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/cloud-solution/c5.png`,
    },
];

const CloudTabData = () => {
    return (
        <section className="common-tab">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center" >
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default CloudTabData