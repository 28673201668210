import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const contactDetails = [
  {
    icon: '/assets/images/icons/locationcontact.png',
    title: 'Office Address',
    text: '507-B,\nAmanora Chambers,\n Amanora Mall, Hadapsar,\n Pune - 411028',
    action: 'https://www.google.com/maps/search/?api=1&query=507-B+Amanora+Chambers+Amanora+Mall+Hadapsar+Pune+411028',
  },
  {
    icon: '/assets/images/icons/contact.png',
    title: 'Contact Number',
    // text: '+91 20472 27424 \n+91 76669 87829',
    action: 'tel:+91 20472 27424',
    additionalAction: 'tel:+91 76669 87829', 
  },
  {
    icon: '/assets/images/icons/address.png',
    title: 'Mail Address',
    text: 'info@redberyltech.com',
    action: 'mailto:info@redberyltech.com',
  },
  {
    icon: '/assets/images/icons/social.png',
    title: 'Social Media Links',
    isSocial: true,
    socialIcons: [
      { icon: faFacebookF, link: 'https://www.facebook.com/RedberylTech' },
      { icon: faLinkedinIn, link: 'https://www.linkedin.com/in/redberyl-tech-solutions/' },
      { icon: faInstagram, link: 'https://www.instagram.com/info.redberyl.tech/' },
      { icon: faXTwitter, link: 'https://x.com/RedberylTech' },
    ],
  },
];

const ContactAddress = () => {
  return (
    <div className='contact-address mt-4'>
      {contactDetails.map((detail, index) => (
        <div className='main-divvv d-flex' key={index}>
          <div>
            <img
              src={process.env.PUBLIC_URL + detail.icon}
              className="contact-icon-img me-3"
              alt={`${detail.title} icon`}
            />
          </div>
          <div>
            <h3 className='blue-text'>{detail.title}</h3>
            {detail.isSocial ? (
              <div>
                {detail.socialIcons.map((social, socialIndex) => (
                  <a 
                    key={socialIndex} 
                    href={social.link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className='contact-social-link'
                  >
                    <FontAwesomeIcon
                      icon={social.icon}
                      className='contact-social-icon'
                    />
                  </a>
                ))}
              </div>
            ) : (
              <div>
                {detail.action ? (
                  <a href={detail.action} className='contact-action-link'>
                    <p className='normal-text contact-us-details'>
                      {/* Ensure text exists before calling split */}
                      {detail.text && detail.text.split('\n').map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </a>
                ) : (
                  <p className='normal-text contact-us-details'>
                    {/* Ensure text exists before calling split */}
                    {detail.text && detail.text.split('\n').map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                )}
                {/* Handle additional action for second phone number */}
                {detail.additionalAction && (
                  <div style={{lineHeight:"7px"}}>
                  <a href={detail.additionalAction} className='contact-action-link'>
                    <p className='normal-text contact-us-details'>
                      {detail.additionalAction.split(':')[1]} 
                    </p>
                  </a>
                  <a href={detail.action} className='contact-action-link'>
                    <p className='normal-text contact-us-details'>
                      {detail.action.split(':')[1]} 
                    </p>
                  </a>
                </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactAddress;
