import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';


const tabData = [
    {
        key: "first",
        title: 'Collaborative Efforts',
        heading    : "Foster creativity and drive innovation with diverse expertise",
        subHeading : "The power of collaboration",
        description: "By bringing together individuals with different backgrounds and skillsets, collaborative product development fosters a dynamic and creative environment. Diverse perspectives challenge assumptions, spark new ideas, and lead to innovative solutions.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/productdevlopment/p1.png`,
    },
    {
        key: "second",
        title: 'On Time Delivery',
        subHeading: "Efficiency and speed",
        heading: "Streamline development cycles and accelerate product launches.",
        description: "Collaborative product development allows teams to work together seamlessly, eliminating communication bottlenecks and reducing development time. This streamlined approach accelerates product launches, helping you get your products to market faster and gain a competitive edge.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/productdevlopment/p2.png`,
    },
    {
        key: "third",
        title: 'Quality Measures',
        subHeading: "Building better products together",
        heading: "Enhance product quality through collective feedback and continuous improvement.",
        description: "Through collaborative feedback and continuous improvement, teams can identify and address potential issues early in the development process. This iterative approach ensures that the final product meets the highest quality standards and exceeds customer expectations.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/productdevlopment/p3.png`,
    },
    {
        key: "four",
        title: 'Customer Focused',
        subHeading: "Focus on customers",
        heading: "Putting the customer first.",
        description: "Collaborative product development emphasizes customer needs and preferences throughout the entire process. By actively involving customers in the development process, you can ensure that your products meet their needs and expectations, leading to higher customer satisfaction.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/productdevlopment/p4.png`,
    },
    {
        key: "five",
        title: 'Cost Effectiveness',
        subHeading: "Cut costs smartly",
        heading: "Cost-effectiveness and efficiency.",
        description: "Collaborative product development reduces redundant efforts and optimizes resource allocation. By working together efficiently, teams can achieve more with less, reducing development costs and improving overall project profitability.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/productdevlopment/p5.png`,
    },
];



const ProductDevlopementData = () => {
    return (
        <section className="common-tab">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default ProductDevlopementData