import { faCalendarDays, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './JobCard.css';
import KnowMoreModal from './job-card-modal/KnowMoreModal';
import ApplyModal from './job-card-modal/ApplyModal';

const JobCard = ({
    jobTitle,
    postingDate,
    experienceRequired,
    skills,
    companyName,
    jobDescription,
    location,
    languages
}) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [applymodalShow, setApplyModalShow] = React.useState(false);

    return (
        <section className='job-card-main'>
            <div className='main-card-white'>
                <div>
                    <div>
                        <h4 className='blue-text'>{jobTitle}</h4>
                        <div className='d-flex'>
                            <p className='me-5 normal-text-card'>
                                <FontAwesomeIcon icon={faCalendarDays} className='me-2' />
                                {postingDate}
                            </p>
                            <p className='normal-text-card'>
                                <FontAwesomeIcon icon={faUserGear}  className='me-2'/>
                                {experienceRequired} Years
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='job-desc'>{skills}</p>
                </div>
                <div>
                    <button className='know-more-btn-job' onClick={() => setModalShow(true)}>
                        Know More
                    </button>
                    <button className='apply-btn' onClick={() => setApplyModalShow(true)}>
                        Apply
                    </button>
                </div>
            </div>

            {/* Know More Modal */}
            <KnowMoreModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onApplyNowClick={() => {
                    setModalShow(false); // Close Know More Modal
                    setApplyModalShow(true); // Open Apply Modal
                }}
                companyName={companyName} // Pass company name
                jobDescription={jobDescription} // Pass company description
                skills={skills} // Pass job description
                location={location} // Pass job location
                experienceRequired={experienceRequired} // Pass experience required
                languages={languages} // Pass languages required
                jobTitle={jobTitle}
            />

            {/* Apply Modal */}
            <ApplyModal
                show={applymodalShow}
                onHide={() => setApplyModalShow(false)}
                jobTitle={jobTitle} // Pass jobTitle as a prop to ApplyModal
                // generic={false}
            />
        </section>
    );
};

export default JobCard;
