import React from 'react'
import '../tab-details/TabDetails.css'

const CommonImage = ({ imgsrc, alt }) => {
    return (
        <section className="tab-details-main">
            <div className="main-second-tab">
                <img
                    src={process.env.PUBLIC_URL + '/assets/images/servicePage/h2-shape2.png'}
                    className="dot-img"
                    alt="Decorative dots"
                />
                <div className="white-bggg">
                    <img
                        src={imgsrc}
                        className="tab-details-img-huge"
                        alt={alt}
                    />
                </div>
            </div>
        </section>
    )
}

export default CommonImage

