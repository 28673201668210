import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';


const tabData = [
    {
        key: "first",
        heading: "Boost brand visibility with web and mobile platforms.",
        subHeading: "Reach a wider audience",
        title: "Brand Visibilty",
        description: "Web and mobile apps provide a powerful platform to showcase your brand to a wider audience. With a well-designed website and user-friendly mobile app, you can reach potential customers wherever they are and increase your brand's visibility in the digital world.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/appdevlopemnt/a1.png`,
    },
    {
        key: "second",
        heading: "Enhance customer connections through personalized experiences.",
        subHeading: "Building stronger relationships",
        title: "Client Engagement",
        description: "Web and mobile apps allow you to create personalized experiences for your customers. By gathering data and preferences, you can tailor content, offers, and services to individual needs, fostering stronger customer relationships and loyalty.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/appdevlopemnt/a2.png`,
    },
    {
        key: "third",
        heading: "Offer convenient access to your business anytime, anywhere.",
        subHeading: "Accessibility and availability",
        title: "High Mobility",
        description: "With web and mobile apps, your business is accessible to customers 24/7, from anywhere in the world. This convenience allows customers to easily access information, make purchases, and interact with your business on their own terms.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/appdevlopemnt/a3.png`,
    },
    {
        key: "four",
        heading: "Outpace competitors with innovative app solutions.",
        subHeading: "A competitive edge",
        title: "Innovative Solutions",
        description: "Innovative app solutions can give you a significant competitive advantage. By leveraging the latest technology and offering unique features, you can differentiate your business from the competition and attract new customers.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/appdevlopemnt/a4.png`,
    },
    {
        key: "five",
        heading: "Scale operations to meet growing demand.",
        subHeading: "Flexibility and growth",
        title: "Scale & Grow",
        description: "Web and mobile apps provide the flexibility to scale your operations as your business grows. You can easily expand your reach, add new features, and accommodate increasing customer demand without significant overhead costs.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/appdevlopemnt/a5.png`,
    },
];



const AppDevlopementData = () => {
    return (
        <section className="common-tab">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default AppDevlopementData