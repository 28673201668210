import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';



const tabData = [
    {
        key: "first",
        title: 'Thorough Data Assessment',
        subHeading: "Evaluate and prepare data environments for smooth transitions.",
        heading: "Thorough data assessment.",
        description: "Comprehensive evaluation ensures a customized migration strategy by analyzing data architecture, performance metrics, and business requirements.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/datamigration/d1.png`,
    },
    {
        key: "second",
        title: 'Accurate Data Mapping',
        subHeading: "Transform and align data for seamless integration.",
        heading: "Accurate data mapping",
        description: "Map, cleanse, and enhance data integrity during migrations to improve accuracy and reliability in the target environment.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/datamigration/d2.png`,
    },
    {
        key: "third",
        title: 'Strategic Migration Planning',
        subHeading: "Plan migrations to minimize disruptions and ensure precision.",
        heading: "Strategic migration planning",
        description: "Develop strategies tailored to cloud transitions, upgrades, or platform shifts, reducing downtime and optimizing workflows.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,

        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/datamigration/d3.png`,
    },
    {
        key: "four",
        title: 'Robust Testing and Validation',
        subHeading: "Ensure reliability with rigorous post-migration testing.",
        heading: "Robust testing and validation",
        description: "Validate data and processes through comprehensive integrity checks, ensuring seamless operational continuity.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/datamigration/d4.png`,
    },
    {
        key: "five",
        title: 'Secure and Optimized Transfers',
        subHeading: "Protect and enhance data during migration",
        heading: "Secure and optimized transfers",
        description: "Advanced encryption safeguards data, while fine-tuning configurations boosts the efficiency of analytics and data processing.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/datamigration/d5.png`,
    },
];


const DataMigrationData = () => {
    return (
        <section className="common-tab">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default DataMigrationData