import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner'
import OurClients from '../../homepage/our-clients/OurClients'
import Testimonials from '../../homepage/testimonials/Testimonials'
import DataFeature from './data-migration-feature/DataMigrationFeature.js'
import DataMigrationFeature from './data-migration-feature/DataMigrationFeature.js'
import GetQuote from '../../common-component/get-quote/GetQuote.js'
import CommonHeader from '../../common-component/common-header/CommonHeader.js'
import DataMigrationData from './data-migration-data/DataMigrationData.js'

const DataMigration = () => {
  return (
    <>
      <CommonServiceBanner
        imageSrc={'/assets/images/servicesPage/datamigration.png'}
        subheading={''}
        title={'Data Migration'}
        titleSecond={''}
        description={'Efficiently shift Data from one Database/Data Warehouse (DB/DW) to another. Seamlessly migrate, ensuring data integrity, optimizing performance and preserving architecture for uninterrupted analytics.Explore our range of services, including data assessment, migration strategy development, ETL (Extract, Transform, Load) processes, and post-migration support'}
        quoteButtonLabel={'Contact Us'}
        downloadButtonLabel={'Download Brouchure'}
      />
      <DataMigrationData />
      <DataMigrationFeature />
      {/* <CommonHeader/> */}
      <OurClients />
      <Testimonials />
      <GetQuote title="Seamless data migration" subtitle="Ensure secure, efficient, and accurate database transitions with our tailored migration services. From assessment to performance tuning, we deliver reliability at every step." />
    </>
  )
}

export default DataMigration