import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Card, Modal,Button, Container } from 'react-bootstrap';
import './gallery.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; 
import { useSwipeable } from 'react-swipeable'; 
import {galleryimages} from "./galleryImagesData"

const Gallery = ({ 
  bgColor = 'bg-primary', 
  gradCheck = false, 
  maxGalleries = Infinity, 
  galleryIds = []  
}) => {
  const [selectedImages, setSelectedImages] = useState(
    galleryimages.reduce((acc, item) => {
      acc[item.id] = item.src;
      return acc;
    }, {})
  );

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [currentGalleryId, setCurrentGalleryId] = useState(null);
  const [currentSubimageIndex, setCurrentSubimageIndex] = useState(null);

  const [isGalleryPage, setIsGalleryPage] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes('/gallery')) {
      setIsGalleryPage(false);
    } else {
      setIsGalleryPage(true);
    }
  }, []);

  

  const handleSubImageClick = useCallback((galleryId, subImageSrc, index) => {
    setSelectedImages((prevSelectedImages) => ({
      ...prevSelectedImages,
      [galleryId]: subImageSrc,
    }));
    setModalImage(subImageSrc);
    setCurrentGalleryId(galleryId);
    setCurrentSubimageIndex(index);
    setShowModal(true);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentGalleryId(null);
    setCurrentSubimageIndex(null);
  };

  const handleNextImage = () => {
    if (currentGalleryId === null || currentSubimageIndex === null) return;

    const gallery = galleryimages.find((item) => item.id === currentGalleryId);
    const nextIndex = (currentSubimageIndex + 1) % gallery.subimages.length;
    setModalImage(gallery.subimages[nextIndex].src);
    setCurrentSubimageIndex(nextIndex);
  };

  const handlePrevImage = () => {
    if (currentGalleryId === null || currentSubimageIndex === null) return;

    const gallery = galleryimages.find((item) => item.id === currentGalleryId);
    const prevIndex = (currentSubimageIndex - 1 + gallery.subimages.length) % gallery.subimages.length;
    setModalImage(gallery.subimages[prevIndex].src);
    setCurrentSubimageIndex(prevIndex);
  };

  // Handle swipe gestures using react-swipeable
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextImage,  
    onSwipedRight: handlePrevImage, 
    trackMouse: true,            
  });

  // Filter the galleries based on the galleryIds prop
  const filteredGalleries = galleryIds.length > 0
    ? galleryimages.filter(gallery => galleryIds.includes(gallery.id))
    : galleryimages;

  // Limit the number of galleries displayed based on maxGalleries prop
  const limitedGalleries = filteredGalleries.slice(0, maxGalleries);

  useEffect(() => {
    // Function to handle keydown event
    const handleKeyDown = (event) => {
      if (currentGalleryId === null || currentSubimageIndex === null || !showModal) return;
  
      if (event.key === 'ArrowLeft') {
        // Left arrow key pressed - show the previous image
        handlePrevImage();
      } else if (event.key === 'ArrowRight') {
        // Right arrow key pressed - show the next image
        handleNextImage();
      }
    };
  
    // Add event listener for keydown event
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener when the component is unmounted or modal is closed
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentGalleryId, currentSubimageIndex, showModal]);
  
  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center">
        <Row>
          {limitedGalleries.map((gallery) => (
            <Col key={gallery.id} md={isGalleryPage ? 12 : 4} sm={12} className="mb-4">
              <Card className="h-100">
                <Card.Body className={gradCheck ? 'gradient-card' : ''}>
                  {/* Main image */}
                  <div className="main-image-container">
                    <label className="gallery-title">{gallery.title}</label>
                    <img
                      src={selectedImages[gallery.id]}
                      alt={gallery.alt}
                      className="img-fluid mb-3 main-image"
                      onClick={() => handleSubImageClick(gallery.id, selectedImages[gallery.id], 0)}
                      loading="lazy"
                    />

                    {/* Subimages */}
                    <div className="subimage-container">
                      {gallery.subimages.map((subimage, index) => (
                        <img
                          key={subimage.src}
                          src={subimage.src}
                          alt={subimage.alt}
                          className="img-thumbnail me-2 subimage"
                          onClick={() => handleSubImageClick(gallery.id, subimage.src, index)}
                          loading="lazy"
                        />
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Modal for showing the clicked image */}
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
          <Modal.Body {...swipeHandlers}> {/* Add swipe handlers here */}
            <div className="modal-image-container">
              <FaChevronLeft
                className="modal-arrow left-arrow"
                onClick={handlePrevImage}
              />
              <img
                src={modalImage}
                alt="Modal View"
                className="img-fluid"
              />
              <FaChevronRight
                className="modal-arrow right-arrow"
                onClick={handleNextImage}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
};

export default React.memo(Gallery);