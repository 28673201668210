import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner'
import CommonHeader from '../../common-component/common-header/CommonHeader'
import Testimonials from '../../homepage/testimonials/Testimonials'
import GetQuote from '../../common-component/get-quote/GetQuote'
import FAQs from '../../common-component/common-faq/FAQs'
import OptimusPrimeData from './optimus-prime-data/OptimusPrimeData'
import OptimusPrimeHugeData from './optimus-prime/OptimusPrimeHugeData'
import OptimusPrimeFeature from './optimus-prime-features/OptimusPrimeFeature'

const OptimusPrime = () => {


  const faqData = [
    {
      question: "What types of data can be migrated using the tool?",
      answer: "The tool supports the migration of various types of data, including structured and unstructured data, across different database platforms."
    },
    {
      question: "How customizable is the tool for different migration needs?",
      answer: "The tool is highly configurable, allowing users to customize migration workflows, mappings, and transformations to suit their specific requirements."
    },
    {
      question: "Can the tool estimate the time and resources required for migration?",
      answer: "Yes, the tool provides planning and estimation features that help users anticipate the time, resources, and effort needed for the complete migration process."
    },
    {
      question: "Are there any limitations on the size or complexity of data that can be migrated?",
      answer: "There are no any limitations on the size or complexity of data that can be migrated"
    },
    {
      question: "What is the size of data migration?",
      answer: "The tool is designed to handle migrations of all sizes and complexities, from small-scale projects to enterprise-level migrations."
    },
    {
      question: "Can this tool convert schema compatible with target database/data warehouse?",
      answer: "Yes, the tool supports migrations between different database platforms, including relational databases, NoSQL databases, and cloud-based databases."
    },
    {
      question: "How does the tool provide real-time reports on migration progress?",
      answer: "The tool offers real-time, comprehensive reports on migration progress, providing insights into data transferred, errors encountered, and remaining tasks."
    },
    {
      question: "How user-friendly is the tool for non-technical users?",
      answer: "Yes, technical support is available to assist users during the migration process, including troubleshooting issues and providing guidance on best practices."
    },
    {
      question: "What security measures are in place to protect sensitive data during migration?",
      answer: "The tool incorporates security features such as encryption, access controls, and data masking to safeguard sensitive data during migration."
    },
    {
      question: "How user-friendly is the tool for non-technical users?",
      answer: "The tool features a user-friendly interface with intuitive controls and step-by-step guidance, making it accessible to both technical and non-technical users."
    }
  ];

  const optimusTestimonials = [

    {
      id: 1,
      image: "/assets/images/reviews/reviewPersonM.png",
      name: "Jitendra M.",
      role: "Business Head | India Based Technology Company",
      quote: "What truly sets RedBeryl apart is their ability to tailor their solutions to our unique needs. Their ability to understand our goals and challenges, resulting in highly customized technology solutions that have driven our growth and efficiency. In an ever-evolving tech landscape, RedBeryl has consistently proven itself to be a reliable and visionary partner.",
  },
  {
    id: 2,
    image: "/assets/images/reviews/reviewPersonM.png",
    name: "Ranjit H.",
    role: "CTO | India Based Healthcare Company",
    quote: "RedBeryl has consistently delivered top-notch services and products that have not only met but exceeded our expectations. Their team of experts is always at the forefront of the latest technological advancements, which has allowed us to stay ahead in our industry.",
},
  ]


  return (
    <>

      <section>
        <CommonServiceBanner
          imageSrc={"/assets/images/productsPage/OptimusPrimeBg.png"}
          subheading={"Simplify huge & complex data transfer with"}
          title={"Advanced Data"}
          titleSecond={"Migration Tool"}
          description={
            "Optimus Prime is a state-of-the-art Database Migration Tool, designed to facilitate seamless data transition from one database to another. It empowers businesses to move their critical data efficiently, ensuring data integrity, granularity, quality, security, and minimal downtime."
          }
          quoteButtonLabel={"Contact Us"}
          downloadButtonLabel={"Download Brouchure"}
        />         
        <OptimusPrimeData />
        <OptimusPrimeHugeData />
        <OptimusPrimeFeature />
        <CommonHeader highlightSubtitleIndex={2} subtitle='Powering data migration for modern enterprises' title='Our Clients' />
        <div style={{ textAlign: "center", marginBottom: "35px" }}>

          <img src="/assets/images/landingPage/logos/optimus/optimus_voucha.png" />
        </div>
        <Testimonials  testimonials={optimusTestimonials}/>

        <GetQuote title="Let’s empower your technology landscape with our seamless data migration tool.
" subtitle="Making it easy for you to achieve your business goals in quick and easy way." />



        <FAQs faqData={faqData} />


      </section>

    </>
  )
}

export default OptimusPrime