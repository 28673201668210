import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner'
import OurClients from '../../homepage/our-clients/OurClients'
import Testimonials from '../../homepage/testimonials/Testimonials'
import CloudFeature from './cloud-solution-feature/CloudFeature'
import GetQuote from '../../common-component/get-quote/GetQuote'
import CloudTabData from './cloud-tab-data/CloudTabData'
import CommonHeader from '../../common-component/common-header/CommonHeader'

const CloudSolution = () => {
  return (
    <>
      <CommonServiceBanner
        imageSrc={'/assets/images/servicesPage/ServicesCloudSolution.png'}
        subheading={''}
        title={'Cloud Solutions'}
        titleSecond={''}
        description={'Cloud Technology Solutions harnesses cloud computing to enable businesses to store, manage and access data and applications remotely and providing scalability, flexibility and cost-efficiency while reducing the need for on-premises infrastructure. Explore our comprehensive suite of cloud services, ranging from cloud strategy consultation and infrastructure design to migration, deployment, and ongoing support'}
        quoteButtonLabel={'Contact Us'}
        downloadButtonLabel={'Download Brouchure'}
      />

      <CloudTabData/>

      <CloudFeature />

{/* 
<CommonHeader/> */}
      <OurClients />
      <Testimonials />

      <GetQuote title="Elevate your business with cloud solutions today!"  subtitle="Appropriate for your specific business, making it easy for you to have quality IT services."/>
    </>
  )
}

export default CloudSolution