import React from 'react'
import './GetQuoteButton.css'
import { Link } from 'react-router-dom'

const GetQuoteButton = ({text, onClick,to}) => {
  return (
    <div className='main-get-button-class'>
      <Link to={to}>
        <button className='get-quote-btn' onClick={onClick}> {text}</button>
      </Link>
    </div>
  )
}

export default GetQuoteButton