import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

const CareerFirstDetails = () => {
    return (
        <section className='first-job-section'>
            <div>
                <h4 className='sub-hedaing-first-job'>Maximize your company's potential</h4>
                <h3 className='heding-first-job'>Find your perfect <span className='pink-text-heding'>candidate</span></h3>
                <p className='blk-normal-text'>Welcome to our recruiter page! We specialize in top-tier talent staffing with well-established and proven practices to build and enhance your team. Let's collaborate to drive success together.</p>
            </div>
            <div className='common-tab mb-4'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="tabs-link-holder">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Robust Talent Pool</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Tailored Strategies</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third"> Proven Success</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className='mt-4'>
                        <Tab.Pane eventKey="first">
                            <p className='normal-text'>Access to a diverse and extensive network of skilled and prescreened professionals spanning various industries and roles, ensuring you get access to top-tier candidates for every position.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <p className='normal-text'>Tailored approaches to candidate sourcing, screening, and placement, ensuring that each recruitment process is customized to meet the unique requirements of clients.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <p className='normal-text'>Demonstrated success in delivering high-quality talent solutions, backed by testimonials, case studies, and a history of successful placements, instilling confidence in recruiters partnering with the company.</p>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

        </section>
    )
}

export default CareerFirstDetails