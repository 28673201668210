import React from 'react';
import "./featureCard.css"

const FeatureCard = ({ icon, title, description, showBorder = true }) => {
    return (
        <section className='feature-card-section'>
            <div>
                <div
                    className={`feature-card  ${showBorder ? 'border custom-border' : ''}`}
                >
                    <div className=" feature-card-content">
                        <img
                            loading="lazy"
                            src={icon}
                            alt={`${title} feature icon`}
                            className="feature-card-icon mb-4"

                        />
                        <h3 className="card-title mb-3">{title}</h3>
                        <p className="card-description">{description}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeatureCard;