import React, { useState } from 'react';
import { CommonBanner } from '../common-component/common-banner/CommonBanner';
import OurClients from '../homepage/our-clients/OurClients';
import Testimonials from '../homepage/testimonials/Testimonials';
import AboutSecondSection from './about-second/AboutSecondSection';
import AboutFourthSection from './about-four/AboutFourthSection';
import ItSolution from '../homepage/it-solution/ItSolution';
import AboutFive from './about-five/AboutFive';
import AboutThree from './about-three/AboutThree';
import AboutFeature from './about-feature/AboutFeature';
import CommonHeader from '../common-component/common-header/CommonHeader';
import { Container } from 'react-bootstrap';
import './AboutUs.css';  // Import the CSS file
import { Link } from 'react-router-dom';

const AboutUs = () => {
//   const [position, setPosition] = useState({ x: 0, y: 0 });

//   const handleMouseMove = (e) => {
//     const rect = e.target.getBoundingClientRect();
//     setPosition({
//       x: e.clientX - rect.left,
//       y: e.clientY - rect.top,
//     });
//   };

  return (
    <>
      <CommonBanner pageTitle={"About Us"} bg={'/assets/images/aboutUs/bannerAboutUs.png'} />
      <AboutSecondSection />
      <AboutThree />
      <AboutFourthSection />
      <AboutFive />
      <AboutFeature />
      <OurClients />
      <Testimonials />
      <CommonHeader title='Team Members' subtitle='Passionate personalities, versatile brains' highlightSubtitleIndex={2} />

      <Container>
        <div
          className="image-container"
        //   onMouseMove={handleMouseMove}
        >
          <img
            src='/assets/images/team/monochrome_new.png'
            className='team-members'
            alt="Team"
          />
          {/* <div
            className="cursor-effect"
            style={{
              left: position.x - 50,  
              top: position.y - 50, 
            }}
          /> */}
        </div>
      </Container>

      <ItSolution
        title="Unlock the power of seamless software solutions"
        description="Making it easy for you to achieve your business goals in a quick and easy way."
      buttonText={
        <Link to="/contact-us" style={{color:"white"}}>
            Contact Us Now!
        </Link>
    
      }
      />
    </>
  );
};

export default AboutUs;
