import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './../../../../components/common-component/tab-details/TabDetails.css';
import CommonImage from '../../../common-component/common-image/CommonImage';

const RfillingsHugeData = () => {
    const features= [
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "Multi-Company Setup",
          description: "Quickly add new companies, ensuring each has accurate details and is ready for order submissions."
        },
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "Assign Key Roles",
          description: "Designate roles like organizer, primary contact, or account manager for each company, aligning with compliance and operational needs."
        },
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "Centralized Company Overview",
          description: "View and manage essential details across all client companies in one convenient dashboard, keeping information easily accessible for orders and compliance."
        }
      ];
      


    return (
        <section className="tab-details-main">
            <Container>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                       <CommonImage imgsrc='/assets/images/servicePage/TabContent.png' />
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <h4 className="tab-sub-heading">Flexible entity management

                            </h4>
                            <h3 className="tab-heading mt-4">
                            Easily add and manage client entities

                            </h3>
                            <p className="normal-text-main mt-4">
                            Empower client admins to organize and maintain key information about each entity they manage. with R-Filings, client admins can add multiple entities, assign key personnel, and ensure each organization’s details are up-to-date.
                            </p>
                            <div className="mt-5">
                                {features.map((feature, index) => (
                                    <div key={index} className="d-flex mb-4">
                                        <div className="me-3">
                                            <img
                                                src={process.env.PUBLIC_URL + feature.icon}
                                                alt={`${feature.title} icon`}
                                            />
                                        </div>
                                        <div>
                                            <h3 className="blue-text">{feature.title}</h3>
                                            <p className="normal-text">{feature.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default RfillingsHugeData;