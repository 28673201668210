import Accordion from 'react-bootstrap/Accordion';
import "./FAQsSection.css"
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import CommonHeader from '../common-header/CommonHeader';

function FAQs({ faqData }) {
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey); // Toggle active state
  };

  return (
<section className="main-faq" >

<Container>
        <CommonHeader title='FAQs' subtitle='Frequently asked questions' />

    <Accordion activeKey={activeKey} onSelect={handleSelect}>
      {faqData.map((item, index) => (
        <Accordion.Item 
          eventKey={String(index)} 
          key={index} 
          className="accordion-item"
        >
          <Accordion.Header className={`accordion-header ${activeKey == String(index) ? 'accordion-header-active' : ''}`}>
            {item.question}
          </Accordion.Header>
          <Accordion.Body className="accordion-body">
            {item.answer}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
    </Container>
</section>


  );
}

export default FAQs;
