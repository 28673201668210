import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import '../AboutUs.css'
import AboutSecondSubSection from './AboutSecondSubSection';

const AboutSecondSection = () => {
    return (
        <section className='about-main '>
            <Container>
                <Row>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                        <div className='first-job-section'>
                            <div>
                                <h4 className='sub-hedaing-first-job'>About us</h4>
                                <h3 className='heding-first-job'>Choose the best IT<br />services company </h3>
                                <p className='blk-normal-text'>RedBeryl is a leading, software product development and staffing company based in Pune, India. With our professional team, we integrate businesses with latest cutting edge technologies that meet our client needs and unlock tomorrow's opportunities.</p>
                            </div>
                            <div className='common-tab mb-4'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Nav variant="pills" className="tabs-link-holder">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Our Mission</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Our Vision</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Our Value</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className='mt-4'>
                                        <Tab.Pane eventKey="first">
                                            <p className='normal-text'>Committed to create robust & scalable solutions to assist businesses of all sizes to achieve their goals and growth by providing new horizon to their business through cloud technologies. Collaborate with business partners at every stage of their cloud modernization journey.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <p className='normal-text'>Build a platform to provide the cutting edge technology solutions to effectively utilize the power of cloud to our business partners. Improve business values through meaningful innovation using unique RedBeryl Products, Services & proven practices.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <p className='normal-text'>We value innovation, collaboration, transparency, trust and inclusion. Together we’re building a better way to bring synergy between business and technology.</p>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                        <AboutSecondSubSection />
                    </Col>
                </Row>

            </Container>

        </section>
    )
}

export default AboutSecondSection