import React, { useEffect } from 'react';
import './DevlopmentProcess.css';
import { Col, Container, Row } from 'react-bootstrap';
import CommonHeader from '../../common-component/common-header/CommonHeader';
import AOS from 'aos';
import 'aos/dist/aos.css';

const DevlopmentProcess = () => {
    // Data array for the development process steps
    const processSteps = [
        {
            icon: "/assets/images/servicePage/Time.svg",
            title: 'Design Thinking and Rapid Prototyping',
            description: 'Empathy-driven design focuses on user pain points, with rapid prototyping enabling quick idea validation and iterative improvements through feedback.',
        },
        {
            icon: "/assets/images/servicePage/verification.svg",
            title: 'Microservices and Serverless Architectures',
            description: 'Modular microservices enable scalable deployments, while serverless platforms reduce infrastructure costs and improve performance for dynamic needs.',
        },
        {
            icon: "/assets/images/servicePage/revenu.svg",
            title: 'AI-Powered Development and Automation',
            description: 'AI-driven development uses machine learning and natural language processing to understand and assist with code, streamlining tasks and enhancing software quality.',
        },
        {
            icon: "/assets/images/servicePage/system.svg",
            title: 'DevSecOps for Built-In Security',
            description: 'Security is integrated at every stage using automated tools for vulnerability checks, enabling fast releases without compromising data protection.',
        },
    ];

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <section className='devlopment-process'>
            <Container>
                <CommonHeader title='Our Process' subtitle='We follow streamlined development process' highlightSubtitleIndex={3} />
                <Row data-aos="fade-up"
                    data-aos-duration="3000">
                    {processSteps.map((step, index) => (
                        <Col key={index} xl={3} lg={6} md={6} sm={6} xs={12}>
                            <div className='main-div-class'>
                                <img
                                    src={process.env.PUBLIC_URL + step.icon}
                                    alt={step.title}
                                    className="devlop-img"
                                />
                                <h3 className='blue-heading'>{step.title}</h3>
                                <p className='normal-text'>{step.description}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default DevlopmentProcess;
