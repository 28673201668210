import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';
import CommonHeader from '../../../common-component/common-header/CommonHeader';
const tabData = [
    {
        key: "first",
        heading: "Launch your business with ease",
        subHeading: "Seamlessly set up for success!",
        title: "Entity Formation",
        description: "Our platform streamlines the formation of corporations, LLCs, PCs, and PLLCs, empowering service providers to assist entrepreneurs in setting up their businesses effortlessly, across all 50 states.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/R-filing/r1.png`,
    },
    {
        key: "second",
        heading: "Simplify legal documentation",
        subHeading: "Precision at every step",
        title: "Corporate Filings",
        description: "Handle complex filings like dissolutions, mergers, LPs, conversions, and more efficiently. Our tools ensure precision and compliance for every step of the corporate filing process.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/R-filing/r2.png`,
    },
    {
        key: "third",
        heading: "Effortless compliance tracking",
        subHeading: "Stay compliant, stay confident",
        title: "Compliance",
        description: "Help businesses maintain good standing with services like annual and biennial reports, reinstatements, DBA filings, and more. Automated tools keep deadlines and requirements in check.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/R-filing/r3.png`,
    },
    {
        key: "fourth",
        heading: "Manage ongoing responsibilities",
        subHeading: "Streamline recurring obligations",
        title: "Subscriptions",
        description: "Support businesses with subscription-based services like registered agent and independent manager roles. Our platform ensures timely renewals and seamless management.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/R-filing/r4.png`,
    },
    {
        key: "fifth",
        heading: "Equip businesses with essentials",
        subHeading: "Professional tools delivered",
        title: "Document Retrieval",
        description: "Provide high-quality corporate kits, seals, and stock/membership certificates to clients. Our platform ensures these essentials are accessible and delivered promptly.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/R-filing/r5.png`,
    },
    {
        key: "sixth",
        heading: "Effortless beneficial ownership information reporting (BOIR)",
        subHeading: "Stay transparent, stay secure",
        title: "Beneficial Ownership Information",
        description: "Help businesses comply with Beneficial Ownership Information (BOI) reporting requirements seamlessly. Our platform simplifies the process, ensuring accuracy and timely submissions, while keeping sensitive information secure and aligned with regulatory mandates.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/R-filing/r6.png`,
    },
];




const RFillingsData = () => {
    return (
        <section className="common-tab">
            <Container>
            <CommonHeader title='R-Filings Provides A Platform To' subtitle='Register businesses and manage compliance in few minutes' highlightSubtitleIndex={4}/>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default RFillingsData