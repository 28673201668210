import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './CommonHeader.css'; 

const CommonHeader = ({
  title = "What We Do",
  subtitle = "We provide IT services that your business needs",
  subtitleWidth = "w-100",
  titleCol = "text-pink",
  highlightSubtitleIndex = 8, 
  highlightCol = "text-pink" 
}) => {

  const subtitleWords = subtitle.split(' ');

  return (
    <section className='common-header'>
      <Container>
        <Row className="justify-content-center ">
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="service-header-container">
              <div className="service-header-wrapper">
                <div className="service-header-inner">
                  <div className="service-header-title-container">
                    <div className="service-header-title">
                      <div className="service-header-title-wrapper d-flex align-items-center justify-content-center">
                        {/* Left Divider - Using Bootstrap border */}
                        <div className="service-header-divider border-start red-divider"></div>
                        
                        {/* Title */}
                        <div className="service-header-title">{title}</div>
                        
                        {/* Right Divider - Using Bootstrap border */}
                        <div className="service-header-divider-right border-end red-divider"></div>
                      </div>
                    </div>
                    <div className={`${subtitleWidth} service-header-subtitle poppins-bold-italic`}>
                      <div className="service-header-subtitle-wrapper poppins-bold-italic ">
                        {subtitleWords.map((word, index) => (
                          <span key={index} className={index === highlightSubtitleIndex ? "highlighted" : ''}>
                            {word}{' '}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CommonHeader;
