import React from "react";
import "./App.css";
import "./index.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import HomePage from "./components/homepage/HomePage";
import AboutUs from "./components/about-us/AboutUs";
import ContactUs from "./components/contact-us/ContactUs";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";

// Products
import Hrms from "./components/productsPage/hrms/Hrms";
import OptimusPrime from "./components/productsPage/optimus-prime/OptimusPrime";
import Rfillings from "./components/productsPage/rfillings/Rfillings";
import Products from "./components/productsPage/products/Products";

// Career
import Jobseeker from "./components/career-page/job-seeker/Jobseeker";
import Recruiters from "./components/career-page/recruiters/Recruiters";

// Services
import CloudSolution from "./components/services-page/cloud-solution/CloudSolution";
import DataMigration from "./components/services-page/data-migration/DataMigration";
import TalentStaffing from "./components/services-page/talent-staffing/TalentStaffing";
import SoftwareModerization from "./components/services-page/software-moderization/SoftwareModerization";
import AppDevlopement from "./components/services-page/app-devlopement/AppDevlopement";
import AutomationDevops from "./components/services-page/automation-devops/AutomationDevops";
import ProductDevlopement from "./components/services-page/product-devlopement/ProductDevlopement";
import OdcEstablishment from "./components/services-page/odc-establishment/OdcEstablishment";
import Services from "./components/services-page/services/Services";
import RShopizer from "./components/productsPage/r-shopizer/RShopizer";
import GalleryContainer from "./components/gallery/GalleryContainer";
import ChatMain from "./components/chat-bot/ChatMain";
import BlogPage from "./components/blog-page/BlogPage";
import {
  AuthorPage,
  BlogPostPage,
  CategoryPage,
  TagPage,
} from "./components/blog-page/components/BlogPostPage";

import { ProductLoginPage } from "./components/login-pages/ProductLoginPage.js";
import EmailJSComponent from "./components/test/EmailTest.jsx";

function App() {
  const location = useLocation();

  // Check if the current path is "/product-login"
  const isProductLoginPage = location.pathname === "/product-login";
  
  return (
    <>
      <ScrollToTop />
      
      {/* Conditionally render the ChatMain component */}
      {!isProductLoginPage && <ChatMain />}
      
      {/* Conditionally render the Header */}
      {!isProductLoginPage && <Header />} 

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/career" element={<Jobseeker />} />

        {/* Products */}
        <Route path="/products/" element={<Products />} />
        <Route path="/products/optimus-prime" element={<OptimusPrime />} />
        <Route path="/products/r-recruit" element={<Hrms />} />
        <Route path="/products/r-fillings" element={<Rfillings />} />
        <Route path="/products/r-shopizer" element={<RShopizer />} />

        {/* Product login page */}
        <Route path="/product-login" element={<ProductLoginPage />} />

        {/* Service Page */}
        <Route path="/services" element={<Services />} />
        <Route path="/services/cloud-solutions" element={<CloudSolution />} />
        <Route path="/services/data-migration" element={<DataMigration />} />
        <Route path="/services/talent-staffing" element={<TalentStaffing />} />
        <Route path="/services/software-moderization" element={<SoftwareModerization />} />
        <Route path="/services/app-devlopment" element={<AppDevlopement />} />
        <Route path="/services/devops-automation" element={<AutomationDevops />} />
        <Route path="/services/collaborative" element={<ProductDevlopement />} />
        <Route path="/services/odc-establishment" element={<OdcEstablishment />} />

        {/* <Route path="/mail" element={<EmailJSComponent />} /> */}

        {/* Career Page */}
        <Route path="/career/job-seeker" element={<Jobseeker />} />
        <Route path="/career/recruiter" element={<Recruiters />} />

        {/* Gallery */}
        <Route path="/gallery" element={<GalleryContainer />} />

        {/* Blog Page */}
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:postId" element={<BlogPostPage />} />
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route path="/tag/:tag" element={<TagPage />} />
        <Route path="/author/:author" element={<AuthorPage />} />
      </Routes>

      {/* Conditionally render the Footer */}
      {!isProductLoginPage && <Footer />} 
    </>
  );
}

export default App;
