import React from 'react'
import './DownloadButton.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const DownloadButton = ({buttonname, onClick}) => {
  return (
    <div>
        <button className='download-btn'  onClick={onClick}>{buttonname} <FontAwesomeIcon icon={faPlus} /></button>
    </div>
  )
}

export default DownloadButton