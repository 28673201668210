import React from 'react'
import './Testimonials.css'
import { Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";


const defaultTestimonials = [
    {
        id: 1,
        image: "/assets/images/reviews/reviewPersonM.png",
        name: "Jitendra M.",
        role: "Business Head | India Based Technology Company",
        quote: "What truly sets RedBeryl apart is their ability to tailor their solutions to our unique needs. Their ability to understand our goals and challenges, resulting in highly customized technology solutions that have driven our growth and efficiency. In an ever-evolving tech landscape, RedBeryl has consistently proven itself to be a reliable and visionary partner.",
    },
    {
        id: 2,
        image: "/assets/images/reviews/reviewPersonF.png",
        name: "Jyotika A.",
        role: "CPO | India Based IT Company",
        quote: "Working with the RedBeryl team has been an invaluable experience for our organization. They have consistently demonstrated their commitment to finding the right talent for our company, helping us build a strong and capable workforce.",
    },
    {
        id: 3,
        image: "/assets/images/reviews/reviewPersonF.png",
        name: "Bhakti T.",
        role: "CEO | India Based Technology Company",
        quote: "Our partnership with RedBeryl team has proven to be an exceptional asset to our organization. Their services have not only saved us time but have also helped us find outstanding talent. We look forward to continuing our collaboration and would highly recommend their services to any company seeking top-notch staffing solutions.",
    },
    {
        id: 4,
        image: "/assets/images/reviews/reviewPersonM.png",
        name: "Prateek M.",
        role: "Product Manager | India Based Retail Company",
        quote: "I am thrilled to offer my heartfelt testimonial for RedBeryl. This innovative and forward-thinking tech company has been an integral part of our success. Their cutting-edge solutions and unwavering commitment to excellence have transformed the way we do business.",
    },
    {
        id: 5,
        image: "/assets/images/reviews/reviewPersonF.png",
        name: "Sam C.",
        role: "CTO | Technology Company",
        quote: "RedBeryl's dedication to quality and their ability to quickly adapt to our changing requirements have made a significant impact on our business. We appreciate their prompt communication, regular updates, and commitment to maintaining a strong working relationship.",
    },
    {
        id: 6,
        image: "/assets/images/reviews/reviewPersonM.png",
        name: "Sriram.",
        role: "Recruitment Head | Technology Fortune 500 Company",
        quote: "RedBeryl's professionalism, expertise, and attention to detail have made the hiring process efficient and hassle-free. They took the time to understand our specific needs and culture, which resulted in the successful placement of highly qualified candidates who fit seamlessly into our team.",
    },
    {
        id: 7,
        image: "/assets/images/reviews/reviewPersonM.png",
        name: "Ranjit H.",
        role: "CTO | India Based Healthcare Company",
        quote: "RedBeryl has consistently delivered top-notch services and products that have not only met but exceeded our expectations. Their team of experts is always at the forefront of the latest technological advancements, which has allowed us to stay ahead in our industry.",
    },
    {
        id: 8,
        image: "/assets/images/reviews/reviewPersonM.png",
        name: "Pramod G.",
        role: "Product Manager | India Based Technology Company",
        quote: "The level of support and dedication provided by RedBeryl is truly outstanding. From the initial consultation to ongoing maintenance and support, their team has been responsive, knowledgeable, transparent and a pleasure to work with.",
    },
    {
        id: 9,
        image: "/assets/images/reviews/reviewPersonM.png",
        name: "Matt G.",
        role: "Product Manager | US Based Technology Company ",
        quote: "RedBeryl has demonstrated impressive flexibility and ability to quickly pivot with the evolving requirements of the project. They have been quick with their turnaround time and actively engaged throughout the duration of the project. They have been a strong partner throughout the project journey.",
    }
];


const Testimonials = ({testimonials=defaultTestimonials}) => {
    // Function to get the initials from the name
    const getInitials = (name) => {
        const nameParts = name.split(" ");
        const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join("");
        return initials;
    };

    return (
        <section className='main-tesimonial'>
            <Container>
                <Row>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={30}
                        autoplay={{
                            delay: 15000,
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true, dynamicBullets: true }}
                        breakpoints={{
                            0: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            991: { slidesPerView: 2 },
                            1440: { slidesPerView: 2 },
                        }}
                        modules={[Pagination, Autoplay]}
                        className="myswiper"
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index}>
                                <div className='testi-main-card mb-5'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <div className=' me-3 initials-container mt-2'>
                                                {/* Display initials instead of image */}
                                                <span className="user-initials ">
                                                    {getInitials(testimonial.name)}
                                                </span>
                                            </div>
                                            <div className='main-class-user-details'>
                                                <h5 className='user-name'>{testimonial.name}</h5>
                                                <p className='user-role'>{testimonial.role}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icons/doublecomma.png"}
                                                className="comma-img"
                                                alt="quote"
                                            />
                                        </div>
                                    </div>
                                    <div className='testi-main-quote mt-3'>
                                        <p className='normal-text quote'>{testimonial.quote}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Row>
            </Container>
        </section>
    );
}

export default Testimonials;
