const propertiesApp={
    // strapiUrl:"http://localhost:8765",
    // strapi:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzI4NDc4NzU5LCJleHAiOjE3MzEwNzA3NTl9.7p-9sTupewfrln8JjF358lAkqwFnKU8964JfIJ1CAeM",
    // react_host:"http://localhost:8765",
    // strapi_java_host:"http://localhost:8765",
    // stripe_host:"http://localhost:8000",
    // chatbot_url:"https://chatbot.redberyltest.in",
    // automation_host:"http://localhost:3001",
    // quickbooks_host:"http://localhost:6000",
    python_url:"http://192.168.1.108:9500"
}


export default propertiesApp;