import React, { useState } from "react";
import { Container, Button, Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ProductPage.css";
import GetQuoteLinearButton from "../../../common-component/get-quote-linear-btn/GetQuoteLinearButton";

export function ProductSection({ bg = "/assets/servicePage/serviceBg.png" }) {
  const [activeProductIndex, setActiveProductIndex] = useState(0);

  const handleProductClick = (index) => {
    setActiveProductIndex(index);
  };

  const products = [
    {
      icon: "/assets/images/servicePage/optimusprimeProductSection.svg",
      imgSrc: "/assets/images/productsPage/productsOptimusPrimeNew.png",
      title: "Optimus Prime",
      link: "/products/optimus-prime",
      description:
        "Optimus Prime helps for seamless migrations of dataware houses with lightning speed. This is a completely configurable tool which helps users to plan and estimate the complete migration activities. It also has a plug-n-play testing module that tests migrated data vs source data (cell by cell) in near real time with comprehensible reports. Tool gives you information about most likely differences beforehand so that those exceptions can be added to the exclusion list of validations. This enables users to have clean validation reports and easy sign-off on migrated data.",
      onClickSvg: "/assets/images/productsPage/onClickOptimusPrime.svg",
    },
    {
      icon: "/assets/images/servicePage/hrmsProductSection.svg",
      imgSrc: "/assets/images/productsPage/productsHRMSnew.png",

      title: "R-Recruit ",
      description:
        "R-Recruit  is essentially a human resource software which meticulously combines all the different HR processes and systems to ensure easy management and smooth functioning of different business processes. The application of R-Recruit is aimed at enhancing the performance of employees to help accomplish the business goals of the company.User-centric website shows your audience that the website owners care about their customers, which enhances the usability of your website.",
      link: "/products/R-Recruit ",
      onClickSvg: "/assets/images/productsPage/onClickHRMs.svg",
    },
    {
      icon: "/assets/images/servicePage/productsRfiling.svg",
      imgSrc: "/assets/images/productsPage/productsRFilingsnew.png",

      title: "R-Filings",
      link: "/products/r-fillings",
      description:
        "R-Filings offers a user-centric platform designed to simplify business compliance and operations. With features like seamless CRM and accounting integration, it ensures efficient client management and financial tracking. The intuitive interface allows users to navigate easily, while real-time status updates keep businesses informed about filing progress. Advanced analytics and automated compliance tracking make managing deadlines across states effortless, ensuring businesses remain compliant without stress.",
      onClickSvg: "/assets/images/productsPage/onCLickRFile.svg",
    },

    {
      icon: "/assets/images/productsPage/rshopizer-btn-icon.png",
      imgSrc: "/assets/images/productsPage/products-r-shopizer.png",

      title: "R-Shopizer",
      link: "/products/r-shopizer",
      description:
        "R-Shopizer is at the forefront of the retail revolution, seamlessly integrating with ONDC to empower retailers to thrive in the digital marketplace while enhancing the overall shopping experience for customers. Committed to quality, reliability, and customer satisfaction, R-Shopizer aims to be your preferred online shopping destination. Join our growing community of satisfied shoppers and explore a diverse range of products, enjoying a seamless and enjoyable shopping journey tailored to your needs. Experience the future of retail with R-Shopizer, where exceptional service and a user-friendly interface redefine your online shopping experience.",
      onClickSvg: "/assets/images/productsPage/rshopizer-onclick-btn-icon.png",
    },
  ];

  return (
    <section className="product-tab-mainnnn">
      <Container>
        <Row >
          {/* Left Column: Product Cards */}
          <Col lg={4} md={4} >
            <div className="product-section-header">
              <div className="company-main">
                <div className="product-header-content">
                  {/* <div className="company-name "> */}

                  <div className =" company-name sub-hedaing-first-job">
                    Redberyl technologies
                  </div>
                  <div className=" company">
                    A next-gen product development company
                  </div>
                </div>
              </div>
            </div>

            {/* Product Cards Section */}
            <Row className="product-title-main mt-4">
              {products.map((product, index) => (
                <Col
                  key={index}
                  lg={10}
                  xs={12}
                  className={`mb-4 ${activeProductIndex === index ? "active" : ""}`}
                >
                  <Card
                    onClick={() => handleProductClick(index)}
                    className={`product-card cursor-pointer ${activeProductIndex === index ? "active" : ""}`}
                  >
                    <Card.Body>
                      <div className="product-card-header d-flex justify-content-start align-items-center">
                        {/* Conditionally render either icon or onClickSvg */}
                        <Card.Img
                          src={activeProductIndex === index ? product.onClickSvg : product.icon}
                          className="product-icon"
                        />
                        <Card.Title className="product-title">
                          {product.title}
                        </Card.Title>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>

          {/* Right Column: Product Image and Details */}
          <Col lg={8} md={8} xs={12} >
            <Row>
              {/* Product Image */}
              <Col md={6} xs={12}>
                <img
                  loading="lazy"
                  src={products[activeProductIndex].imgSrc}
                  alt="productImage"
                  className="img-fluid rounded product-image"
                />
              </Col>

              {/* Product Details */}
              <Col md={6} xs={12} >
                <h2 className="text-custom-pink mb-3 active-product-title">
                  {products[activeProductIndex].title}
                </h2>
                <p className=" mb-4 active-product-description">
                  {products[activeProductIndex].description}
                </p>

                {/* Buttons */}
                <div className="d-flex justify-content-center justify-content-md-start gap-3 product-btn">
                  <Button
                    as={Link}
                    to={`${products[activeProductIndex].link}`}
                    className="px-4 py-2 text-base text-custom-blue  know-more-btn font-bold"
                  >
                    Know More
                  </Button>
                  <GetQuoteLinearButton label="Login" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>

  );
}
