import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import './ContactForm.css'
import ContactAddress from '../contact-address/ContactAddress';
import AOS from 'aos';
import 'aos/dist/aos.css';
import GetQuoteLinearButton from '../../common-component/get-quote-linear-btn/GetQuoteLinearButton';

const options = [
  { value: 'Optimus Prime', label: 'Optimus Prime' },
  { value: 'R-Recruit', label: 'R-Recruit' },
  { value: 'R-Fillings', label: 'R-Fillings' },
  { value: 'R-Shopizer', label: 'R-Shopizer' },

  { value: 'Cloud Solution', label: 'Cloud Solution' },
  { value: 'Data Migration', label: 'Data Migration' },
  { value: 'Talent Staffing', label: 'Talent Staffing' },
  { value: 'Software Modernization (AI/ML)', label: 'Software Modernization (AI/ML)' },
  { value: 'Web & Mobile App Development', label: 'Web & Mobile App Development' },
  { value: 'DevOps Automation', label: 'DevOps Automation' },
  { value: 'Collaborative Product Development', label: 'Collaborative Product Development' },
  { value: 'ODC Establishment', label: 'ODC Establishment' },
];


const ContactForm = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className="contact-form" data-aos="fade-up"
    data-aos-duration="3000" >
      <Container>
        <Row className="justify-content-center">
          <Col xxl={3} xl={4} lg={4} md={5} sm={6} xs={12}>
            <p className='contact-heading'>Ready to take your
              <span className='pink-word'> business</span> to new heights?</p>
          </Col>
          <Col xxl={5} xl={5} lg={5} md={7} sm={6} xs={12}>
            <p className="normal-text contact-form-description">
              We're here to help! Whether you're looking for innovative IT solutions, expert guidance, or simply want to learn more about our services, our team is ready to assist you. 
              <br />
              Reach out to us through our contact form.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xxl={3} xl={4} lg={4} md={5} sm={6} xs={12}>
            <div>
              <ContactAddress />
            </div>
          </Col>
          <Col xxl={5} xl={5} lg={6} md={7} sm={6} xs={12}>
            <div className='contact-form-sub mt-4'  >
              <Form>
                <Row>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Name <span className='red-star'>*</span></Form.Label>
                      <Form.Control type="text" placeholder="Your Name" />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Email Address <span className='red-star'>*</span></Form.Label>
                      <Form.Control type="email" placeholder="name@example.com" />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formPhone">
                      <Form.Label>Phone (Optional)</Form.Label>
                      <Form.Control type="tel" placeholder="Your Phone Number" />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formService">
                      <Form.Label>Select Product/Service</Form.Label>
                      <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formMessage">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Write your message here..." style={{ resize: 'none' }}/>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <GetQuoteLinearButton label="Send message" />
            </div>
          </Col>
        </Row>
      </Container>
    </section >
  );
};

export default ContactForm;
