import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import '../home-banner/HomeBanner.css';

const HomeBannerSecond = ({ slidesData, activeIndex, setActiveIndex }) => {
  return (
    <section className='main-tesimonial'>
      <Container>
        <Row>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true, dynamicBullets: true }}
            modules={[Pagination, Autoplay]}
            className="myswiper"
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            initialSlide={activeIndex}
          >
            {slidesData.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className='white-bgg-banner mb-5'>
                  <img
                    src={process.env.PUBLIC_URL + '/assets/images/landingPage/' + slide.image}
                    className='banner-slider-img'
                    alt={slide.heading}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
      </Container>
    </section>
  );
};

export default HomeBannerSecond;
