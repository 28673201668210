import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner.js'
import OurClients from '../../homepage/our-clients/OurClients.js'
import Testimonials from '../../homepage/testimonials/Testimonials.js'
import DataFeature from './product-devlopement-feature/ProductDevlopementFeature.js'
import DataMigrationFeature from './product-devlopement-feature/ProductDevlopementFeature.js'
import GetQuote from '../../common-component/get-quote/GetQuote.js'
import ProductDevlopementFeature from './product-devlopement-feature/ProductDevlopementFeature.js'
import CommonHeader from '../../common-component/common-header/CommonHeader.js'
import ProductDevlopementData from './product-devlopement-data/ProductDevlopementData.js'

const ProductDevlopement = () => {
  return (
    <>
      <CommonServiceBanner
        imageSrc={'/assets/images/servicesPage/productdevlopemnt.png'}
        subheading={''}
        title={'Collaborative'}
        titleSecond={'Product Development'}
        description={'Collaborative product development fosters teamwork and innovation, bringing together diverse expertise. to create and refine products efficiently, ensuring a seamless blend of ideas, skills and market demands. Together, we embark on a journey of ideation, prototyping, and iteration, ensuring that every aspect of your product is meticulously crafted and optimized.'}
        quoteButtonLabel={'Contact Us'}
        downloadButtonLabel={'Download Brouchure'}
      />
<ProductDevlopementData/>


<ProductDevlopementFeature/>
{/* <CommonHeader/> */}

<OurClients />


      <Testimonials />
      <GetQuote title="Get the best product development team for your business today!
"  subtitle="Ensure secure, efficient, and accurate database transitions with our tailored migration services. From assessment to performance tuning, we deliver reliability at every step."/>
    </>
  )
}

export default ProductDevlopement