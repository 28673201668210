import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner'
import CommonHeader from '../../common-component/common-header/CommonHeader'

import Testimonials from '../../homepage/testimonials/Testimonials'
import GetQuote from '../../common-component/get-quote/GetQuote'
import FAQs from '../../common-component/common-faq/FAQs'

import RShopizerFeature from './r-shopizer-features/RShopizerFeature'
import RShopizerHugeData from './rfillingsData/RShopizerHugeData'
import RShopizerData from './r-shopizer-data/RShopizerData'

const RShopizer = () => {


   const faqData = [
      {
         question: "What is R-Shopizer, and how does it benefit businesses?",
         answer: "R-Shopizer is a scalable and customizable e-commerce platform. It empowers businesses with end-to-end e-commerce solutions, enabling streamlined transactions, enhanced customer experience, and efficient delivery management."
      },
      {
         question: "Is R-Shopizer suitable for small businesses, or is it designed for large enterprises?",
         answer: "R-Shopizer is highly configurable and supports businesses of all sizes, from small startups to large-scale enterprises. Its flexibility ensures it meets the unique needs of any business."
      },
      {
         question: "What mobile application features does R-Shopizer offer?",
         answer: "R-Shopizer provides Android and iOS mobile applications, ensuring a seamless and mobile-first shopping experience for your customers. This allows businesses to engage with users on their preferred devices."
      },
      {
         question: "How does R-Shopizer integrate with shipping services?",
         answer: "The platform offers integrated shipping solutions to simplify logistics, including real-time tracking and delivery management, ensuring customers receive their orders efficiently."
      },
      {
         question: "Which payment gateways are supported by R-Shopizer?",
         answer: "R-Shopizer is integrated with major payment gateways, enabling secure and seamless transactions for your customers."
      },
      {
         question: "What is ONDC integration, and how does it benefit businesses using R-Shopizer?",
         answer: "R-Shopizer’s ONDC integration allows businesses to tap into India's Open Network for Digital Commerce, expanding marketplace presence and boosting sales opportunities."
      },
      {
         question: "Can I customize the e-commerce platform to match my business requirements?",
         answer: "Yes, R-Shopizer is fully customizable. You can tailor the platform to align with your brand, product offerings, and operational workflows."
      },
      {
         question: "What kind of support does R-Shopizer provide during and after implementation?",
         answer: "R-Shopizer offers comprehensive onboarding support, technical assistance, and continuous updates to ensure your e-commerce platform runs smoothly."
      },
      {
         question: "Does R-Shopizer handle high traffic and sales volumes during peak periods?",
         answer: "Absolutely! R-Shopizer is designed to scale dynamically, ensuring optimal performance even during high-traffic periods, such as sales or festive seasons."
      },
      {
         question: "How does R-Shopizer ensure data security and compliance?",
         answer: "R-Shopizer follows industry-standard security protocols, including encrypted transactions, secure login, and compliance with global data protection regulations to safeguard your business and customer information."
      }
   ];

const rshopizerTestimonials = [
   {
      id: 1,
      image: "/assets/images/reviews/reviewPersonM.png",
      name: "Prateek M.",
      role: "Product Manager | India Based Retail Company",
      quote: "I am thrilled to offer my heartfelt testimonial for RedBeryl. This innovative and forward-thinking tech company has been an integral part of our success. Their cutting-edge solutions and unwavering commitment to excellence have transformed the way we do business.",
  },    {
   id:2,
   image: "/assets/images/reviews/reviewPersonM.png",
   name: "Pramod G.",
   role: "Product Manager | India Based Technology Company",
   quote: "The level of support and dedication provided by RedBeryl is truly outstanding. From the initial consultation to ongoing maintenance and support, their team has been responsive, knowledgeable, transparent and a pleasure to work with.",
},
]


   return (
      <>

         <section>

            <CommonServiceBanner
               imageSrc={"/assets/images/productsPage/rshopizer-main-bg.png"}
               subheading={"Transform Your Business With"}
               title={"E-Commerce"}
               titleSecond={"Solutions"}
               description={
                  "R-Shopizer's ONDC integration revolutionizes retail, empowering businesses with expanded reach and exceptional digital shopping experiences."}
               quoteButtonLabel={"Contact Us"}
               downloadButtonLabel={"Download Brouchure"}
            />
            <RShopizerData />
            <RShopizerHugeData />
            <RShopizerFeature />
            <div style={{ textAlign: "center", marginBottom: "35px" }} >
               <img src="/assets/images/productsPage/rShopizer/rShopizer-Client.png" />
            </div>
            <Testimonials testimonials={rshopizerTestimonials} />
            <GetQuote title="Revolutionize your e-commerce journey today!" subtitle="Explore a comprehensive suite of features designed to simplify operations, enhance scalability, and empower seamless digital commerce for businesses of all sizes." />
            <FAQs faqData={faqData} />


         </section>

      </>
   )
}

export default RShopizer