import React from 'react';

import '../../../services-page/cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const RShopizerFeature = () => {

    const features =[
      {
        icon: "/assets/images/productsPage/rShopizer/1.png",
        title: "End-to-End E-commerce System",
        description: "Comprehensive e-commerce solution covering every aspect of online retail to empower your business."
      },
      {
        icon: "/assets/images/productsPage/rShopizer/2.png",
        title: "Highly Configurable For Any Scale Business",
        description: "A flexible solution tailored for businesses of all sizes, from startups to enterprises."
      },
      {
        icon: "/assets/images/productsPage/rShopizer/3.png",
    
        title: "Available With Android And IOS Mobile Application",
        description: "Accessible on Android and iOS, offering mobile convenience anytime, anywhere."
      },
      {
        icon: "/assets/images/productsPage/rShopizer/4.png",
        title: "Integrated Shipping/Deliveries",
        description: "Streamlined shipping and delivery services to simplify business logistics."
      },
      {
        icon: "/assets/images/productsPage/rShopizer/5.png",
        title: "Integrated Major Payment Gateway",
        description: "Secure and seamless transactions with leading payment processors integrated."
      },
      {
        icon: "/assets/images/productsPage/rShopizer/6.png",
    
        title: "Powered With ONDC Integration",
        description: "Enhanced e-commerce capabilities with Open Network for Digital Commerce (ONDC)."
      }
    ];

    return (
        <section className="feature-main">
            <Container >
                <CommonHeader title='Why Choose R-Shopizer?' subtitle='Transform your business with cutting-edge e-commerce solutions' highlightSubtitleIndex={5}/>

                <Row>
                    {features.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default RShopizerFeature;
