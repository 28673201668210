import React from 'react'
import ProductsFeature from "./../products/products-features/ProductFeature"
import { CommonBanner } from '../../common-component/common-banner/CommonBanner'
import GetQuote from '../../common-component/get-quote/GetQuote'
import { ProductSection } from './product-section/ProductPage'

const Products = () => {



  return (
    <>

      <section>
        <CommonBanner pageTitle="Products" bg="/assets/images/productsPage/bannerProducts.png" />
        <ProductSection />
        <ProductsFeature />
        <GetQuote title="Looking for the best IT business solutions?" subtitle="Appropriate for your specific business, making it easy for you to have quality IT services." />
      </section>

    </>
  )
}

export default Products