import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Breadcrumb, Image } from 'react-bootstrap';
import './CommonBanner.css';

export const CommonBanner = ({ pageTitle, bg }) => {
    const [dirobj, setDirobj] = useState([]);
    const { postId } = useParams();  
    const { category, author} = "null"
    const location = useLocation(); 
    const pathToTitleMap = {
        '/about': 'About Us',
        '/gallery': 'Gallery',
        '/contact-us': 'Contact Us',
        '/products': 'Products',
        '/products/optimus-prime': 'Optimus Prime',
        '/products/r-recruit': 'HRMS',
        '/services': 'Services',
        '/career/job-seeker': 'Job Seeker',
        '/career/recruiter':"Recruiter",
        '/career': 'Career',
        '/blogs': 'Blogs',
        '/blog': 'Blogs',
        '/': 'Home',
    };

    const getPathTitle = (path) => {
        // console.log(path.split('/'))

        if (path.startsWith('/blog/') && path.split('/').length === 3) {
            return postId ? `${postId}` : 'Blog'; 
        }
    

        // if (path.startsWith('/blogs/category')) {
        //     return category ? `Category: ${category}` : 'Blog Categories';
        // }
    
        // if (path.startsWith('/blogs/author')) {
        //     return author ? `Author: ${author}` : 'Blog Authors';
        // }
    
        return pathToTitleMap[path] || path.charAt(0).toUpperCase() + path.slice(1).replace(/-/g, ' ');
    };
    

    useEffect(() => {
        const path = location.pathname;
        const pathParts = path.split('/').filter(Boolean);
        const fullPathParts = ['Home', ...pathParts];
        setDirobj(fullPathParts);
    }, [location]);

    return (
        <div className="banner" style={{ backgroundImage: `url(${bg})` }}>
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                        <h1 className="pageTitle">
                            {pageTitle || getPathTitle(location.pathname)}
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                        <Breadcrumb className="breadcrumb">
                            {dirobj.map((item, index) => {
                                const isHome = item === 'Home';
                                const fullPath = `/${dirobj.slice(1, index + 1).join('/')}`;
                                const pathTitle = getPathTitle(fullPath);

                                return (
                                    <React.Fragment key={index}>
                                        <Breadcrumb.Item
                                            linkAs={Link}
                                            linkProps={{ to: isHome ? '/' : fullPath }}
                                        >
                                            {pathTitle}
                                        </Breadcrumb.Item>
                                        {index < dirobj.length - 1 && (
                                            <Image
                                                src={process.env.PUBLIC_URL + "/assets/images/icons/in.svg"}
                                                className="seperator-img"
                                                alt="separator"
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
