import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './../../../../components/common-component/tab-details/TabDetails.css';
import CommonImage from '../../../common-component/common-image/CommonImage';

const HrmsHugeData = () => {
    const     features =[
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "AI-Powered Analysis",
          description: "Scans large volumes of resumes and profiles to match candidates based on relevant experience and skills."
        },
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "Customizable Filters",
          description: "Adjust filters based on unique job requirements to get refined search results."
        },
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "Candidate Ranking",
          description: "Prioritizes top candidates, saving time by highlighting the best matches for your needs."
        }
      ];



    return (
        <section className="tab-details-main">
            <Container>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                       <CommonImage imgsrc='/assets/images/productsPage/HRMS-tab-image.png' />
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <h4 className="tab-sub-heading">Find the perfect fit with data-driven precision
                            </h4>
                            <h3 className="tab-heading mt-4">
                            Predictive candidate search

                            </h3>
                            <p className="normal-text-main mt-4">
                            Our advanced solution leverages machine learning and algorithms to intelligently analyze resumes, skills, and job preferences, helping you identify the ideal candidates for your organization.
                            </p>
                            <div className="mt-5">
                                {features.map((feature, index) => (
                                    <div key={index} className="d-flex mb-4">
                                        <div className="me-3">
                                            <img
                                                src={process.env.PUBLIC_URL + feature.icon}
                                                alt={`${feature.title} icon`}
                                            />
                                        </div>
                                        <div>
                                            <h3 className="blue-text">{feature.title}</h3>
                                            <p className="normal-text">{feature.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default HrmsHugeData;