import * as React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom"; // Import useNavigate instead of useHistory
import "./BlogPostPage.css"; // You can style this page as needed
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { blogPosts as blogPostData } from "./BlogData";
import { CommonBanner } from "../../common-component/common-banner/CommonBanner";
import GalleryContainer from "../../gallery/GalleryContainer";
import Gallery from "../../gallery/Gallery";
import { BlogCard } from "./BlogCard";

const getBlogPost = (postId) => {
  const blogPosts = blogPostData;
  return blogPosts.find((post) => String(post.id) === String(postId));
};

export function BlogPostPage() {
  const { postId } = useParams();
  const navigate = useNavigate();
  const blogPost = getBlogPost(postId);

  if (!blogPost) {
    return (
      <Container fluid className="p-5">
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <div className="blog-post-content-wrapper">
              <div className="blog-post-header">
                <h2 className="blog-post-title">Oops! Post Not Found</h2>
                <p className="blog-post-meta">
                  We're sorry, but the blog post you're looking for doesn't exist or has been removed.
                </p>
              </div>
              <div className="blog-post-details">
                <p className="blog-post-meta">
                  You can go back to the <Link to="/blog" className="a">Blog Overview</Link> and explore other articles.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  
  const currentUrl = window.location.href;
  const prefilledMessage = `Check out this blog post: ${blogPost.title}`;

  const { title, image, date, author, content, tags, subcontent,galleryToShowOnBlog=1 } = blogPost;

  return (
    <>
      <CommonBanner
        pageTitle="Blogs"
        bg="/assets/images/blogPage/blog-banner.png"
      />
      <Container fluid className="blog-post-container p-5">
        <Row>
          <Col md={8} xs={12}>
            <div className="blog-post-content-wrapper">
              <Row>
                <Col>
                  <div className="blog-post-header">
                    <Image
                      src={image}
                      alt={title}
                      fluid
                      className="blog-post-image d-flex justify-center"
                    />
                    <p className="blog-post-meta">
                      <img
                        src="/assets/images/blogPage/clock.svg"
                        alt="clock"
                        className="blog-meta-img"
                      />
                      <span className="blog-post-date ">{date}</span>
                      <img
                        src="/assets/images/blogPage/person.svg"
                        alt="person"
                        className="blog-meta-img"
                      />
                      <span className="blog-post-author">
                        
                        <span className="by-word"> By </span>
                        <Link
                          to={`/author/${author}`}
                          className="author-link a"
                        >
                          {author}
                        </Link>
                      </span>{" "}
                      <img
                        src="/assets/images/blogPage/tag.svg"
                        alt="tag"
                        className="blog-meta-img"
                      />
                      {tags.map((tag, index) => (
                        <Link
                          key={index}
                          to={`/tag/${tag}`}
                          className="m-2 blog-post-tags"
                        >
                          {tag}
                        </Link>
                      ))}
                    </p>
                  </div>
                  <div className="blog-post-details">
                    <h1 className="blog-post-title">{title}</h1>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="blog-post-content">
                    <p>{content}</p>
                  </div>
                </Col>
              </Row>

              {/* Render Subcontent Dynamically */}
              <Row>
                {subcontent &&
                  subcontent.map((sub, index) => (
                    <Col key={index} md={12} className="subcontent-section">
                      {sub.subheadings
                        .split("\n")
                        .map((heading, headingIndex) => (
                          <div key={headingIndex}>
                            <h3 className="dynamic-subheading">{heading}</h3>

                            {/* Render description only for matching headingIndex */}
                            <div>
                              {/* Check if the headingIndex matches the index of subdescription */}
                              {sub.subdescription
                                .split("\n")
                                .map((line, lineIndex) => {
                                  // If the headingIndex matches the lineIndex for this subdescription
                                  if (headingIndex === lineIndex) {
                                    return (
                                      <p
                                        className="dynamic-text-content"
                                        key={lineIndex}
                                      >
                                        {line
                                          .split("\b")
                                          .map((part, partIndex) => (
                                            <>
                                              {partIndex > 0 && <br />}{" "}
                                              {/* Render line break when \b is found */}
                                              {part}
                                            </>
                                          ))}
                                      </p>
                                    );
                                  }
                                  return null;
                                })}
                            </div>

                            {/* Conditionally Render Image based on subHeadingImgInsertIndex */}
                            {sub.subHeadingImgs &&
                              sub.subHeadingImgs.length > 0 && (
                                <Row className="d-flex flex-wrap">
                                  {sub.subHeadingImgs.map((img, imgIndex) => {
                                    if (
                                      headingIndex ===
                                      sub.subHeadingImgInsertIndex
                                    ) {
                                      return (
                                        <Col
                                          key={imgIndex}
                                          xs={12}
                                          md={6}
                                          lg={4}
                                          className="mb-3"
                                        >
                                          <Image
                                            src={img}
                                            alt={`Subcontent image ${imgIndex +
                                              1}`}
                                            fluid
                                          />
                                        </Col>
                                      );
                                    }
                                    return null; // If index doesn't match, nothing is rendered
                                  })}
                                </Row>
                              )}

                            {/* Conditionally Render Quote based on quoteFromDescriptionInsertIndex */}
                            {sub.quoteFromDescription &&
                              parseInt(
                                sub.quoteFromDescriptionInsertIndex,
                                10
                              ) === headingIndex && (
                                <div className="quote-container">
                                  <blockquote className="quote-text">
                                    <p>{sub.quoteFromDescription}</p>
                                  </blockquote>
                                  <footer className="quote-author">
                                    - {sub.quoteFromDescriptionAuthor}
                                  </footer>
                                </div>
                              )}
                          </div>
                        ))}
                    </Col>
                  ))}
              </Row>

              <Row className="d-flex align-items-center justify-content-between mt-2">
                {/* Left Side - Tags */}
                <Col xs={12} md={6} className="d-flex flex-wrap">
                  <div className="tag">
                    <span className="tags-word">Tags: </span>
                    {tags.map((tag, index) => (
                      <button key={index} className="m-1 tag-btn">
                        <Link
                          key={index}
                          to={`/tag/${tag}`}
                          className="m-2 blog-post-tags"
                        >
                          {tag}
                        </Link>
                      </button>
                    ))}
                  </div>
                </Col>

                {/* Right Side - Social Share */}
                <Col
                  xs={12}
                  md={6}
                  className="d-flex align-items-center justify-content-end"
                >
                  <span className="share-word">Share: </span>
                  {/* Share on Facebook */}
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      currentUrl
                    )}&display=popup&ref=plugin&src=share_button`}
                    title="Share on Facebook"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="shared-counts-button facebook"
                  >
                    <span className="shared-counts-icon-label">
                      <span className="shared-counts-icon">
                        <FaFacebook size={20} />
                      </span>
                    </span>
                  </a>

                  {/* Share on Twitter */}
                  <a
                    href={`https://twitter.com/share?url=${encodeURIComponent(
                      currentUrl
                    )}&text=${encodeURIComponent(prefilledMessage)}`}
                    title="Share on Twitter"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="shared-counts-button twitter"
                  >
                    <span className="shared-counts-icon-label">
                      <span className="shared-counts-icon">
                        <FaTwitter size={20} />
                      </span>
                    </span>
                  </a>

                  {/* Share on LinkedIn */}
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                      currentUrl
                    )}&text=${encodeURIComponent(title)}`}
                    title="Share on LinkedIn"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="shared-counts-button linkedin"
                  >
                    <span className="shared-counts-icon-label">
                      <span className="shared-counts-icon">
                        <FaLinkedin size={20} />
                      </span>
                    </span>
                  </a>

                  {/* Share on WhatsApp */}
                  <a
                    href={`https://wa.me/?text=${encodeURIComponent(
                      prefilledMessage
                    )}%20${encodeURIComponent(currentUrl)}`}
                    title="Share on WhatsApp"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="shared-counts-button whatsapp"
                  >
                    <span className="shared-counts-icon-label">
                      <span className="shared-counts-icon">
                        <FaWhatsapp size={20} />
                      </span>
                    </span>
                  </a>

                  {/* Share via Email */}
                  <a
                    href={`mailto:?subject=${encodeURIComponent(
                      "Check out this blog post!"
                    )}&body=${encodeURIComponent(
                      prefilledMessage
                    )}%20${encodeURIComponent(currentUrl)}`}
                    title="Share via Email"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="shared-counts-button email"
                  >
                    <span className="shared-counts-icon-label">
                      <span className="shared-counts-icon">
                        <FaEnvelope size={20} />
                      </span>
                    </span>
                  </a>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="d-flex justify-content-center">
                  <button
                    className="back-to-blog"
                    onClick={() => navigate("/blog")}
                  >
                    Back to Blogs
                  </button>
                </Col>
              </Row>
            </div>
          </Col>

          {/* Sidebar Column - Categories, Recent Blog, Archive, Gallery, Tags */}
          <Col md={4} xs={12}>
            <div className="sidebar">
              <div className="sidebar-section">
                <h5>Categories</h5>
                <ul className="category-list">
                  {Array.from(
                    new Set(blogPostData.map((post) => post.category))
                  ).map((category, index) => (
                    <li key={index}>
                      <Link to={`/category/${category}`} className="a">
                        {category}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Render Recent Blogs Dynamically */}
              <div className="sidebar-section">
                <h5>Recent Blog</h5>
                <ul className="recent-blog-list">
                  {blogPostData.slice(0, 3).map((post) => (
                    <Link to={`/blog/${post.id}`}>
                      <li key={post.id} className="a">
                        {post.title}
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>

              <div className="sidebar-section">
                <h5>Archives</h5>
                <ul className="archive-list">
                  {/* <li><a href="#">January 2024</a></li>
                  <li><a href="#">December 2023</a></li>
                  <li><a href="#">November 2023</a></li> */}
                </ul>
              </div>

              <div className="sidebar-section">
                <h5>Gallery</h5>
                <Gallery maxGalleries={1} galleryIds={[galleryToShowOnBlog]} />
              </div>

              <div className="sidebar-section">
                <h5>Tags</h5>
                <div className="tag-list">
                  {tags.map((tag, index) => (
                    <Link
                      key={index}
                      to={`/tag/${tag}`}
                      className="m-2 tag-item"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export function TagPage() {
  const { tag } = useParams();
  const filteredPosts = blogPostData.filter((post) => post.tags.includes(tag));

  return (
    <Container fluid className="tag-page-container p-5">
      <Row>
        <Col>
          <h1 className="tag-page-title blog-post-title">Tag: {tag}</h1>
        </Col>
      </Row>

      <Row className="tag-page-blog-cards">
        {filteredPosts.map((post) => (
          <Col key={post.id} md={4} xs={12} className="mb-4">
            <BlogCard
              id={post.id}
              image={post.image}
              date={post.date}
              author={post.author}
              authorIcon="/assets/images/blogPage/person.svg" // You can replace with actual author icon
              dateIcon="/assets/images/blogPage/clock.svg" // You can replace with actual date icon
              title={post.title}
              backgroundImage="/assets/images/blogPage/background.png" // Optional background image if required
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export function CategoryPage() {
  const { category } = useParams();
  const filteredPosts = blogPostData.filter(
    (post) => post.category === category
  );

  return (
    <Container fluid className="category-page-container p-5">
      <Row>
        <Col>
          <h1 className="category-page-title blog-post-title">Category: {category}</h1>
        </Col>
      </Row>

      <Row className="category-page-blog-cards">
        {filteredPosts.map((post) => (
          <Col key={post.id} md={4} xs={12} className="mb-4">
            <BlogCard
              id={post.id}
              image={post.image}
              date={post.date}
              author={post.author}
              authorIcon="/assets/images/blogPage/person.svg" // You can replace with actual author icon
              dateIcon="/assets/images/blogPage/clock.svg" // You can replace with actual date icon
              title={post.title}
              backgroundImage="/assets/images/blogPage/background.png" // Optional background image if required
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export function AuthorPage() {
  const { author } = useParams();
  const filteredPosts = blogPostData.filter((post) => post.author === author);

  return (
    <Container fluid className="author-page-container p-5">
      <Row>
        <Col>
          <h1 className="author-page-title blog-post-title">
            Posts by {author}
          </h1>
        </Col>
      </Row>

      <Row className="author-page-blog-cards">
        {filteredPosts.map((post) => (
          <Col key={post.id} md={4} xs={12} className="mb-4">
            <BlogCard
              id={post.id}
              image={post.image}
              date={post.date}
              author={post.author}
              authorIcon="/assets/images/blogPage/person.svg" // You can replace with actual author icon
              dateIcon="/assets/images/blogPage/clock.svg" // You can replace with actual date icon
              title={post.title}
              backgroundImage="/assets/images/blogPage/background.png" // Optional background image if required
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
