import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner'
import CommonHeader from '../../common-component/common-header/CommonHeader'
import HrmsData from './hrms-data/HrmsData'
import HrmsHugeData from './hrms-huge-data/HrmsHugeData'
import HrmsFeature from './hrms-feature/HrmsFeature'
import Testimonials from '../../homepage/testimonials/Testimonials'
import GetQuote from '../../common-component/get-quote/GetQuote'
import FAQs from '../../common-component/common-faq/FAQs'

const Hrms = () => {

    const faqData = [
        {
          question: "What is Predictive Candidate Search, and how does it work?",
          answer: "Predictive Candidate Search leverages AI and machine learning to analyze resumes, skills, and job preferences, helping businesses identify ideal candidates quickly and accurately."
        },
        {
          question: "How does Paperless Employee Onboarding benefit organizations?",
          answer: "Paperless Employee Onboarding digitizes the entire process, from document submission to training modules, streamlining workflows and saving time by eliminating manual paperwork."
        },
        {
          question: "What is a Customizable Interview Workflow?",
          answer: "Customizable Interview Workflow allows businesses to create tailored interview processes for various roles, ensuring a seamless progression from screening to final hiring."
        },
        {
          question: "How does Automated Candidate Scoring help in hiring?",
          answer: "This feature ranks applicants based on skills, experience, and role alignment, enabling faster and more informed hiring decisions."
        },
        {
          question: "What is Candidate Sourcing and Role Matching?",
          answer: "It involves accessing a pre-vetted talent pool to quickly fill positions with candidates that match specific job requirements and company culture."
        },
        {
          question: "Can the platform assist with Interview Scheduling and Evaluation?",
          answer: "Yes, it simplifies scheduling and evaluation through automation, ensuring an efficient and positive experience for recruiters and candidates."
        },
        {
          question: "What is included in Employee Lifecycle Management?",
          answer: "Employee Lifecycle Management covers recruitment, onboarding, performance evaluations, and offboarding, offering a comprehensive view of an employee's journey."
        },
        {
          question: "How does Analytics and Reporting improve hiring processes?",
          answer: "Analytics provides insights into hiring trends, areas for improvement, and optimization strategies for recruitment efforts."
        },
        {
          question: "What does Comprehensive Candidate Profile and Feedback involve?",
          answer: "It provides detailed profiles, feedback from hiring teams, and a centralized view of candidate data for better decision-making."
        },
        {
          question: "Can the solution handle payroll and end-to-end hiring?",
          answer: "Yes, it integrates payroll management and streamlines the entire hiring process, from initial recruitment to onboarding, for a seamless experience."
        }
      ];


      const HrmsTestimonials = [
        {
          id: 1,
          image: "/assets/images/reviews/reviewPersonM.png",
          name: "Sriram.",
          role: "Recruitment Head | Technology Fortune 500 Company",
          quote: "RedBeryl's professionalism, expertise, and attention to detail have made the hiring process efficient and hassle-free. They took the time to understand our specific needs and culture, which resulted in the successful placement of highly qualified candidates who fit seamlessly into our team.",
      }, {
        id: 2,
        image: "/assets/images/reviews/reviewPersonF.png",
        name: "Jyotika A.",
        role: "CPO | India Based IT Company",
        quote: "Working with the RedBeryl team has been an invaluable experience for our organization. They have consistently demonstrated their commitment to finding the right talent for our company, helping us build a strong and capable workforce.",
    },
    {
      id: 3,
      image: "/assets/images/reviews/reviewPersonF.png",
      name: "Bhakti T.",
      role: "CEO | India Based Technology Company",
      quote: "Our partnership with RedBeryl team has proven to be an exceptional asset to our organization. Their services have not only saved us time but have also helped us find outstanding talent. We look forward to continuing our collaboration and would highly recommend their services to any company seeking top-notch staffing solutions.",
  },
]
  return (
    <>

    <section >
        
    <CommonServiceBanner
        imageSrc={"/assets/images/productsPage/R-RecruiteBg.png"}
        subheading={"Workforce excellence with R-Recruit"}
        title={"HRM & Staffing"}
        titleSecond={"Solutions"}
        description={
          "R-Recruit streamlines recruitment, employee engagement, client management and data-driven decisions, enhancing HR practices and contributing to organizational success."
        }
        quoteButtonLabel={"Contact Us"}
        downloadButtonLabel={"Download Brouchure"}
      />

    

      <HrmsData />


      <HrmsHugeData/>


      <HrmsFeature/>


      <CommonHeader highlightSubtitleIndex={2} subtitle='Trusted by hiring teams across industries' title='Our Clients'/>

      <div  style={{textAlign:"center", marginBottom:"35px"}}>
          <img src="/assets/images/landingPage/logos/hrms/recruite_primus.png"  alt='primus'/>
          <img src="/assets/images/landingPage/logos/hrms/recruite_deloitte.png"alt='deloitee' />
          <img src="/assets/images/landingPage\logos\hrms\recruite_incube.png"alt='incube' />
          <img src="/assets/images/landingPage\logos\hrms\recruite_datacorp.png"  alt='datacorp'/>

        </div>

      <Testimonials testimonials={HrmsTestimonials}/>

      <GetQuote title="Let’s automate and streamline recruitement efforts" subtitle="Catering businesses seeking end-to-end talent acquisition solutions."/>
      

      <FAQs faqData={faqData}/>

    
    </section>

    </>
  )
}

export default Hrms