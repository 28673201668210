import React, { useRef, useState } from "react";
import Chatbot from "./ChatBot";
import "./ChatMain.css"; // Importing the external CSS file

const ChatMain = () => {
  

  return (

              <Chatbot />

  );
};

export default ChatMain;
