import React from "react";
import { Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import "swiper/css/effect-fade";
import GetQuoteLinearButton from "../../common-component/get-quote-linear-btn/GetQuoteLinearButton";
import { Link, useNavigate } from "react-router-dom";

const HomeBannerFirst = ({ slidesData, activeIndex }) => {
  const navigate = useNavigate();

  const currentSlide = slidesData[activeIndex];

  return (
    <section className="main-tesimonial">
  <Container>
  <Row>
    <div className="tab-details-main mt-5 d-flex flex-column">
      <h4 className="tab-sub-heading">{currentSlide.subHeading}</h4>
      <h2 className="tab-heading">{currentSlide.heading}</h2>
      {/* <p className="normal-text">{currentSlide.text}</p> */}
      <div className="bullet-points">
        {currentSlide.bulletPoints.map((e) => {
          return (
            <div className="d-flex align-items-center mb-2 text-center home-bullet-container" key={e}>
              <img src="/assets/images/servicePage/imageGrid/check.svg" alt="High-Speed Transfer icon" className="me-4 " style={{width:"24px"}} />
              <p className="mb-0">{e}</p>
            </div>
          );
        })}
      </div>
    </div>

    <div className="home-hero-btn-container">
    <div className="d-flex  justify-content-start home-hero-btn">
      <GetQuoteLinearButton
        label="About Us"
        onClick={() => navigate("/about")}
        to="/about"
      />
      <Link to={currentSlide.link}>
        <div className="know-more-btn home-hero-btn-product">Know More</div>
      </Link>
    </div>
    </div>
  </Row>
</Container>

    </section>
  );
};

export default HomeBannerFirst;
