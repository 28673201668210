import React, { useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import PaginationCommon from "../../../common-component/pagination-common/PaginationCommon";
import RecruiterCard from "./RecruiterCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import recruiterData from "./RecruiterData.js" 
// console.log(recruiterData,"data")

const RecruiterPanel = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;



  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const filteredRecruiters = recruiterData.filter((recruiter) => {
    return (
      recruiter.jobTitle.toLowerCase().includes(searchTerm) ||
      recruiter.summery.toLowerCase().includes(searchTerm)
    );
  });

  const totalPages = Math.ceil(filteredRecruiters.length / itemsPerPage);

  const currentRecruiters = filteredRecruiters.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  return (
    <section className="job-panel">
      <Container>
        <Row className="justify-content-center">
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="text-center mb-4">
              <InputGroup className="mb-5">
                <Form.Control
                  type="text"
                  placeholder="Search Candidate...."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row>
          {currentRecruiters.map((data, index) => (
            <Col key={index} xl={6} lg={6} md={12} sm={12} xs={12}>
              <div className="mb-4">
                <RecruiterCard data={data} />
              </div>
            </Col>
          ))}
        </Row>
        <PaginationCommon
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </Container>
    </section>
  );
};

export default RecruiterPanel;
