import { Form, Modal } from 'react-bootstrap';

const ApplyModal = (props) => {
    const { generic = false } = props;
    console.log(generic)
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="apply-modal-title"
                centered
                scrollable
                className="common-main-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="apply-modal-title">
                        <h4 className="modal-headdd">{generic ? 'Submit Profile' : 'Apply'}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="fullName">
                            <Form.Label>Full Name *</Form.Label>
                            <Form.Control type="text" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="contact">
                            <Form.Label>Contact *</Form.Label>
                            <Form.Control type="tel" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="altContact">
                            <Form.Label>Alternative Contact</Form.Label>
                            <Form.Control type="tel" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control type="email" required />
                        </Form.Group>
                        {generic ? (
                            <Form.Group className="mb-3" controlId="category">
                                <Form.Label>Job Category *</Form.Label>
                                <Form.Control as="select" required>

                                    <option>Java Developer</option>
                                    <option>Python Developer</option>
                                    <option>AWS Engineer</option>
                                    <option>Cloud Engineer</option>
                                    <option>Frontend Developer</option>
                                    <option>Database Admin</option>
                                    <option>AI Developer</option>
                                    <option>Bussiness Analyst</option>
                                    <option>HR Admin</option>
                                    <option>QA Engineer</option>

                                </Form.Control>
                            </Form.Group>
                        ) : (
                            <Form.Group className="mb-3" controlId="position">
                                <Form.Label>Position *</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={props.jobTitle} 
                                    disabled
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3" controlId="resume">
                            <Form.Label>Resume *</Form.Label>
                            <Form.Control type="file" accept=".pdf,.doc,.docx" required />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="job-card-main">
                        <button
                            className="know-more-btn-job"
                            onClick={props.onHide}
                            aria-label={generic ? 'Submit Resume' : 'Submit Application'}

                        >
                            {generic ? 'Submit Resume' : 'Submit Application'}
                        </button>
                        <button
                            className="apply-btn"
                            onClick={props.onHide}
                            aria-label="Cancel Application"
                        >
                            Cancel
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ApplyModal;
