import React from "react";
import CommonServiceBanner from "../../common-component/common-service-banner/CommonServiceBanner.js";
import OurClients from "../../homepage/our-clients/OurClients.js";
import Testimonials from "../../homepage/testimonials/Testimonials.js";
import DataFeature from "./app-devlopement-feature/AppDevlopementFeature.js";
import DataMigrationFeature from "./app-devlopement-feature/AppDevlopementFeature.js";
import GetQuote from "../../common-component/get-quote/GetQuote.js";
import AppDevlopementFeature from "./app-devlopement-feature/AppDevlopementFeature.js";
import CommonHeader from "../../common-component/common-header/CommonHeader.js";
import AppDevlopementData from "./app-devlopement-data/AppDevlopementData.js";

const AppDevlopement = () => {
  return (
    <>
      <CommonServiceBanner
        imageSrc={"/assets/images/servicesPage/appdevlopemnt.png"}
        subheading={""}
        title={"Web And Mobile "}
        titleSecond={"App Development"}
        description={
          "Web and mobile app development involves creating software applications tailored for online and device use. it encompasses design, coding, testing and deployment to deliver functional and user-friendly digital solutions. you can expect top-notch development services that not only meet but exceed industry standards. We collaborate closely with you throughout the development process to ensure your vision is brought to life exactly as you imagine."
        }
        quoteButtonLabel={"Contact Us"}
        downloadButtonLabel={"Download Brouchure"}
      />

      <AppDevlopementData />
      <AppDevlopementFeature />

      {/* <CommonHeader /> */}
      <OurClients />


      <Testimonials />
      <GetQuote
        title="Transform your ideas into reality today!"
        subtitle="Appropriate for your specific business, making it easy for you to have quality IT services."
      />
    </>
  );
};

export default AppDevlopement;
