import { Button, Col, Row } from "react-bootstrap";
import { useState } from "react";
import "./ProductLoginPage.css";
import { LoginProductData } from "./LoginProductData.js";

export const ProductLoginPage = () => {
  const loginProductData = [
    {
      heading: 'Welcome to Our Product',
      subHeading: 'Please fill in the details below to get started.',
      fields: [
        {
          id: 'firstName',
          label: 'First Name *',
          type: 'text',
          placeholder: 'Enter your first name',
          rowSpan: 6,
          groupRow: true,
          required: true,
        },
        {
          id: 'lastName',
          label: 'Last Name *',
          type: 'text',
          placeholder: 'Enter your last name',
          rowSpan: 6,
          groupRow: true,
          required: true,
        },
        {
          id: 'email',
          label: 'Email address *',
          type: 'email',
          rowSpan: 12,
          required: true,
          placeholder: 'name@example.com',
        },
        {
          id: 'companyName',
          label: 'Company Name',
          type: 'text',
          rowSpan: 12,
          placeholder: 'Enter your company',
          helperText: 'Must be at least 8 characters.',
        },
        {
          id: 'phone',
          label: 'Phone',
          type: 'phone',
          rowSpan: 12,
          placeholder: '+1 (555) 000-0000',
          countryOptions: [
            { value: 'US', label: 'US' },
            { value: 'CA', label: 'CA' },
            { value: 'GB', label: 'GB' },
            { value: 'AU', label: 'AU' },
          ],
          helperText: 'Please enter your phone number.',
        },
        {
          id: 'privacyCheckbox',
          privacyCheckbox: true,
          privacyCheckboxText: 'I accept the privacy statement *',
          required: true,
        },
      ],
      buttonLabel: 'Submit',
      onSubmit: (e) => {
        e.preventDefault();
  
        const formData = {};
        let isValid = true;
        let errorMessage = '';
  
        // Helper function to validate phone number
        const validatePhoneNumber = (phoneNumber) => {
          const phonePattern = /^[+0-9\s\(\)-]+$/; // Allows numbers, spaces, parentheses, and dashes
          return phonePattern.test(phoneNumber);
        };
  
        // Loop through each field and collect the data
        loginProductData[0].fields.forEach((field) => {
          const fieldElement = document.getElementById(field.id);
  
          if (fieldElement) {
            if (field.id === 'phone') {
              const countryElement = document.querySelector(`#${field.id}SelectCountry`);
              formData[field.id] = {
                country: countryElement ? countryElement.value : '',
                number: fieldElement.value,
              };
  
              // Validate phone number
              if (!validatePhoneNumber(fieldElement.value)) {
                isValid = false;
                errorMessage = 'Please enter a valid phone number.';
              }
            } else if (field.id === 'companyName') {
              // Validate Company Name (at least 8 characters)
              if (fieldElement.value.length < 8) {
                isValid = false;
                errorMessage = 'Company Name must be at least 8 characters long.';
              }
              formData[field.id] = fieldElement.value;
            } else {
              formData[field.id] = fieldElement.value;
            }
          }
        });
  
        // Handle privacy checkbox separately
        const privacyCheckbox = document.getElementById('privacyCheckbox');
        if (privacyCheckbox) {
          formData['privacyCheckbox'] = privacyCheckbox.checked;
        }
  
        // Check if required fields (firstName, lastName, email) are provided
        if (!formData.firstName || !formData.lastName || !formData.email) {
          isValid = false;
          errorMessage = 'Please fill out all required fields.';
        }
  
        // Check if privacy checkbox is checked
        if (!formData.privacyCheckbox) {
          isValid = false;
          errorMessage = 'You must accept the Privacy Statement.';
        }
  
        // Handle form validation and submission
        if (isValid) {
          console.log('Form data submitted:', formData);
          alert('Form Submitted Successfully!');
        } else {
          alert(errorMessage); // Show the error message if validation fails
        }
      },
  
      imageTitle: 'Advanced Data Migration Tool',
      imageSubTitle:
        'Optimus Prime is a state-of-the-art Database Migration Tool, designed to facilitate seamless data transition from one database to another.',
      imageUrl: '/assets/images/landingPage/r-prime-carousel.jpeg',
    },
  ];
  
  

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phone: '',
    privacyCheckbox: false
  });

  const handleInputChange = (e) => {
    const { id, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const isSubmitDisabled = () => {
    return !(formData.firstName && formData.lastName && formData.email && formData.privacyCheckbox);
  };

  return (
    <Row className="no-gutters main-product-login">
      <Col
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        className="d-flex flex-column justify-content-center col-in-form"
      >
        <div className="logo-container">
          <img
            src={`/assets/images/logo/logo.png`}
            className="logo-img"
            alt="logo"
          />
        </div>
        <section className="login-from-image-data-container">
          <main className="login-form-image-container">
            <img
              src={loginProductData[0].imageUrl} 
              className="login-form-image"
              alt="login-form"
            />
          </main>
          <div className="common-text-login-form text-center">
            <h2 className="form-login-left-head">
              {loginProductData[0].imageTitle}
            </h2>
            <p className="form-login-left-para">
              {loginProductData[0].imageSubTitle}
            </p>
          </div>
          <section className="login-form-product-footer">
            <p className="login-form-product-footer-text">
              © RedBeryl Tech Solutions 2024
            </p>
            <p className="login-form-product-footer-text">
              <a
                href="mailto:help@redberyltech.com"
                target="_blank"
                rel="noreferrer"
                className="login-form-product-footer-text"
              >
                help@redberyltech.com
              </a>
            </p>
          </section>
        </section>
      </Col>

      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <LoginProductData 
          loginProductData={loginProductData} 
          formData={formData} 
          onInputChange={handleInputChange}
        />
        {/* <Button
          variant="primary" 
          type="submit" 
          onClick={loginProductData[0].onSubmit} 
          className="login-form-submit-btn"
          disabled={isSubmitDisabled()}
        >
          {loginProductData[0].buttonLabel}
        </Button> */}
      </Col>
    </Row>
  );
};
