import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';

const tabData = [
    {
        key: "first",
        heading: "Access top-tier candidates tailored to your needs.",
        subHeading: "Redberyl's R-Recruit ai delivers prescreened talent for your unique hiring goals.",
        title: "Top Candidates",
        description: "Discover RedBeryl’s ready, prescreened candidate pool powered by R-Recruit, our proprietary AI hiring tool. Seamlessly find top-tier talent tailored to your needs, saving time and ensuring the perfect fit for your team.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/talent-staffing/t1.png`,
    },
    {
        key: "second",
        heading: "Minimize hiring risks with our expert screening.",
        subHeading: "Redberyl's R-Recruit ai streamlines hiring with a ready, prescreened talent pool.",
        title: "On Contract",
        description: "Speed up your hiring process with RedBeryl’s expertly prescreened candidates. Powered by R-Recruit AI, our proprietary tool ensures you connect with top talent efficiently, reducing time-to-hire and enhancing recruitment success.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/talent-staffing/t2.png`,
    },
    {
        key: "third",
        heading: "From entry to executive roles, drive business growth.",
        subHeading: "Leverage Redberyl's expert HR team for seamless hiring across all organizational levels.",
        title: "Contract To Hire",
        description: "Fuel your business success with RedBeryl's HR specialists, experienced in recruiting for critical roles. From entry-level positions to executive leadership, we ensure the right talent to drive growth and achieve your goals.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/talent-staffing/t3.png`,
    },
    {
        key: "four",
        heading: "Find candidates aligned with your vision and culture.",
        subHeading: "Redberyl’s HR experts ensure talent fits your domain, goals, and organizational ethos.",
        title: "Trained Fresher",
        description: "Hire with confidence through RedBeryl’s expert HR team, adept at understanding your industry, vision, and culture. We deliver candidates who align seamlessly with your organizational goals and values, fostering lasting success.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/talent-staffing/t4.png`,
    },
    {
        key: "five",
        heading: "A tailored approach.",
        subHeading: "Redberyl offers tailored recruitment strategies to meet your specific talent requirements.",
        title: "Freelancer",
        description: "We don't believe in a one-size-fits-all approach. Our team works closely with you to understand your specific hiring needs and develop a customized staffing solution that meets your unique requirements.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/talent-staffing/t5.png`,
    },
];





const TalentStaffingData = () => {
    return (
        <section className="common-tab">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default TalentStaffingData