import React from 'react';
import './../../cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const AutomationDevopsFeatures = () => {
 
 const automationfeature = [
    {
        icon: "/assets/images/servicesPage/feature-image/16.svg",
        title: "End-to-End Automation Testing",
        description:
            "We specialize in comprehensive automation testing, swiftly validating applications for enhanced quality and minimized manual effort.",
    },

    {
        icon: "/assets/images/servicesPage/feature-image/17.svg",
        title: "CI/CD Pipeline Development",
        description:
            "Discover the advantages of CI/CD with our tailored pipeline development services, automating code changes for faster releases and reduced deployment risks.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/18.svg",
        title: "DevOps Integration",
        description:
            "Embrace DevOps power with our seamless integration, bridging development and operations to enhance collaboration and automate workflows, ultimately improving time to market.",
    },

];

    return (
        <section className="feature-main">
            <Container>

            <CommonHeader title="Why Choose RedBeryl!" subtitle="The core values that drive everything we do " highlightSubtitleIndex={2} />
                <Row>
                    {automationfeature.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default AutomationDevopsFeatures;
