import React, { useEffect } from 'react';
import './AboutFive.css';
import { Col, Container, Row } from 'react-bootstrap';
import CommonHeader from '../../common-component/common-header/CommonHeader';
import AOS from 'aos';
import 'aos/dist/aos.css';

const data = [
    {
        image: '/assets/images/landingPage/spectrum/Domain.svg',
        title: 'Domains',
        description: 'Expertise in industries like Finance, Retail, Healthcare, IoT, AI/ML, and Blockchain, driving tailored solutions.',
    },
    {
        image: '/assets/images/landingPage/spectrum/Geography.svg',
        title: 'Geographies',
        description: 'Global reach with operations in the USA, India, Singapore, and UAE, offering localized insights.',
    },
    {
        image: '/assets/images/landingPage/spectrum/Functions.svg',
        title: 'Functions',
        description: 'Focus on Cloud Migrations, Data Engineering, Software Modernization, and efficient Recruitment strategies.',
    },
    {
        image: '/assets/images/landingPage/spectrum/Emerging.svg',
        title: 'Emerging Capabilities',
        description: 'Leveraging Generative AI, ESG compliance and next-generation technologies for future-ready solutions.',
    },
];

const AboutFive = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <section className='about-main delivery-spectrum'>
            <CommonHeader title="Delivery Spectrum" subtitle='Tech-driven growth for every business around the globe' highlightSubtitleIndex={4} />
            <Container>
                <Row data-aos="fade-up"
                    data-aos-duration="3000">
                    {data.map((item, index) => (
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} key={index}>
                            <div className='main-div-delivery-spectrum'>
                                <img
                                    src={process.env.PUBLIC_URL + item.image}
                                    className="service-img"
                                    alt={item.title}
                                />
                                <div className='text-center mt-4'>
                                    <h4 className='blue-text'>{item.title}</h4>
                                    <p className='blk-normal-text-about'>{item.description}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default AboutFive;
