import { CommonBanner } from "../common-component/common-banner/CommonBanner";
import CommonHeader from "../common-component/common-header/CommonHeader";
import Gallery from "./Gallery";

const GalleryContainer = ()=>{
    return(
        <>
        <CommonBanner pageTitle="Gallery"  bg="/assets/images/careerPage/bannerCarrer.png"/>

        <CommonHeader title="Team Members" subtitle="Passionate personalities, versatile brains" highlightSubtitleIndex={2}/>
        
        <Gallery />

        </>
    )

}
export default GalleryContainer
