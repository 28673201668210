import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CommonImage from '../../../common-component/common-image/CommonImage'
import './WhatMatters.css'
import GetQuoteLinearButton from '../../../common-component/get-quote-linear-btn/GetQuoteLinearButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import FirstJobSection from '../first-job-section/FirstJobSection'
import ApplyModal from '../job-card/job-card-modal/ApplyModal'

const WhatMatters = () => {

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
      setShowModal(true);
  };

  const handleHideModal = () => {
      setShowModal(false);
  };

  return (
    <section className='what-matters'>
      <Container>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <FirstJobSection />

            <GetQuoteLinearButton
              label="Upload Resume"
              icon={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={handleShowModal} // Open the modal when button is clicked
              />
  
              <ApplyModal
                  show={showModal} 
                  onHide={handleHideModal} 
                  generic={true} 
              />


            {/* 
                        <Link to="/contact">
  <GetQuoteLinearButton 
    label="Submit Profile"
    icon={<FontAwesomeIcon icon={faArrowRight} />}
  />
</Link>
 */}
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <CommonImage imgsrc='/assets/images/careerPage/growth.jpeg' />
          </Col>
        </Row>

      </Container>
    </section>
  )
}

export default WhatMatters