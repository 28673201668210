import React from 'react';

import '../../../services-page/cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const OptimusPrimeFeature = () => {

    const features =[
      {
        icon: "/assets/images/servicePage/imageGrid/UserInterface1.svg",
        title: "Intuitive User Interface",
        description: "A user-friendly interface, streamlining the intricate database migration process."
      },
      {
        icon: "/assets/images/servicePage/imageGrid/cross_1.svg",
        title: "Multi-Platform Compatibility",
        description: "Compatible with multiple and cross platforms like in-prim to cloud, cloud to cloud or cloud to in-prim."
      },
      {
        icon: "/assets/images/servicePage/imageGrid/loading_1.svg",
    
        title: "Supports One Time Load\nAs Well As CDC",
        description: "One time data migration as well as delta/CDC migration using industry proven tools."
      },
      {
        icon: "/assets/images/servicePage/imageGrid/work-schedule_1.svg",
        title: "Automated Scheduling\nand Monitoring",
        description: "Automated scheduling and monitoring utilize technology to streamline operations, enhance efficiency, and facilitate data-driven decision-making."
      },
      {
        icon: "/assets/images/servicePage/imageGrid/workflow_1.svg",
        title: "Efficient Data Mapping",
        description: "Combination of predefined and customized data type mappings between source and target ensures precise and accurate transition."
      },
      {
        icon: "/assets/images/servicePage/imageGrid/data_1.svg",
    
        title: "Data Validation\nand Error Handling",
        description: "Gives you confidence in the integrity of your migrated data. Identify and address any issues encountered during the migration."
      },
      {
        icon: "/assets/images/servicePage/imageGrid/dashboard_1.svg",
    
        title: "Comprehensive Reports",
        description: "Get detailed reports on overall migration progress & estimated time remaining, as well as comprehensive validation reports at table, row, and cell levels."
      },
      {
        icon: "/assets/images/servicePage/imageGrid/email-marketing_1.svg",
    
        title: "Automated Notifications\nand Alerting",
        description: "Send automated email notification on every stage of the migration to keep business users updated with the progress."
      },
      {
        icon: "/assets/images/servicePage/imageGrid/data-security_1.svg",
    
        title: "Robust Security Measures",
        description: "Tool prioritize data security and privacy. Optimus Prime employs robust encryption & authentication protocols to safeguard sensitive information during the migration process."
      }
    ];

    return (
        <section className="feature-main">
            <Container>
                <CommonHeader highlightSubtitleIndex={6} title='Why Choose Optimus Prime?' subtitle='Optimus Prime effectively tackles the additional
 challenges those needs to be addressed' />

                <Row>
                    {features.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default OptimusPrimeFeature;
