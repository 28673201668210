import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, speed,setIsActive }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIsActive(true)
    if (index < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(displayedText + text[index]);
        setIndex(index + 1);
      }, speed);
      return () => {
        
        clearTimeout(timeoutId)
       
      };
      
    }
    else{
      setIsActive(false)
    }
  }, [index, text, speed, displayedText]);

  // console.log(speed)
  

  return <div>{displayedText}</div>;
};

export default Typewriter;