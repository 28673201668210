import { CommonBanner } from "../common-component/common-banner/CommonBanner"
import GetQuote from "../common-component/get-quote/GetQuote"
import { BlogGrid } from "./components/BlogGrid"

const BlogPage = () => {
return(
    <>
    <CommonBanner pageTitle="Blogs" bg="/assets/images/blogPage/blog-banner.png" />
    <BlogGrid/>
    <GetQuote title="Looking for the Best IT Business Solutions?" subtitle="Appropriate for your specific business, making it easy for you to have quality IT services." />
    </>
)
}
export default BlogPage