import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';
const tabData = [
    {
        key: "first",

        title: "AI/ML Adoption",
        heading: "Boost productivity with AI/ML",
        subHeading: "Unlocking efficiency and innovation",
        description: "By integrating AI and Machine Learning into your software, you can automate repetitive tasks, gain valuable insights from data, and make better decisions. This leads to increased productivity and a competitive edge in the market.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/softwaremoderization/s1.png`,
        },
    {
        key: "second",

        title: "Voice Based Solutions",
        heading: "Enhance user convenience with Alexa",
        subHeading: "Voice-enabled interactions for a seamless experience",
        description: "Enable your users to interact with your software using voice commands through Alexa integration. This creates a more intuitive and convenient user experience, making it easier for users to access information and perform actions.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/softwaremoderization/s2.png`,  },
    {
        key: "third",
        title: "Innovation At It's Best",
        heading: "Stay ahead with innovative solutions",
        subHeading: "Embracing the future of technology",
        description: "Our advanced software solutions leverage the latest technologies, such as AI, ML, IoT, and microservices, to help you stay ahead of the curve. We continuously innovate to provide you with cutting-edge solutions that meet your evolving business needs.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/softwaremoderization/s3.png`,   },
    {
        key: "four",
        title: "Scalable Solutions",
        heading: "Scale effortlessly with microservices",
        subHeading: "Agility and flexibility for your business",
        description: "Microservices architecture allows you to build and deploy software components independently, making it easier to scale your applications as your business grows. This flexibility and agility are crucial for adapting to changing market demands.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/softwaremoderization/s4.png`,  },
    {
        key: "five",
        title: "Cost Effective Solutions",
        heading: "Optimize performance for cost savings",
        subHeading: "Efficiency and value",
        description: "By optimizing your software performance, you can reduce resource consumption and energy costs. Our solutions are designed to deliver high performance while minimizing resource usage, resulting in significant cost savings for your business.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/softwaremoderization/s5.png`,
    }
];




const SoftwareModerizationData = () => {
    return (
        <section className="common-tab">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default SoftwareModerizationData