import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';
import CommonHeader from '../../../common-component/common-header/CommonHeader';


const tabData = [
    {
        key: "first",
        heading: "Streamline talent acquisition",
        subHeading: "AI-Driven Recruitment Efficiency",
        title: "ATS Solution Powered with AI",
        description: "Enhance hiring efficiency with AI-driven applicant tracking. Automate resume parsing, skill matching, and candidate shortlisting for faster decision-making.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/hrms/h1.png`,
    },
    {
        key: "second",
        heading: "Effortless vendor integration",
        subHeading: "Streamlined partner collaboration",
        title: "Vendor Onboarding",
        description: "Simplify vendor management with structured onboarding workflows. Ensure compliance, verify documentation, and streamline collaboration processes.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/hrms/h2.png`,
    },
    {
        key: "third",
        heading: "Seamless new hire integration",
        subHeading: "Simplify first steps for success",
        title: "Candidate Onboarding",
        description: "Digitize the entire onboarding journey for candidates. Enable quick access to documentation, training modules, and team introductions.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/hrms/h3.png`,
    },
    {
        key: "fourth",
        heading: "Accurate and automated pay",
        subHeading: "Simplify salary processing",
        title: "Payroll Management",
        description: "Automate payroll processing with precision. Manage attendance, deductions, and salaries while ensuring compliance with tax regulations.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/hrms/h4.png`,
    },
    {
        key: "fifth",
        heading: "Complete recruitment cycle",
        subHeading: "All-in-One hiring solution",
        title: "End-to-End Hiring",
        description: "Manage every step of hiring, from sourcing and screening to onboarding and performance tracking, all in one unified platform.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/hrms/h5.png`,
    },
];



const HrmsData = () => {
    return (
        <section className="common-tab">
            <Container>
            <CommonHeader title='All-in-One R-Recruit & Staffing Experience' subtitle='Unlock seamless hiring, candidate management, and HR solutions' highlightSubtitleIndex={1}/>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default HrmsData