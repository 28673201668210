import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner.js'
import OurClients from '../../homepage/our-clients/OurClients.js'
import Testimonials from '../../homepage/testimonials/Testimonials.js'
import DataFeature from './software-moderization-feature/SoftwareModerizationFeature.js'
import DataMigrationFeature from './software-moderization-feature/SoftwareModerizationFeature.js'
import GetQuote from '../../common-component/get-quote/GetQuote.js'
import TalentStaffingFeature from './software-moderization-feature/SoftwareModerizationFeature.js'
import SoftwareModerizationFeature from './software-moderization-feature/SoftwareModerizationFeature.js'
import CommonHeader from '../../common-component/common-header/CommonHeader.js'
import SoftwareModerizationData from './software-moderization-data/SoftwareModerizationData.js'

const SoftwareModerization = () => {
  return (
    <>
      <CommonServiceBanner
        imageSrc={'/assets/images/servicesPage/softwaremoderization.png'}
        subheading={''}
        title={'Software Moderization'}
        titleSecond={''}
        description={'Revitalize software through AI, ML, IoT integration. Alexa voice interaction and microservices architecture. Enhance functionality, efficiency and user experience for seamless modernization and integration. Stay ahead in the digital era with our advanced software solutions tailored to your evolving needs.'}
        quoteButtonLabel={'Contact Us'}
        downloadButtonLabel={'Download Brouchure'}
      />

      <SoftwareModerizationData/>

<SoftwareModerizationFeature/>
{/* <CommonHeader/> */}
<OurClients />


      <Testimonials />
      <GetQuote title="Upgrade your software now!"  subtitle="Appropriate for your specific business, making it easy for you to have quality IT services."/>
    </>
  )
}

export default SoftwareModerization