import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../common-component/common-header/CommonHeader';


const AboutFeature = () => {
    const features = [
        {
            icon: '/assets/images/aboutUs/accessibilty.svg',
            title: 'Accessibility',
            description:
                'Get expert help with cloud technology. We customize strategies to match your goals, guiding you through cloud adoption and migration decisions. Whether public, private, or hybrid cloud, we’re here for you.',
        },
        {
            icon: '/assets/images/aboutUs/teamWork.svg',
            title: 'Team work',
            description:
                'Collaboration drives our success, as we work together to achieve shared goals and deliver exceptional outcomes for our clients.',
        },
        {
            icon: '/assets/images/aboutUs/excellence.svg',
            title: 'Excellence',
            description:
                'Get expert help with cloud technology. We customize strategies to match your goals, guiding you through cloud adoption and migration decisions. Whether public, private, or hybrid cloud, we’re here for you.',
        },
        {
            icon: '/assets/images/aboutUs/innovation.svg',
            title: 'Innovation',
            description:
                'At the heart of our vision is a passion for innovation, transforming ideas into impactful solutions that shape the future.',
        },
        {
            icon: '/assets/images/aboutUs/empowerment.svg',
            title: 'Empowerment',
            description:
                'We foster an environment where individuals are encouraged to take ownership, grow, and make meaningful contributions.',
        },
        {
            icon: '/assets/images/aboutUs/resp.svg',
            title: 'Responsibility',
            description:
                'Our commitment to accountability ensures that we act with integrity, meeting the expectations of our stakeholders and society.',
        },
    ];

    return (
        <section className="feature-main">
            <CommonHeader  title="Why Choose RedBeryl !" subtitle='The core values that drive everything we do' highlightSubtitleIndex={2}/>
            <Container>
                <Row>
                    {features.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default AboutFeature;
