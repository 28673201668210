import React from 'react';

import '../../../services-page/cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const ProductFeature = () => {

    const features = [
        {
          icon: "/assets/images/productsPage/imageGrid/discovery.svg",
          title: "Discovery",
          description:
            "Vague ideas won't suffice. RedBeryl collaborates with businesses to define problem statements and customer needs. Our developers clarify project scope, documenting requirements and feasibility in a concise SRS.",
        },
      
        {
          icon: "/assets/images/productsPage/imageGrid/poc.svg",
          title: "POC",
          description:
            "Our expertly crafted Proof of Concept analyzes feasibility, uncovers unexpected issues, and demonstrates product intent to stakeholders. RedBeryl’s POC services clarify product idea capabilities for enterprises.",
        },
        {
          icon: "/assets/images/productsPage/imageGrid/designMap.svg",
      
          title: "Design Roadmap",
          description:
            "We visualize a creative process for phased research, delivery, and implementation by our design team. Our product design consultants launch and assess offerings for both established businesses and new ventures.",
        },
        {
          icon: "/assets/images/productsPage/imageGrid/mvp.svg",
          title: "MVP",
          description:
            "As an experienced MVP development company, we follow a defined SDLC, using strategic tactics for efficient MVP software development. This approach enables us to deliver effective solutions while minimizing resource use.",
        },
        {
          icon: "/assets/images/productsPage/imageGrid/productRoadmap.svg",
          title: "Product Roadmap",
          description:
            "RedBeryl creates functional product roadmaps to guide clients from concept to market using agile methodologies. Our resourceful action plans align short-term efforts with long-term product evolution and business objectives.",
        },
        {
          icon: "/assets/images/productsPage/imageGrid/endd.svg",
      
          title: "End-to-End Development",
          description:
            "We provide comprehensive end-to-end product development services with enterprise-grade expertise for mid-sized and established enterprises. Integrating cognitive technologies like AI, ML, and blockchain.",
        }
      ];
          

    return (
        <section className="feature-main">
            <Container>
                <CommonHeader highlightSubtitleIndex={7} title='Our Process' subtitle='Exceptional product development services to deliver outstanding results'/>

                <Row>
                    {features.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default ProductFeature;
