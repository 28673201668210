import React from 'react';
import './CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const CloudFeature = () => {
    const features = [
        {
            icon: '/assets/images/servicesPage/feature-image/1.svg',
            title: 'Cloud Strategy Consultation',
            description:
                'Get expert help with cloud technology. We customize strategies to match your goals, guiding you through cloud adoption and migration decisions. Whether public, private, or hybrid cloud, we’re here for you.',
        },
        {
            icon: '/assets/images/servicesPage/feature-image/2.svg',
            title: 'Cloud Migration Services',
            description:
                'Get expert help with cloud technology. We customize strategies to match your goals, guiding you through cloud adoption and migration decisions. Whether public, private, or hybrid cloud, we’re here for you.',
        },
        {
            icon: '/assets/images/servicesPage/feature-image/3.svg',
            title: 'Disaster Recovery',
            description:
                'Get expert help with cloud technology. We customize strategies to match your goals, guiding you through cloud adoption and migration decisions. Whether public, private, or hybrid cloud, we’re here for you.',
        },
    ];

    return (
        <section className="feature-main">
            <Container>
                {/* service Header */}

                <CommonHeader title="Why Choose RedBeryl!" subtitle="The core values that drive everything we do " highlightSubtitleIndex={2} />
                <Row>
                    {features.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default CloudFeature;
