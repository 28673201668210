import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner.js'
import OurClients from '../../homepage/our-clients/OurClients.js'
import Testimonials from '../../homepage/testimonials/Testimonials.js'
import DataFeature from './automation-devops-feature/AutomationDevopsFeature.js'
import DataMigrationFeature from './automation-devops-feature/AutomationDevopsFeature.js'
import GetQuote from '../../common-component/get-quote/GetQuote.js'
import AppDevlopementFeature from './automation-devops-feature/AutomationDevopsFeature.js'
import AutomationDevopsFeatures from './automation-devops-feature/AutomationDevopsFeature.js'
import CommonHeader from '../../common-component/common-header/CommonHeader.js'
import AutomationDevopsData from './automation-devops-data/AutomationDevopsData.js'

const AutomationDevops = () => {
  return (
    <>
      <CommonServiceBanner
        imageSrc={'/assets/images/servicesPage/automation.png'}
        subheading={''}
        title={'Automation, CI/CD'}
        titleSecond={'& DevOps'}
        description={'Utilizing software tools for test automation streamlines testing, enhances efficiency, and ensures consistent and reliable validation of software applications, improving overall test coverage. Our tailored automation solutions accelerate testing speed and enable proactive issue identification and resolution, safeguarding user experience.'}
        quoteButtonLabel={'Contact Us'}
        downloadButtonLabel={'Download Brouchure'}
      />


<AutomationDevopsData/>
<AutomationDevopsFeatures/>
{/* <CommonHeader/> */}
<OurClients />
      
      <Testimonials />
      <GetQuote title="Start your development process today!"  subtitle="Appropriate for your specific business, making it easy for you to have quality IT services."/>
    </>
  )
}

export default AutomationDevops