import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Container } from "react-bootstrap";
import FeatureCard from "../../../common-component/feature-card/FeatureCard";

import './WhoWeAre.css';


export default function WhoWeAre() {
  
  const serviceCards = [
    {
      id: 1,
      image: "/assets/images/servicePage/cloud.svg",
      title: "Cloud Solutions",
      description: "Delivering tailored cloud solutions that meet your unique business needs, we leverage cutting-edge technology to propel your organization forward."
    },
    {
      id: 2,
      image: "/assets/images/servicePage/cloud2.svg",
        title: "Staffing Expert",
      description: "Our rigorous screening and diverse talent pool exceed expectations, driving tailored staffing solutions for your unique business needs."
    }
  ];
  return (

    <div className="about-container">
      <Row >
        {/* <Col xs={12} md={10} lg={12} > */}
          {/* Section Title */}
          <div className="mb-5">
            <h2 className="mb-3 text-custom-pink title">Who we are?</h2>
            <h3 className="text-custom-blue subtitle">
              Integrated solution provider, maximizing{" "}
              <span className="custom-pink">value</span> and{" "}
              <span className="text-custom-blue subtitle">talent</span>
            </h3>
          </div>

          {/* Description */}
          <div className="mb-5">
            <span className=" description">
              We provide the full spectrum of IT services and consulting for various industries.
            </span>
          </div>

          {/* Feature Cards */}
          <Row className="mb-5">
            {serviceCards.map((card) => (
              <Col  md={6} sm={6} xs={12} key={card.id} className="mb-4">
                <FeatureCard
                  icon={card.image}
                  title={card.title}
                  description={card.description}
                  showBorder={true}
                />
              </Col>
            ))}
          </Row>
      </Row>
    </div>

  );
}
