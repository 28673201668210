import React from 'react';
import './TabDetails.css';
import { Col, Row } from 'react-bootstrap';
import GetQuoteLinearButton from '../get-quote-linear-btn/GetQuoteLinearButton';

const TabDetails = ({
    subHeading,
    heading,
    description,
    buttonLabel,
    onButtonClick,
    dotImageSrc,
    mainImageSrc,
}) => {
    return (
        <section className='tab-details-main'>
            <Row>
                <Col xxl={6} xl={6} lg={6} md={6} xs={12} sm={12}>
                    <div>
                        <h4 className='tab-sub-heading'>{subHeading}</h4>
                        <h3 className='tab-heading'>{heading}</h3>
                        <p className='normal-text'>{description}</p>
                        <div className='main-class-btn-div mt-4'>
                            <GetQuoteLinearButton label={buttonLabel} onClick={onButtonClick} />
                        </div>
                    </div>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} xs={12} sm={12}>
                    <div className='main-second-tab'>
                        <img
                            src={dotImageSrc}
                            alt='Decorative dots'
                            className="dot-img"
                        />
                        <div className='white-bggg'>
                            <img
                                src={mainImageSrc}
                                alt='Main content'
                                className="tab-details-img"
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default TabDetails;
