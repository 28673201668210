import React from 'react';
import { Modal } from 'react-bootstrap';
import "./../../job-seeker/job-card/job-card-modal/CommonModal.css"
const KnowMoreModalRecruiter = ({ show, onHide, data }) => {
    return (
        <section>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className="common-main-modal"
            >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4 className="modal-headdd">{data.jobTitle}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="modal-head">Current Location</h5>
                    <p className='blk-grey-text'>{data.location}</p>

                    <h5 className="modal-head">Experience Required</h5>
                    <p className='blk-grey-text'>{data.experience} Years</p>

                    <h5 className="modal-head">Skills</h5>
                    <ul>
                        {data.skills.map((skill, index) => (
                            <li key={index} className="blk-grey-text">{skill}</li>
                        ))}
                    </ul>

                    <h5 className="modal-head">Certifications</h5>
                    <ul>
                        {/* {console.log(data.certifications.length>1)} */}
                        {data.certifications && data.certifications.length>0 ? data.certifications.map((e, index) => (
                            <li key={index} className="blk-grey-text">{e}</li>
                        )):""}
                        
                    </ul>

                    <h5 className='modal-head'>Notice Period</h5>
                    <p className='blk-grey-text'> {data.noticePeriod}</p>


                    

                    <h5 className='modal-head'>Current CTC</h5>
                    <p className='blk-grey-text'>{data.currentCTC}</p>


                    <h5 className='modal-head'>Expected CTC</h5>
                    <p className='blk-grey-text'>{data.expectedCTC}</p>

                    
                    <h5 className='modal-head'>Preffered Location</h5>
                    <p className='blk-grey-text'>{data.prefferedLoc}</p>
                    

                    <h6 className='modal-head'> Gender </h6>
                    <p className='blk-grey-text'>{data.gender}</p>

                    
                    <h5  className='modal-head'>Ready To Relocate</h5>
                    <p className='blk-grey-text'>{data.readyToRelocate}</p>


                    {/* Uncomment if needed */}
                    {/* <h5>Summary</h5>
                    <p>{data.summery}</p> */}

                    {/* <h5>Education</h5>
                    {data.education.length > 0 ? (
                        <ul>
                            {data.education.map((edu, index) => (
                                <li key={index}>
                                    <strong>{edu.degree}</strong> in {edu.field} from {edu.institution} (Graduation Year: {edu.graduationYear})
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No educational information available.</p>
                    )} */}

                    {/* <h5>Experience Details</h5>
                    {data.experience_details.length > 0 ? (
                        data.experience_details.map((experience, index) => (
                            <div key={index}>
                                <h6>{experience.title} at {experience.company}</h6>
                                <p><strong>Duration:</strong> {experience.duration}</p>
                                <p><strong>Location:</strong> {experience.location}</p>
                                <p><strong>Responsibilities:</strong></p>
                                <ul>
                                    {experience.responsibilities.map((resp, index) => (
                                        <li key={index}>{resp}</li>
                                    ))}
                                </ul>
                            </div>
                        ))
                    ) : (
                        <p>No experience details available.</p>
                    )} */}
                </Modal.Body>
                <Modal.Footer>
                    {/* Empty footer if needed */}
                    {/* <Button variant="secondary" onClick={onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default KnowMoreModalRecruiter;
