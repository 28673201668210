import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';

const tabData = [
    {
        key: "first",
        heading: "Streamline time-to-market with CI/CD.",
        subHeading: "Accelerate your release cycle with CI/CD",
        title: "Faster Development",
        description: "Cloud solutions allow you to easily adjust your computing resources based on your changing needs. Whether you need to handle a surge in demand or downsize during a lull, you can quickly scale your resources up or down, ensuring you only pay for what you use.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, 
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/automation/a1.png`,
    },
    {
        key: "second",
        heading: "Automate tasks, minimize errors, free resources.",
        subHeading: "Free up your team to focus on innovation",
        title: "Efficiency Boost",
        description: "Automating repetitive tasks, such as building, testing, and deploying software, frees up your development team to focus on more strategic work, such as innovation and problem-solving. Automation also minimizes human error, leading to more reliable and consistent results.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, 
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/automation/a2.png`,
    },
    {
        key: "third",
        heading: "Ensure reliable releases with automated testing.",
        subHeading: "Maintain high quality with automated testing",
        title: "Improved Quality",
        description: "Automated testing helps ensure the quality and reliability of your software by catching bugs and defects early in the development cycle. With comprehensive test coverage, you can deliver high-quality software that meets user expectations and minimizes the risk of costly errors.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/automation/a3.png`,
    },
    {
        key: "four",
        heading: "Break down silos and improve communication",
        subHeading: "Break down silos and improve communication",
        title: "Collaborative Efforts",
        description: "CI/CD and DevOps practices foster collaboration between development, operations, and testing teams. By working together closely, teams can identify and resolve issues more quickly, improve communication, and deliver software more efficiently.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/automation/a4.png`,
    },
    {
        key: "five",
        heading: "Adaptability for your growing needs",
        subHeading: "Scale effortlessly",
        title: "Resource Allocation",
        description: "Cloud-based infrastructure provides the flexibility to scale your resources up or down as needed. This scalability is essential for handling fluctuations in demand and ensuring that your software can support a growing user base.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`, // Example placeholder
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicesPage/service-tab/automation/a5.png`,
    },
];


const AutomationDevopsData = () => {
    return (
        <section className="common-tab">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default AutomationDevopsData