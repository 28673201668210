import React, { useState, useEffect } from 'react';
import './AboutSecondSubSection.css';

const AboutSecondSubSection = () => {
    const [visibleIndex, setVisibleIndex] = useState(0);

    const items = [
        {
            title: 'Product Development',
            description: 'Transforming your ideas into cutting-edge digital products tailored to your needs.',
        },
        {
            title: 'Cloud Migration',
            description: 'Seamlessly moving your infrastructure to the cloud for enhanced scalability and efficiency.',
        },
        {
            title: 'Staffing',
            description: 'Delivering skilled IT professionals to meet your project and organizational requirements.',
        },
        {
            title: 'Data Engineering',
            description: 'Building robust data pipelines and architectures for insightful business decision-making.',
        },
        {
            title: 'Data Migration',
            description: 'Ensuring secure and accurate transfer of critical data across platforms and systems.',
        },
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisibleIndex((prevIndex) => (prevIndex + 1) % items.length); // Loop back to the first item
        }, 4000);

        return () => clearTimeout(timer); // Cleanup timer
    }, [visibleIndex, items.length]);

    return (
        <section className='about-second-sub'>
            <img
                src={process.env.PUBLIC_URL + '/assets/images/aboutUs/aboutUsOurMission.png'}
                className="about-successful-img"
                alt="Our Mission"
            />
            <div className='main-white-second-sub'>
                <div className='white-main-bggggg'>
                    <div className='d-flex'>
                        <div className='me-3'>
                            <img
                                src={process.env.PUBLIC_URL + '/assets/images/aboutUs/aboutUsRib.svg'}
                                className="about-rib-img me-3"
                                alt="Ribbon"
                            />
                        </div>
                        <div>
                            {items.map((item, index) => (
                                <div
                                    key={index}
                                    className={`content-item ${
                                        index === visibleIndex
                                            ? 'fade-in'
                                            : index === (visibleIndex - 1 + items.length) % items.length
                                            ? 'fade-out'
                                            : 'hidden'
                                    }`}
                                >
                                    <h4 className='blue-text'>{item.title}</h4>
                                    <p className='blk-normal-text-abouttt'>{item.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSecondSubSection;
