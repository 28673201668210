import React from 'react'
import { CommonBanner } from '../../common-component/common-banner/CommonBanner'
import CareerFirst from './career-first-section/CareerFirst'
import RecruiterPanel from './recruiter-panel/RecruiterPanel'

const Recruiters = () => {
  return (
    <>
      <CommonBanner pageTitle={"Career"} bg={'/assets/images/careerPage/bannerCarrer.png'} />
      <CareerFirst />
      <RecruiterPanel />
    </>
  )
}

export default Recruiters