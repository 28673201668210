import React from 'react'
import GetQuote from '../common-component/get-quote/GetQuote'
import ContactLoaction from './contact-location/ContactLoaction'
import { CommonBanner } from '../common-component/common-banner/CommonBanner'
import './contact-address/ContactAddress.css'
import ContactForm from './contact-form/ContactForm'


const ContactUs = () => {
  return (
    <>
      <CommonBanner pageTitle={"Contact Us"} bg={'/assets/images/contactUs/bannerContactUs.png'} />
      <div className='main-contact'>
        <ContactForm/>
        <ContactLoaction />
        <GetQuote title={'Build your next big idea with us!'}
          subtitle={'Share your project details, and we’ll provide a tailored quote to kickstart your journey toward innovation.'}
        />
      </div>
    </>
  )
}

export default ContactUs