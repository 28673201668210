import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './HomeBanner.css';
import HomeBannerFirst from './HomeBannerFirst';
import HomeBannerSecond from './HomeBannerSecond';

const slidesData = [
  {
    subHeading: "Simplify huge and complex data transfer",
    heading: "Advanced Data Migration Tool",
    text: "Optimus Prime is a state-of-the-art Database Migration Tool, designed to facilitate seamless data transition from one database to another.",
    // text:"Optimus Prime accelerates data warehouse migrations with ease. This configurable tool helps users plan and estimate migration activities. Its plug-and-play testing module compares migrated data to source data in near real-time, generating clear reports. By identifying potential discrepancies in advance, it allows for streamlined validation and simplifies the sign-off process.",
    image: "r-prime-carousel.jpeg",
    link:"/products/optimus-prime",
    bulletPoints:[
      'Seamless data migration planning and estimation.',
      'Plug-and-play testing module for near real-time data comparison.',
      'Proactive exception management for cleaner validation reports.'
    ]
  },
  {
    subHeading: "Elevate your workforce with R-Recruit",
    heading: "HRM & Staffing Solutions",
    text: "R-Recruit streamlines recruitment, employee engagement, client management and data-driven decisions, enhancing HR practices and contributing to organizational success.",
    // text:"R-Recruit is a human resource software that integrates various HR processes and systems, ensuring seamless management and efficient functioning of business operations. Its primary aim is to enhance employee performance, helping organizations achieve their business goals. The user-centric design of R-Recruit reflects a commitment to customer care, significantly improving platform usability. With a focus on delivering an optimal user experience, R-Recruit empowers organizations to streamline HR practices and drive overall success.",
    image: "r-recruite-carousel.jpeg",
    link:"/products/r-recruit",
    bulletPoints:[
      'Streamlines recruitment and employee engagement processes.',
      'Enhances client management capabilities.',
      'Facilitates data-driven decision-making for improved HR practices.',
    ]
  },
  {
    subHeading: "A comprehensive platform that helps you",
    heading: "Launch Manage and Comply",
    text: "From entity formation to ongoing compliance, streamline every stage of business lifecycle with R-Filings. Our platform will help you through every step of the process in all 50 states",
    // text:"R-Filings offers a user-friendly platform that simplifies business compliance and operations, integrating seamlessly with CRM and accounting systems for efficient client management. Its intuitive interface and real-time status updates keep businesses informed about filing progress, while advanced analytics and automated compliance tracking ensure effortless management across the business lifecycle.",
    image: "r-filing-carousel.jpeg",
    link :"/products/r-fillings",
    bulletPoints:[
      'Streamlines entity formation and ongoing compliance.',
      'Supports every stage of the business lifecycle.',
      'Provides assistance across all 50 states.'
    ]
  },
  {
    subHeading: "Transform Your Business With",
    heading: "E-commerce Solutions",
    text:"R-Shopizer's ONDC integration revolutionizes retail, empowering businesses with expanded reach and exceptional digital shopping experiences.",
    // text: "R-Shopizer is revolutionizing the retail landscape by seamlessly integrating with ONDC, empowering retailers to thrive in the digital marketplace while enhancing the shopping experience for customers. With a commitment to quality and customer satisfaction, R-Shopizer aims to be the preferred online shopping destination, offering a diverse range of products and a tailored shopping journey. Experience the future of retail with R-Shopizer, where exceptional service and a user-friendly interface create unparalleled digital shopping experiences.",
    image: "r-shop-carousel.jpeg",
    link : "/products/r-shopizer",
    bulletPoints:[
      'Revolutionizes retail through ONDC integration.',
      'Empowers businesses with expanded market reach.',
      'Enhances digital shopping experiences for customers.'
    ]
  },
];

const HomeBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section className='home-banner'>
      <Container>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <HomeBannerFirst slidesData={slidesData} activeIndex={activeIndex} />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <HomeBannerSecond 
              slidesData={slidesData} 
              activeIndex={activeIndex} 
              setActiveIndex={setActiveIndex} 
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeBanner;
