import React from 'react'
import WhatMatters from './what-matters/WhatMatters'
import JobPanel from './job-card/JobPanel'
import { CommonBanner } from '../../common-component/common-banner/CommonBanner'


const Jobseeker = () => {
    return (
        <>
            <CommonBanner pageTitle={"Career"} bg={'/assets/images/careerPage/bannerCarrer.png'} />
            <WhatMatters />
            <JobPanel />
        </>
    )
}

export default Jobseeker