import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';
import CommonHeader from '../../../common-component/common-header/CommonHeader';
const tabData = [
    {
        key: "first",
        heading: "One platform, endless possibilities",
        subHeading: "Your all-in-one business companion",
        title: "Simplify operations effortlessly",
        description: "R-Shopizer combines everything your business needs into a single platform – from inventory and payment management to seamless customer engagement. With its robust tools and integrations, you can focus on growing your business without worrying about the backend complexities.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/rShopizer/tab-imgs/1.png`,
    },
    {
        key: "second",
        heading: "Scale beyond limits",
        subHeading: "Built to grow as you do",
        title: "Grow with your business",
        description: "Whether you’re a small business owner or managing a large enterprise, R-Shopizer’s scalable architecture ensures you can meet customer demands. Its flexibility allows you to expand your operations effortlessly, supporting everything from a single store to a nationwide network.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/rShopizer/tab-imgs/2.png`,
    },
    {
        key: "third",
        heading: "Mobile-first excellence",
        subHeading: "Stay in their pocket, always",
        title: "Engage customers on the go",
        description: "With dedicated Android and iOS applications, R-Shopizer ensures your business stays at your customers' fingertips. Provide a seamless shopping experience on mobile devices, enabling faster transactions and higher customer satisfaction.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/rShopizer/tab-imgs/3.png`,
    },
    {
        key: "fourth",
        heading: "Unlock ONDC potential",
        subHeading: "Fuel your growth with ONDC",
        title: "Connect with India’s marketplace",
        description: "By integrating with the Open Network for Digital Commerce (ONDC), R-Shopizer connects your business to a growing marketplace. Gain visibility, attract new customers, and boost sales through a network that’s transforming India’s retail ecosystem.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/rShopizer/tab-imgs/4.png`,
    },
];




const RShopizerData = () => {
    return (
        <section className="common-tab">
            <Container>
            <CommonHeader title='Unlock the Full Potential of Your Business' subtitle='Power-packed features to elevate your e-commerce game!' highlightSubtitleIndex={5}/>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default RShopizerData