import React from 'react'
import HomeBanner from './home-banner/HomeBanner'
import BuisnessNeeds from './buisness-needs/BuisnessNeeds'
import DevlopmentProcess from './devlopment-process/DevlopmentProcess'
import ItSolution from './it-solution/ItSolution'
import OurClients from './our-clients/OurClients'
import Testimonials from './testimonials/Testimonials'

const HomePage = () => {
  return (
    <>
      <HomeBanner />
      <BuisnessNeeds />
      <DevlopmentProcess />
      <ItSolution
        title="Preparing for your success by providing the best IT solutions."
        description="Appropriate for your specific business, making it easy for you to have quality IT services."
        buttonText="Learn More"
      
      />
      <OurClients />
      <Testimonials />

    </>
  )
}

export default HomePage