import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ContactLocation.css'
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const ContactLoaction = () => {
    // useEffect(() => {
    //     AOS.init();
    // }, [])
    return (
        <section className='contact-location'>
            <Container>
                <Row className='justify-content-center'>
                    <Col xxl={4} xl={5} lg={5} md={6} sm={6} xs={12}>
                        <div 
                        // data-aos="fade-right"
                        //     data-aos-duration="3000"
                             >
                            <img
                                src={process.env.PUBLIC_URL + '/assets/images/contactUs/offices-hadapsar-amanorachambers-hadapsar.jpg'}
                                className="contact-img"
                                alt='amanora-mall-img'

                            />
                        </div>
                    </Col>
                    <Col xxl={4} xl={5} lg={5} md={6} sm={6} xs={12}>
                        <div 
                        // data-aos="fade-left"
                        //     data-aos-duration="3000"
                             >
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/contactUs/Amanora.jpeg"}
                                className="contact-img"
                                alt='amanora-mall-img'
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='mt-4'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.0100604500553!2d73.93183346734418!3d18.518035898824532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c22241555591%3A0x55b4e980eb492d65!2sAmanora%20Chambers!5e0!3m2!1sen!2sin!4v1733632148865!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Map Embed"
                    className="w-full"
                ></iframe>
            </div>
        </section>
    )
}

export default ContactLoaction