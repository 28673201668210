import * as React from "react";
import { BlogCard } from "./BlogCard";
import { blogPosts } from "./BlogData";

// import Pagination from "../careerPage/Pagination";
import { Container, Row, Col } from "react-bootstrap"; 


export function BlogGrid() {
  const postsPerPage = 6;
  const [currentPage, setCurrentPage] = React.useState(1);

  const totalPages = Math.ceil(blogPosts.length / postsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className="blog-grid-background">
      <Container fluid className="py-5">
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={9} className="d-flex flex-column">
            {/* Blog Cards */}
            <Row className="g-4">
              {currentPosts.map((post) => (
                <Col key={post.id} xs={12} sm={6} md={4} lg={4}>
                  <BlogCard {...post} />
                </Col>
              ))}
            </Row>

            {/* Pagination */}
            {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
