import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner.js'
import OurClients from '../../homepage/our-clients/OurClients.js'
import Testimonials from '../../homepage/testimonials/Testimonials.js'
import DataFeature from './talent-staffing-feature/TalentStaffingFeature.js'
import DataMigrationFeature from './talent-staffing-feature/TalentStaffingFeature.js'
import GetQuote from '../../common-component/get-quote/GetQuote.js'
import TalentStaffingFeature from './talent-staffing-feature/TalentStaffingFeature.js'
import CommonHeader from '../../common-component/common-header/CommonHeader.js'
import ProductDevlopementData from '../product-devlopement/product-devlopement-data/ProductDevlopementData.js'
import TalentStaffingData from './talent-staffing-data/TalentStaffingData.js'

const TalentStaffing = () => {
  return (
    <>
      <CommonServiceBanner
        imageSrc={'/assets/images/servicesPage/talent-staffing.png'}
        subheading={''}
        title={'Talent Staffing'}
        titleSecond={''}
        description={'Our extensive network of talented individuals, coupled with our rigorous screening processes, ensures that you receive candidates who not only meet but exceed your expectations. From entry-level positions to executive roles, we provide staffing solutions that drive your business forward. We understand that every organization is unique, which is why our approach is tailored to match your company\'s culture and specific needs'}
        quoteButtonLabel={'Contact Us'}
        downloadButtonLabel={'Download Brouchure'}
      />

<TalentStaffingData/>

<TalentStaffingFeature/>
<OurClients />


      <Testimonials />
      <GetQuote title="Hire top talent today and make your team!"  subtitle="Appropriate for your specific business, making it easy for you to have quality IT services."/>
    </>
  )
}

export default TalentStaffing