import React from 'react';
import './../../cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const AppDevlopementFeature = () => {
 
    const appdevlopmentfeature = [
        {
            icon: "/assets/images/servicesPage/feature-image/13.svg",
            title: "Web Development",
            description:
                "Our web development covers modern tech for responsive sites, dynamic apps, and engaging e-commerce. Skilled developers ensure stunning design and flawless functionality.",
        },
    
        {
            icon: "/assets/images/servicesPage/feature-image/14.svg",
            title: "Mobile App Development",
            description:
                "Craft powerful mobile apps with our development services, spanning iOS, Android, and cross-platform solutions, delivering captivating experiences and exceptional performance.",
        },
        {
            icon: "/assets/images/servicesPage/feature-image/15.svg",
            title: "UI/UX Design",
            description:
                "Boost your digital presence with our UI/UX design services, creating intuitive, visually appealing interfaces that captivate and engage users effectively.",
        },
    
];

    return (
        <section className="feature-main">
            <Container>
            <CommonHeader title="Why Choose RedBeryl!" subtitle="The core values that drive everything we do " highlightSubtitleIndex={2} />
                <Row>
                    {appdevlopmentfeature.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default AppDevlopementFeature;
