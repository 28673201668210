import React from 'react';
import './CommonServiceBanner.css';
import GetQuoteLinearButton from '../get-quote-linear-btn/GetQuoteLinearButton';
import DownloadButton from '../download-btn/DownloadButton';

const CommonServiceBanner = ({
  imageSrc,
  subheading,
  title,
  titleSecond,
  description,
  quoteButtonLabel,
  downloadButtonLabel,
  onQuoteButtonClick,
  onDownloadButtonClick,
}) => {
  return (
    <section className="common-service-banner">
      <div className="main-class-banner">
        <img
          src={imageSrc}
          alt="Banner"
          className="banner-img"
        />
        <div className="main-class-banner-text-holder">
          <h5 className="banner-subheading">{subheading}</h5>
          <h3 className="banner-title">{title}</h3>
          <h3 className="banner-title-second">{titleSecond}</h3>
          <p className="banner-description">{description}</p>
          <div className="d-flex mt-4">
            {quoteButtonLabel && (
              <GetQuoteLinearButton to="/contact-us" label={quoteButtonLabel} onClick={onQuoteButtonClick} />
            )}
            {downloadButtonLabel && (
              <DownloadButton buttonname={downloadButtonLabel} onClick={onDownloadButtonClick} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonServiceBanner;
