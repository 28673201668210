import React from 'react';
import './../../cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const OdcEstablishmentFeature = () => {
    const establishmentfeature = [
        {
            icon: "/assets/images/servicesPage/feature-image/22.svg",
            title: "Customized ODC Setup",
            description:
                "We specialize in creating bespoke Offshore Development Centers tailored to your needs, whether you're a startup seeking a dedicated team or an enterprise looking to scale operations.",
        },
    
        {
            icon: "/assets/images/servicesPage/feature-image/23.svg",
            title: "Project Management and Reporting",
            description:
                "Streamline project management with our ODC services, offering robust tracking, progress updates, and detailed reporting for informed decision-making on project status and milestones.",
        },
        {
            icon: "/assets/images/servicesPage/feature-image/24.svg",
            title: "Talent Acquisition and Onboarding",
            description:
                "Access skilled professionals tailored to your needs, with our recruitment process and seamless onboarding integrating them into your workflows and culture.",
        },
    
    ];
    
    
    
    
    

    return (
        <section className="feature-main">
            <Container>
            <CommonHeader title="Why Choose RedBeryl!" subtitle="The core values that drive everything we do " highlightSubtitleIndex={2} />
                <Row>
                    {establishmentfeature.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default OdcEstablishmentFeature;
