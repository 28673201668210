
export const galleryimages = [
  {
    id: 1,
    src: '/assets/images/gallery/diwali/0.jpeg',
    alt: 'Gallery User 0',
    title:"Festivals",
    subimages: [
      { src: '/assets/images/gallery/diwali/0.jpeg', alt: 'Gallery User 0' },

      { src: '/assets/images/gallery/diwali/1.jpeg', alt: 'Gallery User 1' },
      { src: '/assets/images/gallery/diwali/2.jpeg', alt: 'Gallery User 2' },
      { src: '/assets/images/gallery/diwali/3.jpeg', alt: 'Gallery User 3' },
      { src: '/assets/images/gallery/diwali/4.jpeg', alt: 'Gallery User 4' },
      // { src: '/assets/images/gallery/diwali/5.jpeg', alt: 'Gallery User 5' },
    ],
  },
  {
    id: 2,
    src: '/assets/images/gallery/team/4.jpg',
    alt: 'Gallery User 4',
    title:"Team",

    subimages: [
      { src: '/assets/images/gallery/team/4.jpg', alt: 'Gallery User 4' },
      { src: '/assets/images/gallery/team/1.jpg', alt: 'Gallery User 0' },
      { src: '/assets/images/gallery/team/2.jpg', alt: 'Gallery User 2' },
      { src: '/assets/images/gallery/team/3.jpg', alt: 'Gallery User 3' },
      { src: '/assets/images/gallery/team/5.jpg', alt: 'Gallery User 5' },
      { src: '/assets/images/gallery/team/6.jpg', alt: 'Gallery User 6' },
      { src: '/assets/images/gallery/team/7.jpg', alt: 'Gallery User 7' },
      { src: '/assets/images/gallery/team/8.jpg', alt: 'Gallery User 8' },
      { src: '/assets/images/gallery/team/9.jpg', alt: 'Gallery User 9' },
      { src: '/assets/images/gallery/team/10.jpg', alt: 'Gallery User 10' },
      { src: '/assets/images/gallery/team/11.jpg', alt: 'Gallery User 11' },
      { src: '/assets/images/gallery/team/12.jpg', alt: 'Gallery User 12' },
      { src: '/assets/images/gallery/team/13.jpg', alt: 'Gallery User 13' },
      { src: '/assets/images/gallery/team/14.jpg', alt: 'Gallery User 14' },
      { src: '/assets/images/gallery/team/15.jpg', alt: 'Gallery User 15' },
      { src: '/assets/images/gallery/team/16.jpg', alt: 'Gallery User 16' },
      { src: '/assets/images/gallery/team/17.jpg', alt: 'Gallery User 17' },
      { src: '/assets/images/gallery/team/18.jpg', alt: 'Gallery User 18' },
      { src: '/assets/images/gallery/team/19.jpg', alt: 'Gallery User 19' },
      { src: '/assets/images/gallery/team/20.jpg', alt: 'Gallery User 20' },
      { src: '/assets/images/gallery/team/21.jpg', alt: 'Gallery User 21' },
      { src: '/assets/images/gallery/team/22.jpg', alt: 'Gallery User 22' },
      { src: '/assets/images/gallery/team/23.jpg', alt: 'Gallery User 23' },
      { src: '/assets/images/gallery/team/24.jpg', alt: 'Gallery User 23' },
      { src: '/assets/images/gallery/team/25.jpg', alt: 'Gallery User 23' },
      { src: '/assets/images/gallery/team/26.jpg', alt: 'Gallery User 23' },
      { src: '/assets/images/gallery/team/27.jpg', alt: 'Gallery User 23' },
      { src: '/assets/images/gallery/team/28.jpg', alt: 'Gallery User 23' },
      // { src: '/assets/images/gallery/team/1_1.jpeg', alt: 'Gallery User 1_1' },
      { src: '/assets/images/gallery/team/1_2.jpeg', alt: 'Gallery User 1_2' },
      { src: '/assets/images/gallery/team/1_3.jpeg', alt: 'Gallery User 1_3' },
      { src: '/assets/images/gallery/team/1_4.jpeg', alt: 'Gallery User 1_4' },
      { src: '/assets/images/gallery/team/1_5.jpeg', alt: 'Gallery User 1_5' },
      { src: '/assets/images/gallery/team/1_6.jpeg', alt: 'Gallery User 1_6' },
      { src: '/assets/images/gallery/team/1_7.jpeg', alt: 'Gallery User 1_7' },
      { src: '/assets/images/gallery/team/1_8.jpeg', alt: 'Gallery User 1_8' }
      // { src: '/assets/images/gallery/team/1_8.jpeg', alt: 'Gallery User 1_8' }
  ]
  
  },
  {
    id: 3,
    src: '/assets/images/gallery/christmas/2.jpg',
    alt: 'Gallery User 1',
    title:"Secret Santa",

    subimages: [
        { src: '/assets/images/gallery/christmas/1.png', alt: 'Gallery User 1' },
      { src: '/assets/images/gallery/christmas/2.jpg', alt: 'Gallery User 2' },
      { src: '/assets/images/gallery/christmas/3.jpeg', alt: 'Gallery User 3' },
      { src: '/assets/images/gallery/christmas/19.jpeg', alt: 'Gallery User 19' },


    ],
  },

  {
    id: 4,
    src: '/assets/images/gallery/birthdays/1.jpeg',
    alt: 'Gallery User 1',
    title:"Birthdays",

    subimages: [
      { src: '/assets/images/gallery/birthdays/1.jpeg', alt: 'Gallery User 1' },
      { src: '/assets/images/gallery/birthdays/2.jpeg', alt: 'Gallery User 2' },
      { src: '/assets/images/gallery/birthdays/3.jpeg', alt: 'Gallery User 3' },
      { src: '/assets/images/gallery/birthdays/4.jpeg', alt: 'Gallery User 4' },
      { src: '/assets/images/gallery/birthdays/5.jpeg', alt: 'Gallery User 5' },
      { src: '/assets/images/gallery/birthdays/6.jpeg', alt: 'Gallery User 6' },
      { src: '/assets/images/gallery/birthdays/7.jpeg', alt: 'Gallery User 7' },
      { src: '/assets/images/gallery/birthdays/8.jpeg', alt: 'Gallery User 8' },
      { src: '/assets/images/gallery/birthdays/9.jpeg', alt: 'Gallery User 9' } ,
      { src: '/assets/images/gallery/birthdays/32.jpeg', alt: 'Gallery User 32' }  

    ],
  },

  {
    id: 5,
    src: '/assets/images/gallery/outing/3.jpeg',
    alt: 'Gallery User 1',
    title:"Outing",

    subimages: [
      // { src: '/assets/images/gallery/outing/1.jpeg', alt: 'Gallery User 1' },
      { src: '/assets/images/gallery/outing/3.jpeg', alt: 'Gallery User 3' },
      { src: '/assets/images/gallery/outing/2.jpeg', alt: 'Gallery User 2' },
      { src: '/assets/images/gallery/outing/4.jpeg', alt: 'Gallery User 4' },
      { src: '/assets/images/gallery/outing/5.jpeg', alt: 'Gallery User 5' },
      { src: '/assets/images/gallery/outing/6.jpeg', alt: 'Gallery User 6' },
      { src: '/assets/images/gallery/outing/7.jpeg', alt: 'Gallery User 7' },
      { src: '/assets/images/gallery/outing/8.jpeg', alt: 'Gallery User 8' },
      { src: '/assets/images/gallery/outing/9.jpeg', alt: 'Gallery User 9' },
      { src: '/assets/images/gallery/outing/10.jpg', alt: 'Gallery User 10' },
      { src: '/assets/images/gallery/outing/11.jpg', alt: 'Gallery User 11' }
  
    ],
  },

  {
    id: 6,
    src: '/assets/images/gallery/travling/1.jpeg',
    alt: 'Gallery User 1',
    title:"Traveling",

    subimages: [
      { src: '/assets/images/gallery/travling/1.jpeg', alt: 'Gallery User 1' },
      { src: '/assets/images/gallery/travling/2.jpeg', alt: 'Gallery User 2' },
      { src: '/assets/images/gallery/travling/3.jpeg', alt: 'Gallery User 3' },
      { src: '/assets/images/gallery/travling/4.jpeg', alt: 'Gallery User 4' },
      { src: '/assets/images/gallery/travling/5.jpeg', alt: 'Gallery User 5' },
      { src: '/assets/images/gallery/travling/6.jpeg', alt: 'Gallery User 6' },
      // { src: '/assets/images/gallery/travling/7.jpeg', alt: 'Gallery User 7' },
      { src: '/assets/images/gallery/travling/8.jpeg', alt: 'Gallery User 8' },
      { src: '/assets/images/gallery/travling/9.jpeg', alt: 'Gallery User 9' },
      { src: '/assets/images/gallery/travling/10.jpeg', alt: 'Gallery User 10' },
      { src: '/assets/images/gallery/travling/11.jpeg', alt: 'Gallery User 11' },
      { src: '/assets/images/gallery/travling/12.jpeg', alt: 'Gallery User 12' }
  
    ],
  },


  
  {
    id: 7,
    src: '/assets/images/gallery/event/1.jpg',
    alt: 'Gallery User 1',
    title:"Events",

    subimages: [
      { src: '/assets/images/gallery/event/1.jpg', alt: 'Gallery User 1' },
      { src: '/assets/images/gallery/event/2.jpg', alt: 'Gallery User 2' },
      { src: '/assets/images/gallery/event/3.jpeg', alt: 'Gallery User 3' },
      { src: '/assets/images/gallery/event/4.jpeg', alt: 'Gallery User 4' },
      { src: '/assets/images/gallery/event/3.jpeg', alt: 'Gallery User 3' },
      { src: '/assets/images/gallery/event/4.jpeg', alt: 'Gallery User 4' },
  
    ],
  },


  {
    id: 8,
    src: '/assets/images/gallery/clients/1.jpeg',
    alt: 'Gallery User 1',
    title:"Client Visit",

    subimages: [
      { src: '/assets/images/gallery/clients/1.jpeg', alt: 'Gallery User 1' },
      { src: '/assets/images/gallery/clients/2.jpeg', alt: 'Gallery User 2' },

  
    ],
  },
  
  
];
