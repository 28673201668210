// candidate info showing on recruiter page

const recruiterData = [
  {
    jobTitle: " AWS Engineer ",
    summery: "",
    location: " Not Disclosed",
    experience: "2-4",
    highestQuali: "B.E.",

    skills: [
      "Amazon Web Services (AWS)",
      "Cloud Architecture",
      "Infrastructure as Code (e.g., Terraform, CloudFormation)",
      "Linux/Unix Administration",
      "Networking Concepts",
      "Security Best Practices",
      "EC2",
      "S3",
      "Lambda",
      "RDS",
      "VPC",
      "IAM",
    ],
    // education: [
    //   {
    //     degree: "B.E.",
    //     field: "Computer Science",
    //     institution: "University X",
    //     graduationYear: "2020",
    //   },
    // ],
    // experience_details: [
    //   {
    //     title: "Frontend Developer",
    //     company: "Company A",
    //     duration: "2 Years",
    //     location: " Pune",
    //     responsibilities: [
    //       "Developed UI components",
    //       "Worked with React",
    //       "Collaborated with design team",
    //     ],
    //   },
    //   {
    //     title: "Junior Frontend Developer",
    //     company: "Company B",
    //     duration: "1 Year",
    //     location: " Pune",
    //     responsibilities: [
    //       "Built responsive web pages",
    //       "Optimized performance using React",
    //     ],
    //   },
    // ],
    imageSrc: "/assets/images/icons/user.jpeg",
    certifications: [
      "AWS Certified Solutions Architect – Associate",
      "AWS Certified DevOps Engineer – Professional",
      "AWS Certified Developer – Associate",
    ],
    noticePeriod: "1 Month",
    expectedCTC: "Negotiable",
    currentCTC: "Not Disclosed",
    readyToRelocate: "Yes",
    prefferedLoc: "Pune/Mumbai",
    gender: "Male",
  },
  {
    jobTitle: "Java Developer",
    summery: "",
    location: " Not Disclosed",
    experience: "2-4",
    highestQuali: "B.E.",

    skills: ["Java", "Spring", "Hibernate", "OOP", "RESTful APIs"],
    // education: [
    //   {
    //     degree: "B.E.",
    //     field: "Computer Science",
    //     institution: "University X",
    //     graduationYear: "2020",
    //   },
    // ],
    // experience_details: [
    //   {
    //     title: "Frontend Developer",
    //     company: "Company A",
    //     duration: "2 Years",
    //     location: "Pune",
    //     responsibilities: [
    //       "Developed UI components",
    //       "Worked with React",
    //       "Collaborated with design team",
    //     ],
    //   },
    //   {
    //     title: "Junior Frontend Developer",
    //     company: "Company B",
    //     duration: "1 Year",
    //     location: "Pune",
    //     responsibilities: [
    //       "Built responsive web pages",
    //       "Optimized performance using React",
    //     ],
    //   },
    // ],
    imageSrc: "/assets/images/icons/user.jpeg",
    certifications: [
      "Oracle Certified Professional, Java SE Programmer",
      "Spring Professional Certification",
    ],
    noticePeriod: "20 Days",
    expectedCTC: "AS PER INDUSTRY STANDARD",
    currentCTC: "Not Disclosed",
    readyToRelocate: "Yes",
    prefferedLoc: "Pune",
    gender: "Female",
  },

  {
    jobTitle: "Python Developer",
    summery: "",
    location: " Hyderabad",
    experience: "2-4",
    highestQuali: "B.E.",

    skills: [
      "Python",
      "Django",
      "Flask",

      "Object-Oriented Programming (OOP)",
      "Data Structures and Algorithms",
      "RESTful APIs",
    ],
    // education: [
    //   {
    //     degree: "B.E.",
    //     field: "Computer Science",
    //     institution: "University X",
    //     graduationYear: "2020",
    //   },
    // ],
    // experience_details: [
    //   {
    //     title: "Frontend Developer",
    //     company: "Company A",
    //     duration: "2 Years",
    //     location: " Pune",
    //     responsibilities: [
    //       "Developed UI components",
    //       "Worked with React",
    //       "Collaborated with design team",
    //     ],
    //   },
    //   {
    //     title: "Junior Frontend Developer",
    //     company: "Company B",
    //     duration: "1 Year",
    //     location: " Pune",
    //     responsibilities: [
    //       "Built responsive web pages",
    //       "Optimized performance using React",
    //     ],
    //   },
    // ],
    imageSrc: "/assets/images/icons/user.jpeg",
    certifications: [
      "Python Institute Certified Entry-Level Python Programmer (PCEP)",
      "Python Institute Certified Associate in Python Programming (PCAP)",
    ],
    noticePeriod: "NA",
    expectedCTC: "Negotiable",
    currentCTC: "Not Disclosed",
    readyToRelocate: "Yes",
    prefferedLoc: "Pune/Mumbai",
    gender: "Male",
  },

  {
    jobTitle: "Data Engineer",
    summery: "",
    location: " Not Disclosed",
    experience: "2-4",
    highestQuali: "B.E.",

    skills: [
      "Python",
      "SQL",
      "Apache Spark",
      "Hadoop",
      "Data Warehousing",
      "ETL (Extract, Transform, Load)",
      "Data Modeling",
    ],
    // education: [
    //   {
    //     degree: "B.E.",
    //     field: "Computer Science",
    //     institution: "University X",
    //     graduationYear: "2020",
    //   },
    // ],
    // experience_details: [
    //   {
    //     title: "Frontend Developer",
    //     company: "Company A",
    //     duration: "2 Years",
    //     location: " Pune",
    //     responsibilities: [
    //       "Developed UI components",
    //       "Worked with React",
    //       "Collaborated with design team",
    //     ],
    //   },
    //   {
    //     title: "Junior Frontend Developer",
    //     company: "Company B",
    //     duration: "1 Year",
    //     location: " Pune",
    //     responsibilities: [
    //       "Built responsive web pages",
    //       "Optimized performance using React",
    //     ],
    //   },
    // ],
    imageSrc: "/assets/images/icons/user.jpeg",
    certifications: [
      "Google Cloud Professional Data Engineer",
      "AWS Certified Data Analytics – Specialty",
      "Microsoft Certified: Azure Data Engineer Associate",
    ],
    noticePeriod: "1 Month",
    expectedCTC: "Negotiable",
    currentCTC: "Not Disclosed",
    readyToRelocate: "Yes",
    prefferedLoc: "Hyderabad",
    gender: "Female",
  },

  {
    jobTitle: "Frontend Developer",
    summery: "",
    location: " Pune",
    experience: "2-4",
    highestQuali: "B.E.",
    skills: [
      "HTML",
      "CSS",
      "Responsive Design",
      "Javascript",
      "Web Performance Optimization",
      "React",
      "Teamwork",
    ],
    // education: [
    //   {
    //     degree: "B.E.",
    //     field: "Computer Science",
    //     institution: "University X",
    //     graduationYear: "2020",
    //   },
    // ],
    // experience_details: [
    //   {
    //     title: "Frontend Developer",
    //     company: "Company A",
    //     duration: "2 Years",
    //     location: "Pune",
    //     responsibilities: [
    //       "Developed UI components",
    //       "Worked with React",
    //       "Collaborated with design team",
    //     ],
    //   },
    //   {
    //     title: "Junior Frontend Developer",
    //     company: "Company B",
    //     duration: "1 Year",
    //     location: "Pune",
    //     responsibilities: [
    //       "Built responsive web pages",
    //       "Optimized performance using React",
    //     ],
    //   },
    // ],
    imageSrc: "/assets/images/icons/user.jpeg",
    certifications: [
      "Front-End Web Developer Nanodegree (Udacity)",
      "Responsive Web Design Certification (freeCodeCamp)",
    ],
    noticePeriod: "1 Month",
    expectedCTC: "AS PER INDUSTRY STANDARD",
    currentCTC: "Not Disclosed",
    readyToRelocate: "Yes",
    prefferedLoc: "Pune/Mumbai",

    gender: "Male",
  },




  {
    jobTitle: "Database Engineer ",
    summery: "",
    location: " Not Disclosed",
    highestQuali: "B.E.",

    experience: "2-4",
    skills: [
      "SQL",
      "Database Design and Modeling",
      "Database Management Systems (e.g., MySQL, PostgreSQL, Oracle)",
      "NoSQL Databases (e.g., MongoDB, Cassandra)",
      "Performance Tuning and Optimization",
      "Backup and Recovery Solutions",
      "Database Security",
      "Stored Procedures and Triggers",
    ],
    // education: [
    //   {
    //     degree: "B.E.",
    //     field: "Computer Science",
    //     institution: "University X",
    //     graduationYear: "2020",
    //   },
    // ],
    // experience_details: [
    //   {
    //     title: "Frontend Developer",
    //     company: "Company A",
    //     duration: "2 Years",
    //     location: " Pune",
    //     responsibilities: [
    //       "Developed UI components",
    //       "Worked with React",
    //       "Collaborated with design team",
    //     ],
    //   },
    //   {
    //     title: "Junior Frontend Developer",
    //     company: "Company B",
    //     duration: "1 Year",
    //     location: " Pune",
    //     responsibilities: [
    //       "Built responsive web pages",
    //       "Optimized performance using React",
    //     ],
    //   },
    // ],
    imageSrc: "/assets/images/icons/user.jpeg",
    certifications: [
      "Microsoft Certified: Azure Database Administrator Associate",
      "Oracle Certified Professional, MySQL Database Administrator",
      "AWS Certified Database – Specialty",
    ],
    noticePeriod: "1 Month",
    expectedCTC: "AS PER INDUSTRY STANDARD",
    currentCTC: "Not Disclosed",
    readyToRelocate: "Yes",
    prefferedLoc: "Nagpur",
    gender: "Male",
  },

  {
    jobTitle: "QA Engineer ",
    summery: "",
    location: " Not Disclosed",
    experience: "2-4",
    highestQuali: "B.E.",

    skills: [
      "Manual Testing",
      "Automated Testing (e.g., Selenium, JUnit, TestNG)",
      "Test Planning and Strategy",
      "Defect Tracking and Management (e.g., JIRA, Bugzilla)",
      "API Testing (e.g., Postman, SoapUI)",
      "Performance Testing (e.g., JMeter, LoadRunner)",
    ],
    // education: [
    //   {
    //     degree: "B.E.",
    //     field: "Computer Science",
    //     institution: "University X",
    //     graduationYear: "2020",
    //   },
    // ],
    // experience_details: [
    //   {
    //     title: "Frontend Developer",
    //     company: "Company A",
    //     duration: "2 Years",
    //     location: " Pune",
    //     responsibilities: [
    //       "Developed UI components",
    //       "Worked with React",
    //       "Collaborated with design team",
    //     ],
    //   },
    //   {
    //     title: "Junior Frontend Developer",
    //     company: "Company B",
    //     duration: "1 Year",
    //     location: " Pune",
    //     responsibilities: [
    //       "Built responsive web pages",
    //       "Optimized performance using React",
    //     ],
    //   },
    // ],
    imageSrc: "/assets/images/icons/user.jpeg",
    certifications: [
      "Certified Software Tester (CST)",
      "Certified Agile Tester (CAT)",
      "HP Certified Professional (HPE)",
    ],
    noticePeriod: "1 Month",
    expectedCTC: "AS PER INDUSTRY STANDARD",
    currentCTC: "Not Disclosed",
    readyToRelocate: "Yes",
    prefferedLoc: "Pune/Mumbai",
    gender: "Male",
  },
];

export default recruiterData;
