// import { Col, Button, Form, InputGroup, Row } from "react-bootstrap";

// export const LoginProductData = ({ loginProductData }) => {
//   const { heading, subHeading } = loginProductData[0]; // Extract heading and subHeading

//   return (
//     <div className="login-form-container">
//       <Form className="product-login-form">
//         <h2 className="login-form-main-header">{heading}</h2>
//         <h4 className="login-form-main-subheading">{subHeading}</h4>

//         <Row className="no-gutters">
//           <Col xs={12} md={6} className="form-col">
//             <Form.Group className="mb-3" controlId="firstName">
//               <Form.Label className="login-form-label">First Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Please enter your name"
//                 className="login-form-input-field"
//               />
//             </Form.Group>
//           </Col>

//           <Col xs={12} md={6} className="form-col">
//             <Form.Group className="mb-3" controlId="lastName">
//               <Form.Label className="login-form-label">Last Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Please enter your last name"
//                 className="login-form-input-field"
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         <Col xs={12}>
//           <Form.Group className="mb-3" controlId="email">
//             <Form.Label className="login-form-label">Email address</Form.Label>
//             <Form.Control
//               type="email"
//               placeholder="name@example.com"
//               className="login-form-input-field"
//             />
//           </Form.Group>
//         </Col>

//         <Col xs={12}>
//           <Form.Group className="mb-3" controlId="companyName">
//             <Form.Label className="login-form-label">Company Name</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter your company"
//               className="login-form-input-field"
//             />
//             <p className="form-msg">Must be at least 8 characters. You must use at least one letter and one number.</p>
//           </Form.Group>
//         </Col>

//         <Col xs={12}>
//           <Form.Group className="mb-3" controlId="phone">
//             <Form.Label className="login-form-label">Phone</Form.Label>
//             <InputGroup className="login-form-phone-input-field">
//               <Form.Select aria-label="Select Country" style={{ flex: "0 0 20%" }}>
//                 <option value="US">US</option>
//                 <option value="CA">CA</option>
//                 <option value="GB">GB</option>
//                 <option value="AU">AU</option>
//               </Form.Select>
//               <Form.Control type="tel" placeholder="+1 (555) 000-0000" style={{ flex: "1" }} />
//             </InputGroup>

//             <Form.Group controlId="privacyCheckbox" className="privacyCheckbox">
//               <Form.Check type="checkbox" label="I accept the Privacy Statement" />
//             </Form.Group>
//           </Form.Group>
//         </Col>

//         <Button variant="primary" type="submit" className="login-form-submit-btn">
//           Try it for free
//         </Button>
//       </Form>
//     </div>
//   );
// };

import { Col, Button, Form, InputGroup, Row } from "react-bootstrap";

export const LoginProductData = ({ loginProductData }) => {
  const { heading, subHeading, fields, buttonLabel, onSubmit } = loginProductData[0];

  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^[+0-9\s\(\)-]+$/; // Allows numbers, spaces, parentheses, and dashes
    return phonePattern.test(phoneNumber);
  };

  
  const renderFields = () => {
    let fieldIndex = 0;
    let rows = [];
  
    // Function to apply red color to the last letter of the label
    const styleLastLetter = (label) => {
      const lastChar = label.charAt(label.length - 1);
      const rest = label.slice(0, label.length - 1);
      return (
        <>
          {rest}
          <span className="last-letter-red">{lastChar}</span>
        </>
      );
    };
  
    // Function to render a field
    const renderField = (field, idx) => {
      // Special handling for the Phone field
      if (field.type === 'phone') {
        return (
          <Col key={`col-${field.id}-${idx}`} xs={12}>
            <Form.Group className="mb-3" controlId={field.id}>
              <Form.Label className="login-form-label">
                {field.required ? styleLastLetter(field.label) : field.label}
              </Form.Label>
              <InputGroup className="login-form-phone-input-field">
                <Form.Select
                  aria-label="Select Country"
                  style={{ flex: "0 0 20%" }}
                  className="login-form-select"
                  id="phoneSelectCountry"
                >
                  {field.countryOptions.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control
                  type="tel"
                  placeholder={field.placeholder}
                  style={{ flex: "1" }}
                  required={field.required} // Add required attribute
                  className="login-form-control"
                  id={field.id}
                  onBlur={(e) => {
                    // On blur, validate the phone number
                    const phoneNumber = e.target.value;
                    if (!validatePhoneNumber(phoneNumber)) {
                      alert("Please enter a valid phone number.");
                    }
                  }}
                />
              </InputGroup>
              {field.helperText && <p className="form-msg">{field.helperText}</p>}
            </Form.Group>
          </Col>
        );
      }
      // Special handling for Privacy Checkbox
      if (field.privacyCheckbox) {
        return (
          <Col key={`col-${field.id}-${idx}`} xs={12}>
            <Form.Group controlId={field.id} >
              <Form.Check
              // className="privacyCheckbox"
                type="checkbox"
                label={field.required ? styleLastLetter(field.privacyCheckboxText) : field.privacyCheckboxText}
                required={field.required} // Add required if the checkbox is mandatory
              />
            </Form.Group>
          </Col>
        );
      }
  
      // Render other fields normally
      return (
        <Col key={`col-${field.id}-${idx}`} xs={12} md={field.rowSpan || 6} className="form-col">
          <Form.Group className="mb-3" controlId={field.id}>
            <Form.Label className="login-form-label">
              {field.required ? styleLastLetter(field.label) : field.label}
            </Form.Label>
            <Form.Control
              type={field.type}
              placeholder={field.placeholder}
              className="login-form-input-field"
              required={field.required} // Add required attribute
            />
            {field.helperText && <p className="form-msg">{field.helperText}</p>}
          </Form.Group>
        </Col>
      );
    };
  
    // Loop through fields and render them
    while (fieldIndex < fields.length) {
      const currentField = fields[fieldIndex];
  
      // Handle grouping fields into a row (for First Name and Last Name)
      const groupRowFields = currentField.groupRow ? [currentField, fields[fieldIndex + 1]] : [currentField];
      
      // Push row rendering into the rows array
      rows.push(
        <Row key={`row-${fieldIndex}`} className="no-gutters">
          {groupRowFields.map((field, idx) => {
            fieldIndex++; // Move to the next field for the next loop iteration
  
            // Return rendered field JSX
            return renderField(field, idx);
          })}
        </Row>
      );
    }
  
    return rows;
  };
 
  


  return (
    <div className="login-form-container">
      <Form className="product-login-form">
        <h2 className="login-form-main-header">{heading}</h2>
        <h4 className="login-form-main-subheading">{subHeading}</h4>

        {renderFields()}

        <Button variant="primary" type="submit" onClick={onSubmit} className="login-form-submit-btn">
          {buttonLabel}
        </Button>
      </Form>
    </div>
  );
};
