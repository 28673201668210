import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './GetQuoteLinearButton.css';

const GetQuoteLinearButton = ({ label, onClick, icon, to }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(); // Execute custom onClick logic, if provided
    }
  };

  return (
    <div className="main-get-quote">
      <Link to={to} >
        <button className="get-quote-linaer-btnn" onClick={handleClick}>
          {label} {icon}
        </button>
      </Link>
    </div>
  );
};

export default GetQuoteLinearButton;
