import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './../../../../components/common-component/tab-details/TabDetails.css';
import CommonImage from '../../../common-component/common-image/CommonImage';

const RShopizerHugeData = () => {
    const features= [
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "Highly Configurable for Any Scale",
          description: "Supports small businesses to large-scale enterprises."
        },
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "Android & iOS Applications",
          description: "Mobile-first shopping experience."
        },
        {
          icon: "/assets/images/servicePage/imageGrid/check.svg",
          title: "Integrated Shipping & Payment Gateways",
          description: "Streamlined transactions and delivery management."
        },
        {
            icon: "/assets/images/servicePage/imageGrid/check.svg",
            title: "ONDC Integration",
            description: "Boosts marketplace presence through India's digital commerce network."
          }
      ];
      


    return (
        <section className="tab-details-main">
            <Container >
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                       <CommonImage imgsrc='/assets/images/productsPage/r-shopizer-tab-image.png' />
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <h4 className="tab-sub-heading">From vision to victory in digital world.</h4>
                            <h3 className="tab-heading mt-4">
                            Empowering businesses with
                            end-to-end e-commerce solutions
                            </h3>
                            <p className="normal-text-main mt-4">
                            Provides scalable and customizable e-commerce platforms with seamless integration.                            </p>
                            <div className="mt-5">
                                {features.map((feature, index) => (
                                    <div key={index} className="d-flex mb-4">
                                        <div className="me-3">
                                            <img
                                                src={process.env.PUBLIC_URL + feature.icon}
                                                alt={`${feature.title} icon`}
                                            />
                                        </div>
                                        <div>
                                            <h3 className="blue-text">{feature.title}</h3>
                                            <p className="normal-text">{feature.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default RShopizerHugeData;