import React from 'react';
import './../../cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const SoftwareModerizationFeature = () => {
 
 const softwaremodernizationfeature = [
    {
        icon: "/assets/images/servicesPage/feature-image/10.svg",
        title: "Alexa Integration",
        description:
            "Integrate Alexa Voice Service into your applications for seamless voice interactions, offering users unparalleled convenience and natural commands.",
    },

    {
        icon: "/assets/images/servicesPage/feature-image/11.svg",
        title: "Machine Learning (ML) and Artificial Intelligence (AI)",
        description:
            "AI and ML for automated tasks, insights, and enhanced user experiences. Analyze data for predictive analytics, personalized recommendations, and smart automation.",
    },
    {
        icon: "/assets/images/servicesPage/feature-image/12.svg",
        title: "Microservices Architecture",
        description:
            "Embrace microservices for flexible, scalable architecture. We break down monolithic apps into smaller services for independent development, deployment, and scaling.",
    },
];


    return (
        <section className="feature-main">
            <Container>
            <CommonHeader title="Why Choose RedBeryl!" subtitle="The core values that drive everything we do " highlightSubtitleIndex={2} />
                <Row>
                    {softwaremodernizationfeature.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default SoftwareModerizationFeature;
