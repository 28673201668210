import React, { useState } from 'react';
import './AboutThree.css';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInView } from 'react-intersection-observer';

const AboutThree = () => {
  const [inView, setInView] = useState(false);

  const counters = [
    {
      image: '/assets/images/aboutUs/team.svg',
      count: 50,
      text: 'Team members',
    },
    {
      image: '/assets/images/aboutUs/champ.svg',
      count: 30,
      text: 'Clients',
    },
    {
      image: '/assets/images/aboutUs/note.svg',
      count: 20,
      text: 'Completed project',
    },
    {
      image: '/assets/images/aboutUs/client.svg',
      count: 40,
      text: "Client's reviews",
    },
  ];

  const { ref, inView: isInView } = useInView({
    triggerOnce: true, 
    onChange: (inView) => setInView(inView), 
  });

  return (
    <section className="about-counter-main" ref={ref}>
      <Container>
        <Row>
          {counters.map((counter, index) => (
            <Col lg={3} md={3} sm={6} xs={6} key={index}>
              <div className="text-center">
                <div className="d-flex justify-content-center">
                  <img
                    src={process.env.PUBLIC_URL + counter.image}
                    className="counter-image"
                    alt={counter.text}
                  />
                  <div>
                    {/* Trigger count-up only when the section is in view */}
                    {inView && (
                      <CountUp
                        end={counter.count}
                        className="counter-count"
                        start={0} 
                        duration={2.5}
                      />
                    )}
                    <FontAwesomeIcon icon={faPlus} className="counter-plus-icon ms-1" />
                  </div>
                </div>
                <p className="count-text">{counter.text}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default AboutThree;
