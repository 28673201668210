import React, { useState } from "react";
import { Container, Row, Col, Carousel, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./servicesCarousel.css";

// Case Studies Header
export function CaseStudiesHeader() {
  return (
    <div className="case-studies-header-trust">
      <p className="trust">Why trust us?</p>
    </div>
  );
}

export function ServicesCarousel({ CaseStudies }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderItem = (item, idx) => (
    <>
      <div className="relative-box">
        <div className="main-container-services">
          <CaseStudiesHeader />
          <div className="services-carousel-title ">{item.title}</div>
          <div className="mt-3 services-carousel-description">
            {item.description}
          </div>
          <StaffCard />
        </div>
      </div>
      <div className="div-dot-nav-container">
        <DotNavigation
          totalItems={CaseStudies.length}
          activeIndex={activeIndex}
          onDotClick={setActiveIndex}
        />
      </div>
    </>
  );

  return (
    <Container
      className="services-main-main"
      style={{
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${CaseStudies[activeIndex].bg}) no-repeat`,
        backgroundPosition: "cover",
        backgroundPositionY: "0px",
      }}
    >
      <div className="services-main">
        <div className="content-wrapper">
          <Carousel
            activeIndex={activeIndex}
            onSelect={(index) => setActiveIndex(index)}
            interval={6000}
            controls={false}
            indicators={false}
          >
            {CaseStudies.map((item, idx) => (
              <Carousel.Item key={idx}>{renderItem(item, idx)}</Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </Container>
  );
}

// Staff Card Component
export function StaffCard() {
  return (
    <div className="mt-4">
      <Link to="/contact-us">
        <Button className="rounded-pill services-contact-btn">
          Contact Us 
        </Button>
      </Link>
    </div>
  );
}

export function DotNavigation({ totalItems, activeIndex, onDotClick }) {
  return (
    <div className="dot-navigation-container">
      {Array.from({ length: totalItems }).map((_, idx) => (
        <div
          key={idx}
          className={`dot ${activeIndex === idx ? "active" : ""}`}
          onClick={() => onDotClick(idx)}
        >
          {activeIndex === idx ? "0" + Number(idx + 1) : ""}
        </div>
      ))}
    </div>
  );
}
