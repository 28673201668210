import React from 'react';
import './../../cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const ProductDevlopementFeature = () => {
    const collaborativefeature = [
        {
            icon: "/assets/images/servicesPage/feature-image/19.svg",
            title: "Idea Incubation and Brainstorming",
            description:
                "Jumpstart product development with our idea incubation sessions, refining concepts into viable products through creative thinking and diverse perspectives.",
        },
    
        {
            icon: "/assets/images/servicesPage/feature-image/20.svg",
            title: "Cross-Functional Collaboration",
            description:
                "We foster seamless cross-functional collaboration through workshops, virtual meetings, and digital platforms, maximizing expertise contribution.",
        },
        {
            icon: "/assets/images/servicesPage/feature-image/21.svg",
            title: "Project Management and Collaboration Tool",
            description:
                "Access our platform for seamless project management and collaboration with cutting-edge tools like shared documents, real-time communication, task tracking, and version control.",
        },
    
    ];
    

    return (
        <section className="feature-main">
            <Container>
            <CommonHeader title="Why Choose RedBeryl!" subtitle="The core values that drive everything we do " highlightSubtitleIndex={2} />
                <Row>
                    {collaborativefeature.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default ProductDevlopementFeature;
