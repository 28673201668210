import * as React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";  
import './BlogCard.css';

export function BlogCard({ image, date, author, authorIcon, dateIcon, title, backgroundImage, id }) {

  return (
    <Container fluid className="blog-card-container d-flex flex-column">
      <Link to={`/blog/${id}`} className="text-decoration-none">
        <div className="position-relative">
          {/* Main Image */}
          <Row>
            <Col className="d-flex justify-content-center">
              <Image
                loading="lazy"
                src={image}
                alt={title}
                className="blog-card-image"
              />
            </Col>
          </Row>

          {/* Content Section */}
          <div className="blog-card-content">
            <div className="blog-card">
              <div className="blog-card-text-container">
                <Row>
                  {/* Date Section */}
                  <Col xs={12} md={6} className="blog-card-date-author">
                    <Image
                      loading="lazy"
                      src={dateIcon}
                      alt="Date Icon"
                      fluid
                      className="blog-card-date-author-icon"
                    />
                    <span className="blog-card-text">{date}</span>
                  </Col>

                  {/* Author Section */}
                  <Col xs={12} md={6} className="blog-card-date-author">
                    <Image
                      loading="lazy"
                      src={authorIcon}
                      alt="Author Icon"
                      fluid
                      className="blog-card-date-author-icon"
                    />
                    <span className="blog-card-text">{author}</span>
                  </Col>
                </Row>
              </div>

              {/* Title */}
              <Row className="mt-2">
                <Col>
                  <h5 className="blog-card-title">{title}</h5>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Link>
    </Container>
  );
}
