import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import GetQuoteButton from '../../common-component/get-quote-btn/GetQuoteButton'
import './ItSolution.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

const ItSolution = ({
    title,
    description,
    buttonText,
    buttonOnClick
}) => {
      useEffect(() => {
        AOS.init();
      }, [])
    return (
        <section className='main-it-solution'>
            <div className='mmmm'>
                <Container data-aos="fade-up">
                    <div className='main-text-solution'>
                        <h3>{title}</h3>
                        <p className='mb-4 main-text-solution-description'>{description}</p>
                        <GetQuoteButton text={buttonText} onClick={buttonOnClick} />
                    </div>
                </Container>
            </div>
            <div className='blue-overlay'></div>
        </section >
    )
}

export default ItSolution