import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner'
import CommonHeader from '../../common-component/common-header/CommonHeader'
import Testimonials from '../../homepage/testimonials/Testimonials'
import GetQuote from '../../common-component/get-quote/GetQuote'
import FAQs from '../../common-component/common-faq/FAQs'
import RFillingsData from './r-fillings-data/RFillingsData'
import RfillingsHugeData from './rfillingsData/RfillingsHugeData'
import RfillingsFeature from './rfillings-features/RfillingsFeature'
import MobileAppData from './mobile-app-data/MobileAppData'

const Rfillings = () => {


  const faqData = [
    {
      question: "What is R-Filings, and who is it designed for?",
      answer: "R-Filings is a B2B platform designed to connect filing service providers with businesses. It streamlines entity formation, compliance management, and document filing processes across all 50 states, serving both service providers and business owners."
    },
    {
      question: "What services can I access through R-Filings?",
      answer: "R-Filings offers services for entity formation, compliance tracking, document retrieval, role-based user management, CRM integration, and real-time updates for seamless business filing and compliance tasks."
    },
    {
      question: "How does R-Filings simplify compliance management?",
      answer: "The platform provides automated compliance tracking with built-in tools that monitor deadlines, send reminders, and ensure accuracy across state requirements, helping businesses stay compliant effortlessly."
    },
    {
      question: "Can R-Filings support multiple entities under one account?",
      answer: "Yes, R-Filings allows client admins to manage multiple entities under one account. You can assign roles, track compliance needs, and view centralized details for all entities in one dashboard."
    },
    {
      question: "How does R-Filings integrate with other tools?",
      answer: "R-Filings seamlessly integrates with accounting tools and CRMs, enabling automated client account setup, tracking, and financial management for a streamlined workflow."
    },
    {
      question: "What kind of analytics does R-Filings provide?",
      answer: "The platform offers actionable reporting and analytics to assess client orders, compliance success, and employee productivity, empowering informed decision-making with data insights."
    },
    {
      question: "How does R-Filings ensure user-friendliness?",
      answer: "R-Filings features a clean, intuitive interface that requires minimal training. Its user-friendly design simplifies navigation and task completion for admins and users alike."
    },
    {
      question: "Can I track the status of my filings in real time?",
      answer: "Yes, R-Filings provides real-time status updates and alerts, keeping you informed about ongoing filings and any necessary actions."
    },
    {
      question: "What role management features does R-Filings offer?",
      answer: "The platform allows you to assign roles like organizer, primary contact, or account manager to users for each entity, ensuring alignment with compliance and operational requirements."
    },
    {
      question: "Is R-Filings suitable for businesses in all 50 states?",
      answer: "Absolutely! R-Filings is designed to support compliance and filing tasks across all 50 states, providing state-specific tools and guidance to meet local regulations."
    }
  ];

  const rfillingsTestimonials = [{
    id: 1,
    image: "/assets/images/reviews/reviewPersonM.png",
    name: "Matt G.",
    role: "Product Manager | US Based Technology Company ",
    quote: "RedBeryl has demonstrated impressive flexibility and ability to quickly pivot with the evolving requirements of the project. They have been quick with their turnaround time and actively engaged throughout the duration of the project. They have been a strong partner throughout the project journey.",
},    {
  id: 2,
  image: "/assets/images/reviews/reviewPersonF.png",
  name: "Sam C.",
  role: "CTO | Technology Company",
  quote: "RedBeryl's dedication to quality and their ability to quickly adapt to our changing requirements have made a significant impact on our business. We appreciate their prompt communication, regular updates, and commitment to maintaining a strong working relationship.",
},
  ]



  return (
    <>

      <section>

        <CommonServiceBanner
          imageSrc={"/assets/images/productsPage/R-filingBg.png"}
          subheading={"A comprehensive platform that helps you"}
          title={"Launch, Manage"}
          titleSecond={"and Comply"}
          description={
            "From entity formation to ongoing compliance, streamline every stage of business lifecycle with R-Filings. Our platform will help you through every step of the process across USA."
          }
          quoteButtonLabel={"Contact Us"}
          downloadButtonLabel={"Download Brouchure"}
        />
        <RFillingsData />
        <RfillingsHugeData />
        <MobileAppData />
        <RfillingsFeature />
        <CommonHeader highlightSubtitleIndex={3} subtitle='Empowering businesses with seamless compliance solutions' title='Our Clients' />
        <div style={{ textAlign: "center", marginBottom: "35px" }} >
          <img src="/assets/images/landingPage/logos/fillings/vstate_fillings.png" />
        </div>
        <Testimonials testimonials={rfillingsTestimonials} />
        <GetQuote title="Business formation & compliance, all in one place." subtitle="From entity formation to ongoing compliance, lets handle the details while you focus on growth." />
        <FAQs faqData={faqData} />


      </section>

    </>
  )
}

export default Rfillings