import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Navbar, NavDropdown } from "react-bootstrap"; // Import NavDropdown
import { faArrowRightLong, faEnvelope, faMapLocationDot, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import GetQuoteLinearButton from "../common-component/get-quote-linear-btn/GetQuoteLinearButton";
import { faFacebookF, faLinkedin, faSquareInstagram, faSquareXTwitter } from "@fortawesome/free-brands-svg-icons";

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");

  const socialLinks = [
    {
      icon: faFacebookF,
      link: "https://www.facebook.com/RedberylTech",
      title: "Facebook",
    },
    {
      icon: faSquareInstagram,
      link: "https://www.instagram.com/info.redberyl.tech/",
      title: "Instagram",
    },
    {
      icon: faSquareXTwitter,
      link: "https://x.com/RedberylTech",
      title: "Twitter",
    },
    {
      icon: faLinkedin,
      link: "https://www.linkedin.com/in/redberyl-tech-solutions/",
      title: "LinkedIn",
    },
  ];
  

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  return (
    <>
      <section className="main-header">
        <div className="black-header-top">
          <Container>
            <div className="top-header-mainnn">
              <p className="head-toppp">
                <FontAwesomeIcon icon={faPhoneVolume} className="me-2 " /> <a href="tel:+91 20 4722 7424" className="contact-data" >+91 20 4722 7424 </a> 
              </p>
              <p className="head-toppp">
                <FontAwesomeIcon icon={faMapLocationDot} className="me-2" />
                <a href="https://www.google.com/maps/search/?api=1&query=507-B+Amanora+Chambers+Amanora+Mall+Hadapsar+Pune+411028" target="_blank" rel="noreferrer" className="contact-data"> 507-B, Amanora Chambers, Amanora Mall, Hadapsar, Pune-411028</a>
              </p>
              <p className="head-toppp">
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />      <a href="mailto:info@redberyltech.com" className="contact-data">
        info@redberyltech.com
      </a>
              </p>
              <div>
                {socialLinks.map((social, index) => (
                  <a
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    title={social.title}
                    className="header-social-icon-link"
                  >
                    <FontAwesomeIcon icon={social.icon} className="header-scial-icon" />
                  </a>
                ))}
              </div>
            </div>
            <Navbar collapseOnSelect expand="lg">
              <Link to={"/"}>
                <Navbar.Brand>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`}
                    className="logo-img"
                    alt="logo"
                    onClick={() => handleNavItemClick("/")}
                  />
                </Navbar.Brand>
              </Link>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                <Nav>
                  <Nav.Link
                    as={Link}
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                    onClick={() => handleNavItemClick("home")}
                  >
                    Home
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="/about"
                    className={location.pathname === "/about" ? "active" : ""}
                    onClick={() => handleNavItemClick("about")}
                  >
                    About Us
                  </Nav.Link>

                  {/* Dropdown for Products */}
                  <NavDropdown title="Products" id="products-dropdown">

                  <NavDropdown.Item as={Link} to="/products/" onClick={() => handleNavItemClick("/products/optimus-prime")}>
                      Overview
                    </NavDropdown.Item>


                    <NavDropdown.Item as={Link} to="/products/optimus-prime" onClick={() => handleNavItemClick("/products/optimus-prime")}>
                      Optimus Prime
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/products/r-recruit" onClick={() => handleNavItemClick("/products/r-recruit")}>
                      R-Recruit
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/products/r-fillings" onClick={() => handleNavItemClick("/products/r-fillings")}>
                      R-Fillings
                    </NavDropdown.Item>

                    <NavDropdown.Item as={Link} to="/products/r-shopizer" onClick={() => handleNavItemClick("/products/r-shopizer")}>
                      R-Shopizer
                    </NavDropdown.Item>
                  </NavDropdown>

                  {/* Dropdown for Services */}
                  <NavDropdown title="Services" id="services-dropdown">

                  <NavDropdown.Item as={Link} to="/services/" onClick={() => handleNavItemClick("/products/optimus-prime")}>
                    Overview
                    </NavDropdown.Item>


                    <NavDropdown.Item as={Link} to="/services/cloud-solutions" onClick={() => handleNavItemClick("services/consulting")}>
                      Cloud Solutions
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/services/data-migration" onClick={() => handleNavItemClick("/services/data-migration")}>
                      Data Migration
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/services/talent-staffing" onClick={() => handleNavItemClick("/services/talent-staffing")}>
                      Talent Staffing
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/services/software-moderization" onClick={() => handleNavItemClick("/services/software-moderization")}>
                      Software Modernization (AI/ML)
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/services/app-devlopment" onClick={() => handleNavItemClick("/services/app-devlopment")}>
                      Web & Mobile App Development
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/services/devops-automation" onClick={() => handleNavItemClick("/services/devops-automation")}>
                      DevOps Automation
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/services/collaborative" onClick={() => handleNavItemClick("/services/collaborative")}>
                      Collaborative Product Development
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/services/odc-establishment" onClick={() => handleNavItemClick("/services/odc-establishment")}>
                      ODC Establishment
                    </NavDropdown.Item>
                  </NavDropdown>

                  {/* Dropdown for Career */}
                  <NavDropdown title="Career" id="career-dropdown">
                    <NavDropdown.Item as={Link} to="/career/job-seeker" onClick={() => handleNavItemClick("/career/job-seeker")}>
                      Job Seeker
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/career/recruiter" onClick={() => handleNavItemClick("/career/recruiter")}>
                      Recruiters
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    className={location.pathname === "/contact-us" ? "active" : ""}
                    onClick={() => handleNavItemClick("contact-us")}
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
                {/* <Link to="/product-login">
                <GetQuoteLinearButton label="Login/Register" to="/product-login" icon={<FontAwesomeIcon icon={faArrowRightLong} />} />
                </Link> */}
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      </section>
    </>
  );
}

export default Header;