import React from 'react'
import './MobileAppData.css'
import { Col, Container, Row } from 'react-bootstrap'

const MobileAppData = () => {
    return (
        <section className='mobile-data'>
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className='tab-details-main'>
                            <h3 className='tab-sub-heading'>Mobile app access</h3>
                            <h5 className='tab-heading'>Quick and convenient management anywhere</h5>
                            <p className='normal-text'>Manage client orders, track compliance, and receive timely updates on essential tasks—all from your mobile device.</p>
                        </div>
                        <div className='main-btn mb-5'>
                            <button className='store-btn me-3'> <img src={process.env.PUBLIC_URL + '/assets/images/servicePage/download_on_AppStore.svg'} className='mobile-img-btn' /></button>
                            <button className='store-btn'> <img src={process.env.PUBLIC_URL + '/assets/images/servicePage/download_on_PlayStore.svg'} className='mobile-img-btn' /></button>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <img src={process.env.PUBLIC_URL + '/assets/images/servicePage/mob.png'} className='mobile-img' />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default MobileAppData