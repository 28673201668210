import React, { useEffect } from 'react'
import './BuisnessNeeds.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CommonHeader from '../../common-component/common-header/CommonHeader';
import AOS from 'aos';
import 'aos/dist/aos.css';


const services = [
  {
    title: "Cloud Solutions",
    image: "/assets/images/landingPage/cardLayout/cloud.jpeg",
    description: "Enable businesses to store, manage, and access data remotely with scalable, cost-effective cloud strategies. Services include consultation, infrastructure design, migration, and ongoing support.",
    link: '/services/cloud-solutions'
  },
  {
    title: "Data Migration",
    image: "/assets/images/landingPage/cardLayout/dataMigration.jpeg",
    description: "Seamlessly migrate data warehouses, ensuring data integrity and optimized performance. Includes data assessment, migration strategy, ETL processes, and post-migration support.",
    link: '/services/data-migration'
  },
  {
    title: "Talent Staffing",
    image: "/assets/images/landingPage/TalentStaffing.jpeg",
    description: "Provide tailored staffing solutions, matching candidates to your company culture and roles. From entry-level to executives, ensure business success with top talent.",
    link: '/services/talent-staffing'
  },
  {
    title: "Software Moderization (AI/ML)",
    image: "/assets/images/landingPage/SoftwareModernization.jpeg",
    description: "Modernize software with AI, ML, IoT, and microservices for enhanced functionality and user experience. Stay competitive with seamless integration and advanced solutions.",
    link: '/services/software-moderization'
  },
  {
    title: "Web & Mobile App Devlopment",
    image: "/assets/images/landingPage/WebAndMob.jpeg",
    description: "Develop custom web and mobile apps with end-to-end services, from design to deployment. Deliver functional, user-friendly solutions tailored to your needs.",
    link: '/services/app-devlopment'
  },
  {
    title: "End To End Automation CI/CD & DevOps",
    image: "/assets/images/landingPage/cardLayout/ene.jpeg",
    description: "Automate testing and CI/CD processes to enhance speed, reliability, and coverage. Ensure software validation with efficient, scalable solutions.",
    link: '/services/devops-automation'
  },
  {
    title: "Collaborative Product Devlopment",
    image: "/assets/images/landingPage/Collab.jpeg",
    description: "Foster teamwork to create and refine products with a seamless blend of ideas and market demands. Engage in ideation, prototyping, and iterative development.",
    link: '/services/collaborative'
  },
  {
    title: "ODC Establishment",
    image: "/assets/images/landingPage/cardLayout/ODC.png",
    description: "Set up offshore development centers to expand capabilities with cost-effective, scalable teams. Access global talent for long-term project success.",
    link: '/services/odc-establishment'
  }
];


const BuisnessNeeds = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className='business-needs-main'>
      <Container>
        <CommonHeader title="What We Do" subtitle="We provide IT services that your business needs" highlightSubtitleIndex={6} />
        <Row data-aos="fade-up"
          data-aos-duration="3000">
          {services.map((service, index) => (
            <Col xl={3} lg={4} md={6} sm={6} xs={12} key={index}>
              <div className='main-img-service mb-4'>
                <img
                  src={process.env.PUBLIC_URL + service.image}
                  className="service-img"
                  alt={service.title}
                />
                <div className='diagonal-color'>
                  <p className='service-title'>{service.title}</p>
                </div>
                <div className="overlay-blue">
                  <div>
                    <p className='service-text'>{service.description}</p>

                    <Link to={service.link}>

                      <button className='read-more-btnn'>
                        Read More

                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default BuisnessNeeds;
