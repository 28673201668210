import React from 'react'
import '../what-matters/WhatMatters.css'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

const FirstJobSection = () => {
    return (
        <section className='first-job-section'>
            <div>
                <h4 className='sub-hedaing-first-job'>What matters to us</h4>
                <h3 className='heding-first-job'>Diversity, culture, respect, passion, <span className='pink-text-heding'>learning!</span></h3>
                <p className='blk-normal-text'>At RedBeryl, we take a comprehensive approach to the workplace.</p>
            </div>
            <div className='common-tab mb-4'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="tabs-link-holder">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Learn & Grow</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Passion & Respect</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Collaborate</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className='mt-4'>
                        <Tab.Pane eventKey="first">
                            <p className='normal-text'>Learn and grow together through knowledge-sharing and self-improvement. By exchanging insights and learning from each other, we foster an environment of continuous growth. We support each other’s personal and professional development, strengthening the whole team. Together, we overcome challenges and celebrate progress.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <p className='normal-text'>Embrace passion and respect, fostering an environment of mutual support. Passion drives us to pursue excellence, while respect ensures that we value each person’s contributions. This combination cultivates a space where creativity thrives, and diverse perspectives are embraced. Together, we build strong, supportive relationships that propel us forward.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <p className='normal-text'>Collaborate across teams to bring innovative ideas to life. By working together, we combine unique skills and perspectives to tackle challenges more effectively. Cross-team collaboration sparks creativity and accelerates problem-solving. Through teamwork, we transform ideas into impactful solutions that drive success and growth.</p>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

        </section>
    )
}

export default FirstJobSection