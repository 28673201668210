import React from 'react';
import { Pagination } from 'react-bootstrap';

import "./PaginationCommon.css"
const PaginationCommon = ({ totalPages, currentPage, onPageChange }) => {
    // Generate pagination items dynamically
    const pageItems = [];
    for (let number = 1; number <= totalPages; number++) {
        pageItems.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => onPageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <section className='pagination-common'>
            <div className='d-flex justify-content-center'>
                <Pagination>
                    {/* Previous button */}
                    <Pagination.Prev
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1} // Disable if we're on the first page
                    />
                    {/* Pagination items */}
                    {pageItems}
                    {/* Next button */}
                    <Pagination.Next
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages} // Disable if we're on the last page
                    />
                </Pagination>
            </div>
        </section>
    );
};

export default PaginationCommon;
