import React from 'react';

import '../../../services-page/cloud-solution/cloud-solution-feature/CloudFeature.css';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureCard from '../../../common-component/feature-card/FeatureCard';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const HrmsFeature = () => {

    const features = [
        {
          icon: "/assets/images/productsPage/hrmsGrid/hrmsPred.svg",
          title: "Predictive Candidate Search",
          description: "Powered by advanced algorithms and machine learning, our solution intelligently analyzes resumes, skills, and job preferences to identify the perfect candidates for your organization."
        },
        {
          icon: "/assets/images/productsPage/hrmsGrid/hrmsonboarding.svg",
      
          title: "Paperless Employee Onboarding",
          description: "Say goodbye to cumbersome paperwork and manual processes and digitize the entire onboarding journey, from document submissions to training modules, creating an efficient workflow."
        },
        {
          icon: "/assets/images/productsPage/hrmsGrid/hrmsvideo-call.svg",
      
          title: "Customizable Interview Workflow",
          description: "Create and customize interview workflows to match the needs of different positions, ensuring a seamless process from initial screening to final decision."
        },
        {
          icon: "/assets/images/productsPage/hrmsGrid/hrmsshortlist.svg",
          title: "Automated Candidate Scoring and Shortlisting",
          description: "Speed up decision-making with automated candidate scoring that ranks applicants based on skills, experience, and role alignment, enabling you to focus on top-tier talent first."
        },
        {
          
          icon: "/assets/images/productsPage/hrmsGrid/hrmsroles.svg",
          title: "Candidate Sourcing and Role Matching",
          description: "Leverage a pre-vetted candidate pool to quickly fill roles with top talent. Choose from a range of qualified candidates tailored to meet specific job requirements."
        },
        {
          icon: "/assets/images/productsPage/hrmsGrid/hrmssatisfaction.svg",
      
          title: "Interview Scheduling and Evaluation",
          description: "Simplify interview scheduling and candidate assessment with automated tools, ensuring an efficient process for recruiters and a positive experience for candidates."
        },
        {
          icon: "/assets/images/productsPage/hrmsGrid/hrmsVectorFeedback.svg",
      
      
      
          title: "Comprehensive Candidate Profile and Feedback",
          description: "Access detailed candidate profiles, monitor feedback from the hiring team, and streamline decisions with a centralized view of candidate qualifications."
        },
        {
          icon: "/assets/images/productsPage/hrmsGrid/hrmsVectorManagement.svg",
      
          title: "Employee Lifecycle Management",
          description: "From recruitment and onboarding to performance evaluation and offboarding, our comprehensive solution covers every stage of an employee's journey within your organization."
        },
        {
          icon: "/assets/images/productsPage/hrmsGrid/hrmsAnalytics.svg",
      
          title: "Analytics and Reporting",
          description: "Gain insights from hiring analytics to refine processes, identify improvement areas, and continuously optimize recruitment strategies."
        }
      ];

    return (
        <section className="feature-main">
            <Container>
                <CommonHeader highlightSubtitleIndex={1} title='Why Choose R-Recruit?' subtitle='Streamline hiring with a comprehensive staffing platform '/>

                <Row>
                    {features.map((feature, index) => (
                        <Col
                            key={index}
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <div className='mt-4'>
                                <FeatureCard
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default HrmsFeature;
