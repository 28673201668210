import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { faFacebookF, faLinkedin, faSquareInstagram, faSquareXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const socialLinks = [
    {
      icon: faFacebookF,
      link: "https://www.facebook.com/RedberylTech",
      title: "Facebook",
    },
    {
      icon: faSquareInstagram,
      link: "https://www.instagram.com/info.redberyl.tech/",
      title: "Instagram",
    },
    {
      icon: faSquareXTwitter,
      link: "https://x.com/RedberylTech",
      title: "Twitter",
    },
    {
      icon: faLinkedin,
      link: "https://www.linkedin.com/in/redberyl-tech-solutions/",
      title: "LinkedIn",
    },
  ];
  

  const footerSections = [
    {
      title: "Products",
      items: [
        { name: "Optimus Prime", link: "/products/optimus-prime" },
        { name: "R-Recruit", link: "/products/r-recruit" },
        { name: "R-Filings", link: "/products/r-fillings" },
        { name: "R-Shopizer", link: "/products/r-shopizer" },
      ],
    },
    {
      title: "Services",
      items: [
        { name: "Cloud Solutions", link: "/services/cloud-solutions" },
        { name: "Data Migration", link: "/services/data-migration" },
        { name: "Talent Staffing", link: "/services/talent-staffing" },
        { name: "Software Modernization (AI/ML)", link: "/services/software-moderization" },
        { name: "Web & Mobile App Development", link: "/services/app-devlopment" },
        { name: "Automation CI/CD & DevOps", link: "/services/devops-automation" },
      ],
    },
    {
      title: "Company",
      items: [
        { name: "About Us", link: "/about" },
        { name: "Career", link: "/career/job-seeker" },
        { name: "Blog",  link: "/blog"},
        { name: "Gallery", link: "/gallery"},
      ],
    },
    {
      title: "Contact Info",
      items: [
        {
          type: "call",
          icon: "/assets/images/icons/Call.png",
          content: "+91 76669 87829",
          action: "tel:+917666987829",
        },
        {
          type: "email",
          icon: "/assets/images/icons/mail.png",
          content: "info@redberyltech.com",
          action: "mailto:info@redberyltech.com",
        },
        {
          type: "website",
          icon: "/assets/images/icons/globe.svg",
          content: "www.redberyltech.com",
          action: "https://www.redberyltech.com",
        },
        {
          type: "address",
          icon: "/assets/images/icons/location.png",
          content: "507-B,\n Amanora Chambers\nAmanora Mall, Hadapsar\nPune - 411028",
          action:
            "https://www.google.com/maps/search/?api=1&query=507-B+Amanora+Chambers+Amanora+Mall+Hadapsar+Pune+411028",
        },
      ],
    },
  ];

  const renderSectionItems = (section) =>
    section.items.map((item, index) =>
      item.type ? (
        <div className="d-flex mt-3" key={index}>
          <img
            src={process.env.PUBLIC_URL + item.icon}
            className="icon-img me-2"
            alt="icon"
          />
          <p className="footer-menu">
            <a
              href={item.action}
              target={item.type === "website" || item.type === "address" ? "_blank" : "_self"}
              rel="noopener noreferrer"
              className="footer-link"
            >
              {item.type === "address"
                ? item.content.split("\n").map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                : item.content}
            </a>
          </p>
        </div>
      ) : (
        <Link to={item.link} key={index}>
          <p className="footer-menu">{item.name}</p>
        </Link>
      )
    );

  return (
    <section className="main-footer">
      <Container>
        <Row>
          <Col lg={3} md={12} sm={3} xs={12}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`}
              className="logo-img"
              alt="logo"
            />
            <p className="footer-textt-first mt-3">
              Driving Digital Transformation with Technology and Talent.
            </p>
            <div>
              {socialLinks.map((social, index) => (
                <a
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  title={social.title}
                  className="footer-scial-icon"
                >
                  <FontAwesomeIcon icon={social.icon} className="footer-social-icon" />
                </a>
              ))}
            </div>
          </Col>
          <Col lg={9} md={12} sm={9} xs={12}>
            <Row>
              {footerSections.map((section, index) => (
                <Col lg={3} md={3} sm={6} xs={12} key={index}>
                  <div>
                    <h3 className="footer-menu-title mb-5">{section.title}</h3>
                    {renderSectionItems(section)}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div className="text-center mt-5">
          <p className="last-line-footer">
            © 2024 RedBeryl Tech. All rights reserved.{" "}
            <Link to="/privacy">| Privacy policy </Link>
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Footer;