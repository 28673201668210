import React from 'react'
import './GetQuote.css'
import { Container } from 'react-bootstrap'
import GetQuoteButton from '../get-quote-btn/GetQuoteButton'

const GetQuote = ({title, subtitle}) => {
  return (
    <section className='get-quote-main'>
      <Container>
        <div className='main-get-quote-text'>
          <h3>{title}</h3>
          <p>{subtitle}</p>
          
          <GetQuoteButton to="/contact-us" text="Contact Us"/>
        </div>
      </Container>
    </section>
  )
}

export default GetQuote