import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Container, Row } from 'react-bootstrap';
import '../../../common-component/common-tab/CommonTab.css'
import TabDetails from '../../../common-component/tab-details/TabDetails';
import CommonHeader from '../../../common-component/common-header/CommonHeader';

const tabData = [
    {
        key: "first",
        heading: "Simplify homogeneous data migration",
        subHeading: "Transition data effortlessly between platforms with matching schemas for reliability and precision.",
        title: "Homogeneous Database Migration",
        description: "Seamlessly migrate databases like Oracle to PostgreSQL, ensuring schema consistency for operational continuity and data integrity.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/optimus-prime/Homogenious.png`,
    },
    {
        key: "second",
        heading: "Seamless heterogeneous data migration",
        subHeading: "Bridge diverse schemas and platforms with precision for smooth migration.",
        title: "Heterogeneous Database Migration",
        description: "Migrate between platforms like Oracle and PostgreSQL, transforming data to align with new schemas while preserving integrity.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/optimus-prime/Hetero.png`,
    },
    {
        key: "third",
        heading: "Efficient massive data migration",
        subHeading: "Migrate large-scale data efficiently with speed, accuracy, and reliability.",
        title: "Big Data Transfers",
        description: "Transfer huge datasets like Greenplum to Google BigQuery, leveraging advanced tools to minimize downtime and maximize performance.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/optimus-prime/Big_Data.png`,
    },
    {
        key: "fourth",
        heading: "Custom data migration solutions",
        subHeading: "Tailor your migration strategy for precision and efficiency.",
        title: "Custom Migration Options",
        description: "Move only necessary data across systems, optimizing performance and avoiding redundant transfers.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/optimus-prime/Custome.png`,
    },
    {
        key: "fifth",
        heading: "On-Demand migration scheduling",
        subHeading: "Schedule migrations at your convenience with minimal disruption.",
        title: "Flexible, On-Demand Scheduling",
        description: "Enable flexible scheduling for seamless migrations, ensuring smooth transitions with optimized timing and zero downtime.",
        dotImageSrc: `${process.env.PUBLIC_URL}/assets/images/servicePage/h2-shape2.png`,
        mainImageSrc: `${process.env.PUBLIC_URL}/assets/images/productsPage/product-tab/optimus-prime/On_demand.png`,
    },
];




const OptimusPrimeData = () => {
    return (
        <section className="common-tab">
            <Container>
                <CommonHeader title='Comprehensive Data Migration Solutions' subtitle='Optimize your data migration
        process with optimus prime' highlightSubtitleIndex={2} />
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Nav variant="pills" className="tabs-link-holder flex-row justify-content-md-center">
                            {tabData.map((tab) => (
                                <Nav.Item key={tab.key}>
                                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="mt-5">
                            {tabData.map((tab) => (
                                <Tab.Pane eventKey={tab.key} key={tab.key}>
                                    <TabDetails
                                        subHeading={tab.subHeading}
                                        heading={tab.heading}
                                        description={tab.description}
                                        buttonLabel="Know More"
                                        onButtonClick={() => console.log(`Button clicked for ${tab.key}`)}
                                        dotImageSrc={tab.dotImageSrc}
                                        mainImageSrc={tab.mainImageSrc}
                                    />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default OptimusPrimeData