import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../AboutUs.css'
import TechStack from './TechStack';


const AboutFourthSection = () => {

    const features = [
        {
            title: 'High-Speed Transfer',
            description: 'Moves massive datasets rapidly, minimizing downtime.',
            icon: '/assets/images/servicePage/imageGrid/check.svg',
        },
        {
            title: 'Scalable Solutions',
            description: 'Adaptable to handle growing data volumes without compromising performance.',
            icon: '/assets/images/servicePage/imageGrid/check.svg',
        },
        {
            title: 'Secure Transfer Protocols',
            description: 'Protects data integrity with robust security measures during migration.',
            icon: '/assets/images/servicePage/imageGrid/check.svg',
        },
    ];

    return (
        <section className='about-main '>
            <Container>
                <Row>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                        <TechStack />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                        <div className='tab-details-main'>
                            <h4 className="tab-sub-heading">Huge volume data</h4>
                            <h3 className="tab-heading mt-4">
                                Seamless migration for enterprise-level data
                            </h3>
                            <p className="normal-text-main mt-4">
                                Our cutting-edge product redefines data migration, offering an efficient way to move vast datasets with ease, specifically tailored for enterprise needs.
                            </p>
                            <div className="mt-5">
                                {features.map((feature, index) => (
                                    <div key={index} className="d-flex mb-4">
                                        <div className="me-3">
                                            <img
                                                src={process.env.PUBLIC_URL + feature.icon}
                                                alt={`${feature.title} icon`}
                                            />
                                        </div>
                                        <div>
                                            <h3 className="blue-text">{feature.title}</h3>
                                            <p className="normal-text">{feature.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>

        </section>
    )
}

export default AboutFourthSection