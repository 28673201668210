import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Avatar } from "primereact/avatar";
import Typewriter from "./dependancies/TypeWritter";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import EmojiPicker from "emoji-picker-react";
import html2canvas from "html2canvas";
import { FaBars } from "react-icons/fa";
import { OverlayPanel } from "primereact/overlaypanel";
import { Container, Row, Col } from "react-bootstrap"; // Importing react-bootstrap components

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
// import authContext from './dependancies/authContext';
import { BreadCrumb } from "primereact/breadcrumb";
// import pic1 from "../Assets/Images/Frame 31284.png"
import propertiesApp from "./dependancies/propertiesApp";

import emailjs from "emailjs-com"; // Import EmailJS

import icon from "./dependancies/botMsgIcon.png";

import { ListGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
const REQURL = "https://chatbot.redberyltech.com/bot/chat_with_us";

const Chatbot = () => {
  // const { isEmailExist, setIsEmailExist } = useContext(authContext)
  // setIsEmailExist(true)
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const chatWindowRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionData, setSuggestionData] = useState("");
  const [subSuggestionsData, setSubSuggestionData] = useState("");
  const [isEmailId, setIsEmailId] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to toggle emoji picker
  const [isActive, setIsActive] = useState(false);

  const followUpTimer = useRef(null);

  const predefinedSuggestions = [
    // "Primary Services",
    // "Entity Formation",
    // "Non-Profit-Services",
    // "Registration Agent",
    // "Attorneys",
    // "Beneficial Ownership Information",
    // "Others"
  ];

  const subSuggestions = {
    // "Primary Services": [
    //     "DBA Registration",
    //     "Name Availability",
    //     "Conversion",
    //     "Corporate Kit and Seal",
    //     "UCC Filings",
    //     "Dissolution"
    // ],
    // "Entity Formation": [
    //     "LLC",
    //     "S-Corp",
    //     "C-Corp",
    //     "Tax ID EIN",
    //     "PC or PLLC",
    // ],
    // "Non-Profit-Services": [
    //     "Formations of Nonforprofit -501c3",
    //     "Application For Exemption",
    //     "State Tax Exemption",
    //     "Foreign State Registration"
    // ],
    // "Registration Agent": [
    //     "Registered Agent",
    //     "Independent Director",
    //     "Entity Monitoring"
    // ],
    // "Beneficial Ownership Information": [],
    // "Attorneys": [],
    // "Others": []
  };

  useEffect(() => {
    const botMessage = {
      sender: "bot",
      type: "first-msg",
      text: "Please feel free to ask your question.",
      flag: "bot-text",
      time: getCurrentTimeWithAMPM(),
    };
    const botSuggestions = {
      sender: "bot",
      type: "first-msg",
      suggestions: predefinedSuggestions,
      time: getCurrentTimeWithAMPM(),
    };
    setMessages([...messages, botMessage]);
  }, []);

  function getCurrentTimeWithAMPM() {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    const hoursStr = hours < 10 ? "0" + hours : hours;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;

    const timeString = hoursStr + ":" + minutesStr + " " + ampm;
    return timeString;
  }

  const handleSuggestionClick = (suggestion) => {
    setSuggestionData(suggestion);
    setIsActive(true);
    const userMessage = {
      sender: "user",
      text: suggestion,
      flag: "user-text",
      time: getCurrentTimeWithAMPM(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    if (subSuggestions[suggestion].length > 0) {
      const botSubSuggestions = {
        sender: "bot",
        type: "sub-suggestions",
        suggestions: subSuggestions[suggestion],
        time: getCurrentTimeWithAMPM(),
      };
      setMessages((prevMessages) => [...prevMessages, botSubSuggestions]);
    } else {
      const botMessage = {
        sender: "bot",
        text:
          suggestion === "Others"
            ? "Please specify your query so that we can assist you better."
            : `How can I help on ${suggestion}?`,
        flag: "bot-text",
        time: getCurrentTimeWithAMPM(),
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    }
  };

  const handleSubSuggestionClick = async (subSuggestion) => {
    setSubSuggestionData(subSuggestion);
    setIsActive(true);
    const userMessage = {
      sender: "user",
      text: subSuggestion,
      flag: "user-text",
      time: getCurrentTimeWithAMPM(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setIsLoading(true);
    try {
      // Simulating typing delay
      await new Promise((resolve) => setTimeout(resolve, 1500));
      const m = "Please specify your query so that we can assist you better.";
      if (subSuggestion === "Others") {
        const botMessage = {
          sender: "bot",
          text: m,
          flag: "bot-text",
          time: getCurrentTimeWithAMPM(),
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } else {
        const botMessage = {
          sender: "bot",
          text: `What do you like to know about ${subSuggestion} ?`,
          flag: "bot-text",
          time: getCurrentTimeWithAMPM(),
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      const m = "Please specify your query so that we can assist you better.";
      if (subSuggestion === "Others") {
        const botMessage = {
          sender: "bot",
          text: m,
          flag: "bot-text",
          time: getCurrentTimeWithAMPM(),
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } else {
        const botMessage = {
          sender: "bot",
          text: `What do you like to know about ${subSuggestion} ?`,
          flag: "bot-text",
          time: getCurrentTimeWithAMPM(),
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      }
    }

    setIsLoading(false);
    setIsActive(false);
  };

  const handleSend = async () => {
    setIsActive(true);

    if (input.trim()) {
      const userMessage = {
        sender: "user",
        text: input,
        flag: "user-text",
        time: getCurrentTimeWithAMPM(),
      };
      setMessages([...messages, userMessage]);

      setIsLoading(true);

      const inputData = {
        question: input,
        page: "global",
        // page: suggestionData === "Others" ? "global" : subSuggestionsData === "" ? suggestionData : subSuggestionsData
      };

      try {
        // const response = await axios.post(`${propertiesApp.chatbot_url}/bot/chat_with_us`, inputData);
        const response = await axios.post(REQURL, inputData);

        const botMessage = {
          sender: "bot",
          text: response.data.answer,
          flag: "bot-text",
          time: getCurrentTimeWithAMPM(),
        };

        await new Promise((resolve) => setTimeout(resolve, 1500));

        setMessages((prevMessages) => [...prevMessages, botMessage]);

        setIsLoading(false);
        setIsActive(false);
        setInput("");

        // Reset the follow-up timer
        if (followUpTimer.current) {
          clearTimeout(followUpTimer.current);
        }

        if (!isActive) {
          followUpTimer.current = setTimeout(() => {
            const followUpMessage = {
              sender: "bot",
              text: "Do you want to know anything else?",
              flag: "bot-text",
              type: "follow-up",
              time: getCurrentTimeWithAMPM(),
            };
            setMessages((prevMessages) => [...prevMessages, followUpMessage]);
            setIsActive(true);
          }, 120000);
        }
      } catch (error) {
        console.error("Error sending message:", error);
        const botMessage = {
          sender: "bot",
          text: `Sorry for the inconvenience. We are not able to answer your query. Please contact us at +91 76669 87829 for further assistance.`,
          flag: "bot-text",
          time: getCurrentTimeWithAMPM(),
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);

        setIsLoading(false);
        setIsActive(false);
        setInput("");
        // Reset the follow-up timer
        if (followUpTimer.current) {
          clearTimeout(followUpTimer.current);
        }
        if (!isActive) {
          followUpTimer.current = setTimeout(() => {
            const followUpMessage = {
              sender: "bot",
              text: "Do you want to know anything else?",
              flag: "bot-text",
              type: "follow-up",
              time: getCurrentTimeWithAMPM(),
            };
            setMessages((prevMessages) => [...prevMessages, followUpMessage]);
            setIsActive(true);
          }, 120000);
        }
      }
    }
  };

  const handleFollowUpResponse = (response) => {
    if (response === "no") {
      const thankYouMessage = {
        sender: "bot",
        text:
          "Thanks for your time. I hope, you got the required information. Have a nice day!",
        flag: "bot-text",
        time: getCurrentTimeWithAMPM(),
      };
      setMessages((prevMessages) => [...prevMessages, thankYouMessage]);
    } else if (response === "yes") {
      setIsActive(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
      handleSend();
    }
  };

  const handleEmojiClick = (emojiData) => {
    setInput((prevInput) => prevInput + emojiData.emoji);
    setShowEmojiPicker(false);
  };
  useEffect(() => {
    // Scroll to bottom whenever messages change
    // chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [messages]);

  const exportChat = async () => {
    console.log("inexport");
    try {
      const chatWindow = chatWindowRef.current;
      if (!chatWindow) {
        throw new Error("Chat window not found.");
      }

      // Collect the message rows from the chat window
      const messageRows = chatWindow.querySelectorAll(".message-row");
      console.log("Message Rows:", messageRows); // Debug: Log message rows

      const currentDate = new Date();
      const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

      // Start building the HTML content
      let chatContent = `
          <html>
            <head>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 20px;
                  padding: 0;
                  line-height: 1.6;
                }
                .header {
                  text-align: center;
                  font-size: 18px;
                  font-weight: bold;
                  margin-bottom: 20px;
                }
                .timestamp {
                  font-size: 12px;
                  color: gray;
                }
                .bot-message {
                  background-color: #e1f5fe;
                  padding: 10px;
                  margin-bottom: 10px;
                  border-radius: 5px;
                  max-width: 80%;
                  margin-left: 10%;
                }
                .user-message {
                  background-color: #f1f1f1;
                  padding: 10px;
                  margin-bottom: 10px;
                  border-radius: 5px;
                  max-width: 80%;
                  margin-right: 10%;
                  text-align: right;
                }
                .suggestion-chip {
                  display: inline-block;
                  background-color: #e0e0e0;
                  padding: 8px;
                  margin: 5px;
                  border-radius: 20px;
                  cursor: pointer;
                }
              </style>
            </head>
            <body>
              <div class="header">Chat Export - ${formattedDate}</div>
        `;

      // Loop through the message rows and structure them as chat messages
      messageRows.forEach((row, index) => {
        console.log(`Processing Row ${index + 1}:`, row); // Debug: Log each message row

        const messageSender = row.classList.contains("bot") ? "bot" : "user";
        const botMessageContent = row.querySelector(".border-for-msg"); // Bot message is in .border-for-msg
        const userMessageContent = row.querySelector(".message"); // User message is in .message
        const timestamp = row.querySelector(".timestamp")
          ? row.querySelector(".timestamp").innerText
          : "";

        let messageText = "";

        // Handle bot messages
        if (messageSender === "bot") {
          if (botMessageContent) {
            // Check if bot has any content (like suggestions or text)
            const suggestionElements = row.querySelectorAll(
              ".chip-main-container"
            );
            let botMessageText = botMessageContent.innerText.trim();
            console.log("Bot Message Text:", botMessageText); // Debug: Log bot message text

            // If there are suggestions, append them
            if (suggestionElements.length > 0) {
              botMessageText = botMessageText
                ? botMessageText
                : "Bot suggested the following:";
              suggestionElements.forEach((suggestionElem) => {
                const suggestionText = suggestionElem.innerText.trim();
                botMessageText += `\n - ${suggestionText}`;
              });
            }

            messageText = botMessageText || "No content available";
          }
        } else if (messageSender === "user") {
          // Handle user messages
          if (userMessageContent) {
            messageText = userMessageContent.innerText.trim();
          }
        }

        // If message content is empty, we assign a placeholder
        if (!messageText) {
          messageText = "No message content available";
        }

        console.log(`Message Text for ${messageSender}:`, messageText); // Debug: Log final message text

        // Append the message with timestamp and sender type
        chatContent += `
            <div class="${messageSender}-message">
              <div class="timestamp">${timestamp}</div>
              <div>${messageText}</div>
            </div>
          `;
      });

      // Close the HTML body and document
      chatContent += `
            </body>
          </html>
        `;

      // Log the final chat content (for debugging)
      console.log("Final Chat Content:", chatContent);

      // Create a Blob from the HTML content
      const blob = new Blob([chatContent], { type: "text/html" });

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "chat_transcript.html";
      link.click();
    } catch (error) {
      console.error("Error exporting chat:", error);
    }
  };

  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false);
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);

  const [userInfo, setUserInfo] = useState({ name: "", email: "", phone: "" });
  const [isChatStarted, setIsChatStarted] = useState(false); // Track if chat has started

  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const handleShow = () => setShowOffCanvas(true);

  const [file, setFile] = useState(null); // Store the file object
  const [uploadSuccess, setUploadSuccess] = useState(false); // Track upload success

  console.log(messages);

  const svgContent = icon;

  // Handle user input change
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  // Handle form input change for user info (name, email, phone)
  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  // Handle form submission to send user info and start the chat
  const handleStartChat = async () => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo)); // Save to localStorage

    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://chatbot.redberyltech.com/auth/login/",
        {
          input_data: `${userInfo.name},${userInfo.email},${userInfo.phone}`,
        }
      );

      // Assuming the response structure is like:
      const { message } = response.data;

      // Construct the message
      const userDetails = message.split(",");
      const name = userDetails[0]?.split(":")[1]?.trim() || "";
      const email = userDetails[1]?.split(":")[1]?.trim() || "";
      const phone = userDetails[2]?.split(":")[1]?.trim() || "None";

      // Create the bot's message with conditional contact
      let botMessage = `Your name: ${name}, Your email: ${email}`;
      if (phone !== "None") {
        botMessage += `, Your contact: ${phone}`;
      }

      // Add this message to the chat history
      setMessages([
        ...messages,
        { sender: "bot", text: botMessage, type: "text" },
      ]);

      setIsChatStarted(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending user info", error);
      setIsLoading(false);
    }
  };

  // Handle sending messages in the chat
  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, { sender: "user", text: input, type: "text" }]);
      setInput("");
    }
  };

  const handleEditDetails = () => {
    setIsChatStarted(false); // Show the form to edit details
    setMessages([
      { sender: "bot", text: "Please review updated details below." },
    ]);
  };

  useEffect(() => {
    const savedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (savedUserInfo) {
      setUserInfo(savedUserInfo);
      setIsChatStarted(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Welcome back! How can I assist you today?" },
      ]);
    }
  }, []);

  const toggleAttachmentModal = () => {
    setAttachmentModalVisible((prevState) => !prevState);
  };

  const handleEmailTranscript = () => {
    const transcript = messages
      .map((msg) => `${msg.sender === "bot" ? "Bot" : "You"}: ${msg.text}`)
      .join("\n");

    console.log("Recipient email:", userInfo.email);

    if (!userInfo.email) {
      alert("Recipient email is empty! Please provide a valid email address.");
      return;
    }

    const emailTemplateParams = {
      email_to: userInfo.email,
      to_name: userInfo.name,
      from_email: "rushikesh.ingale@redberyltech.com",
      message: transcript,
    };

    emailjs
      .send(
        "service_c895i2j",
        "template_yjdfphh",
        emailTemplateParams,
        "Bu9Idvae_DU5QmJLS"
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
          alert("Email Sent");
        },
        (error) => {
          console.error("Error details:", error);
          alert("Failed to send email. Check the console for more details.");
        }
      );
  };

  const handleAttachment = () => {
    console.log("Handle file attachment logic here.");
    toggleAttachmentModal();
  };

  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleOptionSelect = (option) => {
    if (option === "Upload File") {
      fileInputRef.current.click();
      setUploading(true); // Enable file upload section when "Upload File" is selected
    } else {
      console.log(`Selected option: ${option}`);
      toggleAttachmentModal(); // Close modal after selecting an option
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
      setFileName(file.name);
      console.log(`Selected PDF file: ${file.name}`);
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("No file selected.");
      return;
    }

    setUploading(true);
    setUploadSuccess(false);

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post("/your-upload-endpoint", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setUploadSuccess(true);
        console.log("File uploaded successfully:", response.data);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploading(false);
    }
  };

  const breadcrumbItems = [
    { label: "Edit Details", command: handleEditDetails },
    // { label: "Email Transcript", command: handleEmailTranscript },
    // { label: 'Attachment', command: toggleAttachmentModal },
  ];

  const [fileName, setFileName] = useState("");

  const toggleMenu = () => {
    setIsActive(!isActive);
    setShowBreadcrumb(!showBreadcrumb);
  };
  const op = useRef(null);
  const [isCustomIcon, setIsCustomIcon] = useState(true);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (event) => {
    setIsCustomIcon(!isCustomIcon);
    op.current.toggle(event);
    setIsClicked((prevState) => !prevState);
  };

  const handleClose = () => {
    op.current.hide(); // Hide the OverlayPanel when the close button is clicked
  };

  const customIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="29"
      viewBox="0 0 42 29"
      fill="none"
    >
      <circle cx="20.175" cy="4.13223" r="4.13223" fill="#A3D5C9" />
      <circle cx="15.7997" cy="12.6398" r="2.18765" fill="#A3D5C9" />
      <circle cx="2.55226" cy="23.6995" r="2.55226" fill="#A3D5C9" />
      <circle cx="31.4779" cy="25.6441" r="3.0384" fill="#A3D5C9" />
      <circle cx="39.1346" cy="17.015" r="2.18765" fill="#A3D5C9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3734 1.33923C32.5037 2.35941 31.5781 3.8808 31.5781 5.93427C31.5781 6.69484 31.7466 7.61852 31.9607 8.03271C32.171 8.43937 32.2704 8.84433 32.1817 8.93208C32.0927 9.01983 29.7123 11.4629 26.8919 14.3611L21.7636 19.6304L21.0195 19.2328C20.0336 18.706 17.5978 18.707 16.6102 19.2349L15.8618 19.6348L13.0013 16.7668L10.1406 13.8988L10.5079 13.1883C11.1391 11.9678 11.2237 10.8779 10.7886 9.57403C10.0351 7.31662 8.29641 6.05338 5.9425 6.05338C3.34527 6.05338 1.29471 7.89805 1.02879 10.4739C0.637691 14.2644 4.2986 17.0349 7.88708 15.6645L8.99378 15.2417L11.8295 18.0487L14.6651 20.8557L14.2495 21.7272C13.6304 23.0254 13.7108 24.8431 14.4474 26.1956C16.8433 30.5947 23.3861 29.198 23.7558 24.2082C23.8314 23.1875 23.7385 22.5259 23.4213 21.8224L22.9823 20.8489L28.3229 15.5082L33.6634 10.1676L34.6182 10.5667C36.0667 11.1717 38.1175 10.9491 39.4184 10.0451C42.2502 8.07792 42.2441 3.78187 39.4067 1.81031C38.5455 1.21162 38.0786 1.07258 36.7329 1.01327C35.5713 0.962228 34.8929 1.05581 34.3734 1.33923Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div>
      {/* <Container className="chat-container"> */}
        {/* <Row className="chat-overlay button-container justify-content-end mb-5"> */}
        <Row >

          <Col className="d-flex justify-content-end">
            <Button
              type="button"
              icon={isCustomIcon ? "" : "pi pi-times"}
              className="chat-button mt-2 text-center text-lg mr-2"
              onClick={handleClick}
            >
              {/* Conditional rendering of image based on the isClicked state */}
              <div className="chat-icon-btn">
                {isClicked ? (
                  <img
                    src="/assets/images/bot/onClickChatbtn.svg" // The image to show after click
                    alt="chat-icon-on-click"
                  />
                ) : (
                  <img
                    src="/assets/images/bot/chat-main-btn.png" // The initial image
                    alt="chat-icon-btn-img"
                  />
                )}
              </div>
              {/* {isCustomIcon && <span className="chat-label">Chat with us</span>} */}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <OverlayPanel
              ref={op}
              className="custom-overlay-panel"
              style={{ position: "fixed", left: "60%" }}
            >
              <div
                className="custom-header"
                style={{ backgroundColor: "#043199" }}
              >
                <span className="font-fam-for-all">Chat With RedBeryl!</span>

                {isChatStarted && (
                  <Row className="p-3">
                    <Col>
                      {/* Menu Button */}
                      <Button onClick={toggleMenu} className="menu-button">
                        <FaBars />
                      </Button>

                      {/* Show Breadcrumb when showBreadcrumb state is true */}
                      {showBreadcrumb && (
                        <>
                          {/* Breadcrumb Navigation */}
                          {/* <BreadCrumb>
                      {breadcrumbItems.map((item, index) => (
                        <li key={index} className="p-breadcrumb-item">
                          <span
                            className="p-breadcrumb-link item-label"
                            onClick={item.command}
                          >
                            {item.label}
                          </span>
                        </li>
                      ))}
                    </BreadCrumb> */}

                          {/* List View of Buttons */}
                          <ListGroup className=" list-group-chat-items">
                            {breadcrumbItems.map((item, index) => (
                              <ListGroup.Item
                                key={index}
                                className="list-group-chat-item"
                              >
                                <span onClick={item.command}>{item.label}</span>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </>
                      )}
                    </Col>
                  </Row>
                )}
              </div>

              <div className="chatbot">
                <div
                  className="chat-window"
                  style={{
                    backgroundImage: "url('/assets/images/bot/chat.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                  ref={chatWindowRef}
                >
                  <Container>
                    {/* User Info Form */}
                    {!isChatStarted && (
                      <Row className="bot-text bot-message-container p-3">
                        <Col className="message-col">
                          <div className="mt-1 ml-1 border-for-msg">
                            <div className="message bot bot-message-container b4">
                              Please enter your details to start the chat:
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    {!isChatStarted && (
                      <Row className="bot-text bot-message-container p-3">
                        <Col className="message-col">
                          <div className="user-info-form">
                            <label>
                              Name:{" "}
                              <span className="form-mandetory-field">*</span>
                            </label>
                            <InputText
                              name="name"
                              value={userInfo.name}
                              onChange={handleUserInfoChange}
                              placeholder="Enter your name"
                              className="input-field"
                            />
                            <label>
                              Email:{" "}
                              <span className="form-mandetory-field">*</span>
                            </label>
                            <InputText
                              name="email"
                              value={userInfo.email}
                              onChange={handleUserInfoChange}
                              placeholder="Enter your email"
                              className="input-field"
                            />
                            <label>Phone:</label>
                            <InputText
                              name="phone"
                              value={userInfo.phone}
                              onChange={handleUserInfoChange}
                              placeholder="Enter your phone number"
                              className="input-field"
                            />
                            <Button
                              label={
                                isLoading ? (
                                  <span className="loading-dots">
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                  </span>
                                ) : (
                                  "Start Chat"
                                )
                              }
                              onClick={handleStartChat}
                              onTouchStart={handleStartChat}
                              className="start-chat-button"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}

                    {/* Chat Messages */}
                    {isChatStarted &&
                      messages.map((msg, index) => {
                        const isBotMessage = msg.sender === "bot";
                        const isUserMessage = msg.sender === "user";

                        const messageContent = isBotMessage ? (
                          msg.type === "suggestions" ? (
                            <div className="chip-main-container">
                              <div className="bot-message-container b1">
                                <img
                                  src="/assets/images/bot/Trigger.png"
                                  alt="Bot Avatar"
                                  className="bot-image"
                                />
                                <div className="border-for-msg">
                                  {msg.suggestions.map((suggestion, idx) => (
                                    <div key={idx} className="p-1">
                                      <div className="flex justify-content-center chip-main">
                                        <Chip
                                          label={suggestion}
                                          onClick={() =>
                                            handleSuggestionClick(suggestion)
                                          }
                                          className="suggestion-chip"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="p-3">
                              <div className="bot-message-container b4">
                                <img
                                  src="/assets/images/bot/Trigger.png"
                                  alt="Bot Avatar"
                                  className="bot-image"
                                />
                                <div className="border-for-msg">
                                  <Typewriter
                                    text={msg.text}
                                    speed={50}
                                    setIsActive={setIsActive}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        ) : (
                          <div className="message user">{msg.text}</div>
                        );

                        return (
                          <Row
                            key={index}
                            className={`message-row ${
                              isBotMessage ? "bot" : "user"
                            }`}
                          >
                            {messageContent}
                          </Row>
                        );
                      })}
                  </Container>
                </div>

                {/* Attachment Modal */}

                {attachmentModalVisible && (
                  <div className="attachment-modal-overlay">
                    <div className="attachment-modal-container">
                      <div className="attachment-modal-body">
                        <ul className="attachment-options">
                          <li onClick={() => handleOptionSelect("Upload File")}>
                            <div className="attachment-option-item">
                              <span className="attachment-icon">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.28 10.6L10.58 5.3a2.75 2.75 0 1 1 3.9 3.9L8.1 15.55a1.25 1.25 0 1 1-1.77-1.77L12 8.13a.75.75 0 0 0-1.06-1.06l-5.66 5.66a2.75 2.75 0 0 0 3.89 3.89l6.36-6.36a4.25 4.25 0 0 0-6-6.02l-5.31 5.3a.75.75 0 0 0 1.06 1.07Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                              <span className="attachment-option-text">
                                Upload File
                              </span>
                            </div>
                          </li>

                          {fileName && (
                            <div className="file-info">
                              <p>
                                File Selected: <strong>{fileName}</strong>
                              </p>
                              <button
                                onClick={handleFileUpload}
                                disabled={uploading}
                              >
                                {uploading ? "Uploading..." : "Upload File"}
                              </button>
                            </div>
                          )}

                          {/* <li onClick={() => handleOptionSelect("Share a Link")}>
            <div className="attachment-option-item">
              <span className="attachment-icon">
                <svg width="1em" height="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.83 10.48l5.65-5.65a3 3 0 0 1 4.25 4.24L8 15.8a1.5 1.5 0 0 1-2.12-2.12l6-6.01a.5.5 0 1 0-.7-.71l-6 6.01a2.5 2.5 0 0 0 3.53 3.54l6.71-6.72a4 4 0 1 0-5.65-5.66L4.12 9.78a.5.5 0 0 0 .7.7Z" fill="currentColor"></path>
                </svg>
              </span>
              <span className="attachment-option-text">Share a Link</span>
            </div>
          </li> */}

                          {/* <li onClick={() => handleOptionSelect("Attach from Cloud")}>
            <div className="attachment-option-item">
              <span className="attachment-icon">
                <svg width="1em" height="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 2a4 4 0 0 1 4 4v4h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h4V6a4 4 0 0 1 4-4z" fill="currentColor"></path>
                </svg>
              </span>
              <span className="attachment-option-text">Attach from Cloud</span>
            </div>
          </li> */}
                        </ul>
                      </div>
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept=".pdf" // Allow only PDFs
                        style={{ display: "none" }} // Hide the file input
                        onChange={handleFileChange} // Handle file change
                      />
                    </div>
                  </div>
                )}

                {/* Input Area */}
                {isChatStarted && (
                  <div className="input-area">
                    <InputText
                      value={input}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyPress}
                      placeholder="Type your message..."
                      className="input-field"
                    />
                    {/* Emoji Button */}
                    <Button
                      label={
                        isLoading ? (
                          <span className="loading-dots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </span>
                        ) : (
                          <img
                            src="/assets/images/bot/emoji.png"
                            alt="emoji"
                            className="button-icon"
                            style={{ scale: "1.3" }}
                          />
                        )
                      }
                      onClick={() => setShowEmojiPicker((prev) => !prev ) && setIsActive(true)  }
                      className="send-button ml-2"
                    />

                    {/* Attachment */}
                    <Button
                      label={
                        isLoading ? (
                          <span className="loading-dots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </span>
                        ) : (
                          <img
                            src="/assets/images/bot/attachment.png"
                            alt="attachment"
                            className="button-icon"
                            style={{ scale: "1.3" }}
                          />
                        )
                      }
                      onClick={() =>
                        setAttachmentModalVisible(!attachmentModalVisible ) && setIsActive(true)
                      }
                      className="send-button ml-2"
                    />

                    <Button
                      label={
                        isLoading ? (
                          <span className="loading-dots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </span>
                        ) : (
                          <img
                            src="/assets/images/bot/sendChat.png"
                            alt="Send"
                            className="button-icon"
                          />
                        )
                      }
                      onClick={handleSend}
                      className="send-button ml-2"
                    />
                    <Button
                      label={
                        isLoading ? (
                          <span className="loading-dots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </span>
                        ) : (
                          <img
                            src="/assets/images/bot/downloadChat.png"
                            alt="Export Chat"
                            className="button-icon"
                          />
                        )
                      }
                      onClick={exportChat}
                      className="ml-2 export-button"
                    />

                    {/* Emoji Picker */}
                    {showEmojiPicker && (
                      <EmojiPicker
                        onEmojiClick={handleEmojiClick}
                        className="emoji-picker"
                        groupNames={{ smileys: "Smileys & People" }}
                        theme="light"
                        emojiStyle="facebook"
                      />
                    )}
                  </div>
                )}
              </div>
            </OverlayPanel>
          </Col>
        </Row>
      {/* </Container> */}
    </div>
  );
};
export default Chatbot;
