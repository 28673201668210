import React from 'react'
import CommonServiceBanner from '../../common-component/common-service-banner/CommonServiceBanner.js'
import OurClients from '../../homepage/our-clients/OurClients.js'
import Testimonials from '../../homepage/testimonials/Testimonials.js'
import GetQuote from '../../common-component/get-quote/GetQuote.js'
import OdcEstablishmentFeature from './odc-establishment-feature/OdcEstablishmentFeature.js'
import CommonHeader from '../../common-component/common-header/CommonHeader.js'
import OdcEstablishmentData from './odc-establishment-data/OdcEstablishmentData.js'

const OdcEstablishment = () => {
  return (
    <>
      <CommonServiceBanner
        imageSrc={'/assets/images/servicesPage/odcestablishment.png'}
        subheading={''}
        title={'ODC Establishment'}
        titleSecond={''}
        description={'Establishing an Offshore Development Center (ODC) involves creating a dedicated team, infrastructure, and processes to extend capabilities, streamline development, and enhance collaboration for project success. Our ODCs serve as strategic hubs, connecting you with top-tier global talent, offering cost-effective and scalable solutions for your project needs.'}
        quoteButtonLabel={'Contact Us'}
        downloadButtonLabel={'Download Brouchure'}
      />

      <OdcEstablishmentData/>

<OdcEstablishmentFeature/>
{/* <CommonHeader/> */}

<OurClients />


      <Testimonials />
      <GetQuote title="Unlock global talent, optimize operations today!"  subtitle="Appropriate for your specific business, making it easy for you to have quality IT services."/>
    </>
  )
}

export default OdcEstablishment